import classNames from 'classnames';
import { useEffect } from 'react';

import { Homeless } from 'types';

import Card from '../../Card';

import { useDebitCardActions } from 'hooks/useDebitCardActions';

import { renderCardType } from './utils';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            display: 'flex',
            alignItems: 'center',
            flex: 'none',
            flexGrow: 0,
            gap: '8px',
            borderRadius: '20px',
            boxSizing: 'border-box',
            fontWeight: 800,
            height: '40px',
            order: 0,
            padding: '0px 32px',
            textTransform: 'none'
        },
        buttonCancel: {
            border: '1px solid #A80A2D',
            color: '#A80A2D'
        },
        buttonClose: {
            backgroundColor: '#A80A2D',
            color: '#FFFFFF'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        cardStyle: {
            margin: '0px !important'
        },
        closeCardContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px'
        },
        title: {
            color: '#A80A2D',
            fontWeight: 800,
            marginBottom: '0',
            textAlign: 'center'
        }
    })
);

type CardCloseProps = {
    holder: Homeless;
    onClose: () => void;
    setLoading?: (loading: boolean) => void;
    setSuccess?: (success: boolean) => void;
};

const CardClose: React.FC<CardCloseProps> = ({
    holder,
    onClose,
    setLoading,
    setSuccess
}) => {
    const classes = useStyles();

    const { closeDebitCard, debitInfo, loading, success } =
        useDebitCardActions();

    const { id } = holder;
    const { card_last4, card_type } = debitInfo || {
        card_last4: null,
        card_type: null
    };

    const cancelButtonClass = classNames(classes.button, classes.buttonCancel);
    const closeButtonClass = classNames(classes.button, classes.buttonClose);

    const handleCardClose = () => closeDebitCard(id);

    useEffect(() => {
        if (loading && setLoading) {
            setLoading(true);
        }
    }, [loading, setLoading]);

    useEffect(() => {
        if (success && setSuccess && setLoading) {
            setLoading(false);
            setSuccess(true);
        }
    }, [loading, success, setSuccess, setLoading]);

    return (
        <div className={classes.closeCardContainer}>
            <h2 className={classes.title}>
                Close {renderCardType(card_type)} Ending in {card_last4}
            </h2>
            <Card className={classes.cardStyle}>
                Are you sure you want to close your debit card?
                <br />
                This action cannot be undone.
                <br />
                <br />
                Closing your card will:
                <br />
                <ul>
                    <li>Prevent any future transactions</li>
                    <li>Cancel any recurring payments linked to this card</li>
                    <li>Make the card unavailable for use immediately</li>
                </ul>
            </Card>
            <div className={classes.buttonContainer}>
                <Button className={cancelButtonClass} onClick={onClose}>
                    Cancel
                </Button>
                <Button className={closeButtonClass} onClick={handleCardClose}>
                    Yes, Close Card
                </Button>
            </div>
        </div>
    );
};

export default CardClose;
