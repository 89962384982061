import * as api from 'api/homelessProfile';
import * as constants from '../constants/constants';

import { CatchUpItems } from 'types';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from '.';

function completeItem(catchUpItemId: number) {
    return {
        type: constants.COMPLETE_CATCH_UP_ITEM,
        payload: { catchUpItemId }
    };
}

function fetchCatchUpItems() {
    return {
        type: constants.FETCH_CATCH_UP_ITEMS
    };
}

function setCatchUpItems(items: CatchUpItems[]) {
    return {
        type: constants.SET_CATCH_UP_ITEMS,
        payload: items
    };
}

function snoozeItem(catchUpItemId: number, snoozeTill?: string) {
    return {
        type: constants.SNOOZE_CATCH_UP_ITEM,
        payload: { catchUpItemId, snoozeTill }
    };
}

export function completeItemRequest(catchUpItemId: number) {
    return (dispatch: Function) => {
        dispatch(completeItem(catchUpItemId));

        // return api
        //     .completeCatchUpItem(catchUpItemId)
        //     .then(handleSamaritanApiResponse)
        //     .catch(handleSamaritanApiError);
    };
}

export function fetchCatchUpItemsRequest(memberId: number) {
    return (dispatch: Function) => {
        dispatch(fetchCatchUpItems());

        return api
            .fetchCatchUpItems(memberId)
            .then(handleSamaritanApiResponse)
            .then((response: { member_catch_up_items: CatchUpItems[] }) => {
                dispatch(setCatchUpItems(response.member_catch_up_items));
            })
            .catch(handleSamaritanApiError);
    };
}

export function snoozeItemRequest(catchUpItemId: number, snoozeTill?: string) {
    return (dispatch: Function) => {
        dispatch(snoozeItem(catchUpItemId, snoozeTill));

        return api
            .snoozeCatchUpItem(catchUpItemId, snoozeTill)
            .then(handleSamaritanApiResponse)
            .catch(handleSamaritanApiError);
    };
}