export const calculateDateDifference = (targetDateTime: string) => {
    if (!targetDateTime) {
        return 'No Due Date';
    }

    const targetDate = new Date(targetDateTime);
    const currentDate = new Date();

    targetDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === 1) {
        return 'Tomorrow';
    } else if (daysDifference === -1) {
        return 'Yesterday';
    } else if (daysDifference < -1) {
        return `${Math.abs(daysDifference)} days ago`;
    }

    return `${daysDifference} days`;
};

export const checkCurrentMonth = (createdAt: string) => {
    const today = new Date();
    const createdAtDate = new Date(createdAt);

    if (
        today.getMonth() === createdAtDate.getMonth() &&
        today.getFullYear() === createdAtDate.getFullYear()
    ) {
        return true;
    } else {
        return false;
    }
};

export const convertISODateToYYYYMMDD = (isoDate: string) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to the month because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const extractNumbersAndAgo = (input: string, date: string) => {
    const numberMatches = input.match(/\d+/g);
    const hasAgo = input.includes('ago');

    if (numberMatches) {
        const numberArr = numberMatches.map((match) => parseInt(match));
        const numbers = numberArr[0];

        return {
            number: numbers,
            hasAgo: hasAgo
        };
    } else {
        return {
            number: calculateDateDifference(date),
            hasAgo: hasAgo
        };
    }
};

export const formatLongDate = (inputDate: string) => {
    const date = new Date(inputDate);
    const options: object = { month: 'long', day: 'numeric' };

    const formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate;
};

export const getShortMonthName = (date?: string) => {
    const month = date
        ? new Date(date).toLocaleString('default', { month: 'short' })
        : new Date().toLocaleString('default', { month: 'short' });

    return month;
};

export const renderClasses = (
    classes: {
        actionButton: string;
        buttonWarning: string;
        date: string;
        dueDate: string;
        dueDateWarning: string;
        noDueDate?: string;
    },
    date: string
) => {
    const dateDifference = calculateDateDifference(date);
    const checkValue = extractNumbersAndAgo(dateDifference, date);

    let buttonClass = classes?.actionButton;
    let dateClass = classes.date;

    if (checkValue.number === 'No Due Date') {
        dateClass += ` ${classes.noDueDate}`;
    }

    if (
        Number(checkValue.number) <= 3 ||
        checkValue.number === 'Today' ||
        checkValue.number === 'Tomorrow' ||
        checkValue.number === 'Yesterday' ||
        checkValue.hasAgo
    ) {
        buttonClass += ` ${classes.buttonWarning}`;
        dateClass += ` ${classes.dueDateWarning}`;
    } else {
        buttonClass += ` ${classes.dueDate}`;
    }

    return { buttonClass, dateClass };
};
