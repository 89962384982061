// TODO: Fix ts-nocheck PrivateInfo
// @ts-nocheck
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Homeless, User } from '../../../../types';

import ContactInfo from './components/ContactInfo';
import DocumentStorage from './components/Documents';
import Enrollment from './components/Enrollment';
import LoadingCircle from '../../../_shared/LoadingCircle';
import MemberInfo from './components/MemberInfo';
import PopUp from '../PopUp';
import RequestErrorModal from '../../../_shared/RequestErrorModal';

// import { fetchDocumentsForId } from 'api/documents';
import { fetchDocumentsForId } from '../../../../api/documents';
import useUpdatePrivateProfile from '../../../../hooks/useUpdatePrivateProfile';

import {
    formatDate,
    formatHolderPhone,
    formatPhoneNumber,
    validateEmail,
    validatePhone
} from './utils';
import { isObjectEmpty } from '../../../../util';

import { getHomelessInfo } from '../../../../redux/actions/homeless';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        privateInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '80px',
            padding: '30px 12% 60px 12%'
        },
        header: {
            margin: '10px 0'
        },
        headerTitle: {
            fontWeight: 800,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#1F2152'
        },
        headerTitleDiv: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        closeBtn: {
            padding: 0,
            minWidth: '50px',
            color: '#7378E8'
        },
        inputFields: {
            width: '100%',
            border: '0',
            padding: '10px 0px 0px 0px',
            fontFamily: 'Manrope',
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#1f1f51',
            borderRadius: '6px',
            letterSpacing: '-0.21px',
            background: 'transparent',
            resize: 'none',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        inputBirthday: {
            width: '100%',
            border: '0',
            padding: '5px 0px 0px 0px',
            fontFamily: 'Manrope',
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#1f1f51',
            borderRadius: '6px',
            letterSpacing: '-0.21px',
            background: 'transparent',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        underline: {
            height: '0',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            marginBottom: '2rem'
        },
        underlineError: {
            height: '0',
            borderRadius: '10px',
            marginBottom: '2rem',
            border: '6px solid #FF0000'
        },
        body: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            borderRadius: '16px',
            padding: '5px 24px 5px',
            gap: '32px',
            marginTop: '2rem'
        },
        bodyHeader: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '32px',
            color: '#1F2152'
        },
        btnDiv: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
            marginTop: '2rem'
        },
        btn: {
            margin: '10px auto 30px auto',
            minWidth: '300px',
            fontSize: '20px'
        },
        editButtonDiv: {},
        editButton: {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            height: 40,
            width: 40,
            minWidth: 40,
            borderRadius: 40,
            outline: 'none',
            fontSize: '1.2rem',
            color: '#2B3165',
            border: 'none',
            background: '#FFFFFF',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)'
        },
        marginBottom: {
            marginBottom: '2rem'
        },
        name: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#1F1F51',
            margin: 0,
            marginBottom: '2rem'
        },
        orgName: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#1F1F51',
            margin: 0
        },
        title: {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '100%',
            display: 'flex',
            alignTtems: 'flex-end',
            letterSpacing: '0.15em',
            textTransform: 'uppercase',
            color: '#1F2051',
            opacity: 0.7,
            marginBottom: '0.5rem'
        },
        orgLogo: {
            width: '32px',
            height: '32px',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            borderRadius: '50%',
            marginRight: '12px'
        },
        holderOrgDiv: {
            display: 'flex',
            marginBottom: '2rem'
        },
        editBirthdayDiv: {
            boxSizing: 'border-box',
            width: '100%',
            padding: 16,
            marginTop: 10,
            borderRadius: 20,
            borderWidth: 1,
            outline: 'none',
            fontSize: '1rem',
            fontFamily: 'Manrope, sans-serif',
            marginBottom: '2rem',
            backgroundColor: 'white',
            border: '1px solid rgb(118, 118, 118)'
        },
        memberInfoContainer: {},
        contactInfoContainer: {},
        language: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '33px',
            color: '#1F1F51'
        },
        languageField: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            paddingTop: '10px'
        }
    })
);

type Props = {
    exited: boolean;
    holder: Homeless;
    setUnenroll: (value: boolean) => void;
    setReenroll: (value: boolean) => void;
    updateProfileCompletedItems: (type: string) => void;
    user: User;
};

const PrivateInfo: React.FC<Props> = ({
    exited,
    holder,
    setReenroll,
    setUnenroll,
    updateProfileCompletedItems,
    user
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        updatePrivateProfile,
        error,
        isLoading,
        isSuccess,
        isError,
        reset
    } = useUpdatePrivateProfile();

    const deployments = user?.partner?.deployments || [];
    const holderDeployment =
        deployments.find((dep) => dep.id === holder.deployment_id)?.id || '';
    const memberId = holder.id;

    const [newDeployment, setNewDeployment] = useState(
        holderDeployment ? holderDeployment : ''
    );
    const [newFirstName, setFirstName] = useState(
        holder.first_name ? holder.first_name : ''
    );
    const [newMiddleName, setMiddleName] = useState(
        holder.middle_name ? holder.middle_name : ''
    );
    const [newLastName, setLastName] = useState(
        holder.last_name ? holder.last_name : ''
    );
    const [newBirthday, setBirthday] = useState(
        holder.birthday ? holder.birthday : null
    );
    const [newIdentificationNumber, setIdentificationNumber] = useState(
        holder.member_id ? holder.member_id : ''
    );
    const [newQR, setQR] = useState(holder.qr_code_id ? holder.qr_code_id : '');

    const [newPhoneNumber, setPhoneNumber] = useState(
        formatHolderPhone(holder.phone_number ? holder.phone_number : '')
    );
    const [newEmail, setEmail] = useState(holder.email ? holder.email : '');
    const [newLanguage, setNewLanguage] = useState(
        holder.language_preference ? holder.language_preference : ''
    );
    const [updateLanguage, setUpdateLanguage] = useState(false);

    const [documentList, setDocumentList] = useState<null | object[]>(null);
    const [isEditingMemberInfo, setIsEditingMemberInfo] = useState(false);
    const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDocs = useCallback(
        () =>
            fetchDocumentsForId(Number(memberId))
                .then((res: Response) => res.json())
                .then((items: { holder_documents: object[] }) => {
                    setDocumentList(items.holder_documents);
                    setLoading(false);
                }),
        [memberId]
    );

    useEffect(() => {
        if (!documentList) {
            fetchDocs();
        }
    }, [documentList, fetchDocs]);

    useEffect(() => {
        if (isSuccess) dispatch(getHomelessInfo('', '', '', memberId));
    }, [isSuccess, dispatch, memberId]);

    const handleSaveMemberInfo = useCallback(() => {
        const changedState = {} as {
            first_name?: string;
            middle_name?: string;
            last_name?: string;
            birthday?: string | null;
            deployment_id?: number | string | null;
            id_num?: string;
            qr_id?: string;
        };
        const _formData = new FormData();

        if (newFirstName !== holder.first_name)
            changedState['first_name'] = newFirstName;
        if (newMiddleName !== holder.middle_name)
            changedState['middle_name'] = newMiddleName;
        if (newLastName !== holder.last_name)
            changedState['last_name'] = newLastName;
        if (newBirthday !== holder.birthday)
            changedState['birthday'] = newBirthday;
        if (newDeployment !== holderDeployment)
            changedState['deployment_id'] = newDeployment;
        if (newIdentificationNumber !== holder.member_id)
            changedState['id_num'] = newIdentificationNumber;
        if (newQR !== holder.qr_code_id) changedState['qr_id'] = newQR;

        if (!isObjectEmpty(changedState)) {
            if ('birthday' in changedState) {
                _formData.append(
                    'samaritan_member[date_of_birth]',
                    changedState['birthday'] ?? ''
                );
                updateProfileCompletedItems('birthday');
            }
            if ('deployment_id' in changedState) {
                _formData.append(
                    'samaritan_member[deployment_id]',
                    changedState['deployment_id']
                );
            }
            if ('first_name' in changedState) {
                _formData.append(
                    'samaritan_member[pii_first_name]',
                    changedState['first_name']
                );
                updateProfileCompletedItems('full_name');
            }
            if ('id_num' in changedState) {
                _formData.append(
                    'samaritan_member[pii_member_id]',
                    changedState['id_num']
                );
                updateProfileCompletedItems('member_id');
            }
            if ('last_name' in changedState) {
                _formData.append(
                    'samaritan_member[pii_last_name]',
                    changedState['last_name']
                );
                updateProfileCompletedItems('full_name');
            }
            if ('middle_name' in changedState) {
                _formData.append(
                    'samaritan_member[pii_middle_name]',
                    changedState['middle_name']
                );
            }
            if ('qr_id' in changedState) {
                _formData.append(
                    'samaritan_member[qr_id]',
                    changedState['qr_id']
                );
            }

            updatePrivateProfile(memberId, _formData);
        }
    }, [
        memberId,
        newBirthday,
        newDeployment,
        newFirstName,
        newIdentificationNumber,
        newLastName,
        newMiddleName,
        newQR,
        holder.birthday,
        holder.first_name,
        holder.last_name,
        holder.member_id,
        holder.middle_name,
        holder.qr_code_id,
        holderDeployment,
        updateProfileCompletedItems,
        updatePrivateProfile
    ]);

    const handleSaveContactInfo = useCallback(() => {
        const changedState = {};
        const _formData = new FormData();

        if (
            newPhoneNumber !== formatHolderPhone(holder.phone_number) &&
            validatePhone(newPhoneNumber)
        )
            changedState['phone'] = newPhoneNumber;
        if (newEmail !== holder.email && validateEmail(newEmail))
            changedState['email'] = newEmail;
        if (newLanguage !== holder.language_preference)
            changedState['language'] = newLanguage;

        if (!isObjectEmpty(changedState)) {
            if ('phone' in changedState) {
                if (isObjectEmpty(changedState['phone'])) {
                    _formData.append('samaritan_member[phone]', []);
                } else {
                    _formData.append(
                        'samaritan_member[phone]',
                        `+1${changedState['phone'].replace(/\D/g, '')}`
                    );
                }

                updateProfileCompletedItems('contact_info');
            }
            if ('email' in changedState) {
                _formData.append(
                    'samaritan_member[email]',
                    changedState['email']
                );

                updateProfileCompletedItems('contact_info');
            }
            if ('language' in changedState) {
                _formData.append(
                    'samaritan_member[language_preference]',
                    changedState['language']
                );
            }

            updatePrivateProfile(memberId, _formData);

            if (isSuccess) {
                setIsEditingContactInfo(false);
            }
        }
    }, [
        isSuccess,
        memberId,
        newEmail,
        newLanguage,
        newPhoneNumber,
        holder.email,
        holder.language_preference,
        holder.phone_number,
        updateProfileCompletedItems,
        updatePrivateProfile
    ]);

    const editMemberProps = {
        newFirstName,
        setFirstName,
        newMiddleName,
        setMiddleName,
        newLastName,
        setLastName,
        newBirthday,
        setBirthday,
        deployments,
        newDeployment,
        setNewDeployment,
        newIdentificationNumber,
        setIdentificationNumber,
        newQR,
        setQR,
        handleSave: handleSaveMemberInfo
    };

    const editContactProps = {
        holder,
        newEmail,
        setEmail,
        newLanguage,
        setNewLanguage,
        newPhoneNumber,
        setPhoneNumber,
        handleSave: handleSaveContactInfo,
        updateLanguage,
        setUpdateLanguage,
        validateEmail,
        validatePhone
    };

    if (isLoading) {
        return (
            <div className={classes.privateInfoContainer}>
                <div style={{ width: '100%', height: '100px' }}>
                    <LoadingCircle>Saving Changes</LoadingCircle>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.privateInfoContainer}>
            {isError && (
                <RequestErrorModal
                    open={isError}
                    onClose={() => {
                        reset();
                    }}
                    message={`Save error: ${error}`}
                    retryFunc={
                        isEditingContactInfo
                            ? handleSaveContactInfo
                            : handleSaveMemberInfo
                    }
                />
            )}
            <MemberInfo
                classes={classes}
                deployments={deployments}
                exited={exited}
                formatDate={formatDate}
                holder={holder}
                setIsEditing={setIsEditingMemberInfo}
            />
            <ContactInfo
                classes={classes}
                exited={exited}
                formatPhoneNumber={formatPhoneNumber}
                holder={holder}
                setIsEditing={setIsEditingContactInfo}
            />
            {((exited && documentList?.length) || !exited) && (
                <DocumentStorage
                    documentList={documentList}
                    exited={exited}
                    fetchDocs={fetchDocs}
                    holder={holder.name}
                    loading={loading}
                />
            )}
            <Enrollment
                exited={exited}
                holder={holder}
                setReenroll={setReenroll}
                setUnenroll={setUnenroll}
            />
            {isEditingMemberInfo && (
                <PopUp
                    classes={classes}
                    content="edit member info"
                    handleModalClick={setIsEditingMemberInfo}
                    {...editMemberProps}
                />
            )}
            {isEditingContactInfo && (
                <PopUp
                    classes={classes}
                    content="edit contact info"
                    handleModalClick={setIsEditingContactInfo}
                    {...editContactProps}
                />
            )}
        </div>
    );
};

export default PrivateInfo;
