import { useEffect, useState } from 'react';

import { Deployments } from 'types';

import { EditButton } from '../buttons';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        inputContainer: {
            marginBottom: '40px'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 6px 0px',
            resize: 'none',
            width: '100%',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        saveInputBtnContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingBottom: '15px',
            '& button': {
                cursor: 'pointer',
                fontFamily: 'Manrope',
                fontSize: '16px',
                fontWeight: '800'
            }
        },
        saveBtnDisabled: {
            opacity: '50%',
            '& button': {
                cursor: 'auto'
            }
        },
        skipStateContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        skipStateInput: {
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 700
        },
        editIconContainer: {
            cursor: 'pointer',
            height: 'auto',
            margin: '0 0 0 15px',
            width: '1.5rem'
        },
        editIcon: {
            height: 'inherit',
            width: 'inherit'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        menuItem: {
            display: 'flex',
            alignItems: 'center',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px'
        },
        orgLogo: {
            borderRadius: '50%',
            height: '32px',
            marginRight: '12px',
            width: '32px'
        },
        orgContainer: {
            display: 'flex',
            paddingTop: '3px'
        },
        optionalLabel: {
            color: '#7A4406',
            fontSize: '0.8rem',
            fontWeight: 300,
            letterSpacing: '0.15em',
            marginLeft: '8px'
        },
        requiredLabel: {
            color: '#A80A2D',
            fontSize: '0.8rem',
            fontWeight: 300,
            letterSpacing: '0.15em',
            marginLeft: '8px'
        }
    })
);

type OrgInputProps = {
    deployment: Deployments[];
    intakeInput: number;
    inputTitleClass: (input: string) => string;
    setIntakeInput: (input: number) => void;
};

const OrgInput: React.FC<OrgInputProps> = ({
    deployment,
    intakeInput,
    inputTitleClass,
    setIntakeInput
}) => {
    const classes = useStyles();

    const [input, setInput] = useState<string>('');
    const [savedInput, setSavedInput] = useState<boolean>(false);

    const handleChangeDep = (e: React.ChangeEvent<{ value: unknown }>) => {
        const dep = deployment.find(
            (org) => org.name === e.target.value
        ) as Deployments;

        setInput(dep.name);
        setIntakeInput(dep.id);
    };

    const handleEditClick = () => {
        setSavedInput(false);
    };

    const handleInputClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if ((e.target as HTMLSelectElement).value === undefined) {
            return;
        }

        setSavedInput(true);
    };

    useEffect(() => {
        if (intakeInput) {
            const dep = deployment.find((org) => org.id === intakeInput);

            setInput(dep?.name as string);
        }
    }, [deployment, intakeInput]);

    return (
        <div className={classes.inputContainer}>
            <span className={inputTitleClass(intakeInput.toString())}>
                Member's Insurance
                <span className={classes.requiredLabel}>(REQUIRED)</span>
            </span>
            {!savedInput ? (
                <Select
                    className={classes.inputFields}
                    disableUnderline
                    displayEmpty
                    onChange={(e) => handleChangeDep(e as any)}
                    onClick={(e) => handleInputClick(e)}
                    value={input}
                    variant="standard"
                >
                    <MenuItem disabled value="">
                        <span
                            className={classes.menuItem}
                            style={{ fontStyle: 'italic', opacity: '0.6' }}
                        >
                            Click to select
                        </span>
                    </MenuItem>
                    {deployment.length > 0
                        ? deployment.map((org, index) => {
                              return (
                                  <MenuItem
                                      key={index}
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                      }}
                                      value={org.name}
                                  >
                                      <span className={classes.menuItem}>
                                          {org.name}
                                      </span>
                                  </MenuItem>
                              );
                          })
                        : null}
                </Select>
            ) : (
                <div className={classes.orgContainer}>
                    <EditButton
                        handleEditClick={handleEditClick}
                        inputValState={input}
                        saveInputState={savedInput}
                    />
                </div>
            )}
        </div>
    );
};

export default OrgInput;
