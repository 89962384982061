import SamaritanFetch from './httpClient';

export const fetchCatchUpItems = (memberId: number) => {
    return SamaritanFetch.v3(
        `/samaritan_members/${memberId}/catch_up_items?member_catch_up_item[relations][record]{}&member_catch_up_items[status][]=active`,
        {
            method: 'GET'
        }
    );
};

// &member_catch_up_items[status][]=snoozed

export const snoozeCatchUpItem = (
    catchUpItemId: number,
    _snoozeTill?: string
) =>
    SamaritanFetch.v3.put(
        `/member_catch_up_items/${catchUpItemId}?member_catch_up_item[status]=snoozed`
    );