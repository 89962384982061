import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import LoadingCircle from '../../../_shared/LoadingCircle';

import { unenrollHomeless } from 'api/homeless';
import {
    resetUnenrollmentState,
    setUnenrollmentState
} from 'redux/actions/unenrollment';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '20px 25px',
        height: 'auto',
        borderRadius: '16px',
        backgroundColor: '#EDEDFA',
        color: '#000'
    },
    headerTitle: {
        fontWeight: 800,
        fontSize: '24px',
        lineHeight: '32px',
        color: '#1F2152',
        marginBottom: '8px'
    },
    headerTitleDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    headerSubTitleDiv: {
        marginBottom: '1.5em'
    },
    headerSubTitle: {
        fontWeight: 300,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#1F2152'
    },
    closeBtn: {
        padding: 0,
        minWidth: '30px',
        minHeight: '30px',
        color: '#7378E8'
    },
    closeBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    cardContainer: {
        padding: '10px 25px',
        height: 'auto',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        margin: '20px 0'
    },
    cardContent: {
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    cardContentTitle: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#7378E8'
    },
    stepTitle: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '24px',
        color: '#7378E8'
        // marginBottom: '2px'
    },
    stepSubTitle: {
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#1F2152',
        marginTop: '2px'
    },
    stepContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 25px',
        height: 'auto',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        margin: '20px 0',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FBFBFF'
        }
    },
    stepContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    balance: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#1F2152'
    },
    icon: {
        marginRight: '24px',
        color: '#7378E8',
        fontSize: '20px'
    },
    chevronIcon: {
        alignSelf: 'center',
        color: '#7378E8',
        fontSize: '16px'
    },
    checkIcon: {
        alignSelf: 'center',
        color: '#7FE8B2',
        fontSize: '24px'
    },
    unenrollButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '15px 0'
    },
    unenrollButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '8px',
        padding: '20px 16px',
        color: '#A6A6BF',
        cursor: 'pointer',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '500',
        textTransform: 'none',
        minWidth: '50%',
        textAlign: 'left',
        '& i': {
            fontSize: '18px'
        },
        '& .active': {
            backgroundColor: '#7378E8',
            color: '#FFFFFF',
            border: '1px solid #7378E8'
        }
    },
    iconStatus: {
        display: 'flex',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#7378E8',
        color: '#FFFFFF',
        border: '1px solid #7378E8',
        '&:hover': {
            backgroundColor: '#999DFF',
            color: '#FFFFFF',
            border: '1px solid #999DFF'
        }
    },
    disabled: {
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#FFFFFF'
        }
    },
    unenrollContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0',
        '&:first-child': {
            paddingTop: '20px'
        },
        '&:not(:first-child)': {
            borderBottom: '1px solid #E3E3FA'
        }
    },
    textInput: {
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#1F2152',
        width: '100%',
        border: 'none',
        resize: 'none',
        paddingTop: '12px',
        overflow: 'auto',
        '&:placeholder-shown': {
            opacity: '0.5',
            fontWeight: '100'
        },
        '&:focus': {
            outline: 'none'
        }
    },
    submitBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        margin: '60px 0 15px 0'
    },
    submitBtn: {
        fontFamily: 'Manrope',
        fontWeight: '700',
        fontSize: '16px',
        color: '#fff',
        width: '50%',
        backgroundColor: '#7378E8',
        padding: '20px 8px',
        margin: '0',
        cursor: 'pointer',
        borderRadius: '100px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#999DFF'
        }
    }
}));

function UnenrollMember({ holder, setShowUnenrollMember, ...props }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const internalTextAreaRef = useRef(null);
    const externalTextAreaRef = useRef(null);
    const optionalStepRef = useRef(null);

    const {
        externalContextState,
        internalContextState,
        redeemStatus,
        sdohStatus,
        successStatusActiveState,
        unenrollStatusState
    } = props;

    const [externalContext, setExternalContext] =
        useState(externalContextState);
    const [internalContext, setInternalContext] =
        useState(internalContextState);
    const [successStatusActive, setSuccessStatusActive] = useState(
        successStatusActiveState
    );
    const [unenrollStatus, setUnenrollStatus] = useState(unenrollStatusState);

    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    useEffect(() => {
        if (history.location.state && history.location.state.from) {
            if (
                history.location.state.from === `qol` ||
                history.location.state.from === `redeem`
            ) {
                optionalStepRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }
    }, [history, history.location.state]);

    const autosizeTextArea = (textAreaRef) => {
        if (textAreaRef && textAreaRef.current) {
            textAreaRef.current.style.height = '0';
            textAreaRef.current.style.height = `${
                textAreaRef.current.scrollHeight - 14
            }px`;
        }
    };

    const handleSubmitUnenroll = async () => {
        const _formData = new FormData();

        setLoading(true);

        _formData.append('samaritan_member[profile_stage]', 'EXITED');
        _formData.append(
            'samaritan_member[profile_stage_status]',
            unenrollStatus
        );

        if (internalContext) {
            _formData.append(
                'samaritan_member[profile_stage_internal_context]',
                internalContext
            );
        }

        if (externalContext) {
            _formData.append(
                'samaritan_member[profile_stage_external_context]',
                externalContext
            );
        }

        document.querySelector('#unenroll-container').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

        try {
            const unenroll = await unenrollHomeless(holder.id, _formData);

            if (unenroll.status === 200) {
                setLoading(false);
                setSuccess(true);

                setTimeout(() => {
                    setShowUnenrollMember(false);
                    history.push(`/holders`, { unenrollSuccess: true });
                }, 2000);
            }
        } catch (error) {
            console.log(`error`, error);
            setLoading(false);
        }
    };

    const deletePageState = () => {
        delete history.location.state;
        dispatch(resetUnenrollmentState());
    };

    const savePageState = (route) => {
        dispatch(
            setUnenrollmentState(
                route,
                externalContext,
                internalContext,
                successStatusActive,
                unenrollStatus
            )
        );
    };

    const openRedeemForm = () => {
        if (holder.balance > 0) {
            savePageState(`redeem`);

            const fromState = {
                from: `exitRedeem`
            };

            history.push(`${match.url}/redeem`, fromState);
        }
    };

    const openSDOHSurvey = () => {
        if (!sdohStatus) {
            savePageState(`qol`);

            const fromState = {
                from: `exitQol`
            };

            history.push(`${match.url}/qol`, fromState);
        }
    };

    autosizeTextArea(internalTextAreaRef);
    autosizeTextArea(externalTextAreaRef);

    return (
        <div className={classes.wrapper} id="unenroll-container">
            <div className={classes.headerTitleDiv}>
                <h2 className={classes.headerTitle}>
                    Unenrollling {holder.name}
                </h2>
            </div>

            {isLoading && !isSuccess ? (
                <div className={classes.cardContainer}>
                    <LoadingCircle />
                </div>
            ) : null}

            {isSuccess ? (
                <div className={classes.cardContainer}>
                    <h2
                        className={classes.headerTitle}
                        style={{ textAlign: 'center' }}
                    >
                        Member unenrolled
                    </h2>
                </div>
            ) : null}

            {!isLoading && !isSuccess ? (
                <>
                    <div className={classes.headerSubTitleDiv}>
                        <span className={classes.headerSubTitle}>
                            We'd love to learn more about why{' '}
                            {holder.first_name
                                ? holder.first_name
                                : holder.name}{' '}
                            no longer needs their membership.
                        </span>
                    </div>
                    <div className={classes.cardContainer}>
                        <div className={classes.unenrollContent}>
                            <div className={classes.cardContentTitle}>
                                Why are you unenrolling{' '}
                                {holder.first_name
                                    ? holder.first_name
                                    : holder.name}
                                ?
                            </div>
                            <div className={classes.unenrollButtonContainer}>
                                <Button
                                    className={
                                        successStatusActive
                                            ? `${classes.unenrollButton} ${classes.active}`
                                            : classes.unenrollButton
                                    }
                                    variant="outlined"
                                    onClick={() => {
                                        setUnenrollStatus(`SUCCESSFUL`);
                                        setSuccessStatusActive(true);
                                    }}
                                >
                                    <div className={classes.iconStatus}>
                                        <i className="far fa-smile" />
                                        <span
                                            style={
                                                successStatusActive
                                                    ? {
                                                          fontWeight: '600',
                                                          margin: '0 8px'
                                                      }
                                                    : {
                                                          margin: '0 8px'
                                                      }
                                            }
                                        >
                                            Member was successful and no longer
                                            needs their membership
                                        </span>
                                    </div>
                                    <i
                                        className={
                                            successStatusActive
                                                ? 'fas fa-check-circle'
                                                : 'far fa-circle'
                                        }
                                    />
                                </Button>
                                <Button
                                    className={
                                        successStatusActive
                                            ? classes.unenrollButton
                                            : `${classes.unenrollButton} ${classes.active}`
                                    }
                                    variant="outlined"
                                    onClick={() => {
                                        setUnenrollStatus(`UNSUCCESSFUL`);
                                        setSuccessStatusActive(false);
                                    }}
                                >
                                    <div className={classes.iconStatus}>
                                        <i className="far fa-frown" />
                                        <span
                                            style={
                                                !successStatusActive
                                                    ? {
                                                          fontWeight: '600',
                                                          margin: '0 8px'
                                                      }
                                                    : {
                                                          margin: '0 8px'
                                                      }
                                            }
                                        >
                                            {holder.first_name
                                                ? holder.first_name
                                                : holder.name}{' '}
                                            might still need it, but should no
                                            longer be a member
                                        </span>
                                    </div>
                                    <i
                                        className={
                                            !successStatusActive
                                                ? 'fas fa-check-circle'
                                                : 'far fa-circle'
                                        }
                                    />
                                </Button>
                            </div>
                        </div>
                        <div className={classes.unenrollContent}>
                            <div className={classes.cardContentTitle}>
                                Additional Context
                            </div>
                            <textarea
                                className={classes.textInput}
                                ref={internalTextAreaRef}
                                rows={1}
                                value={internalContext}
                                name="internalContext"
                                placeholder={`These details will only be seen by ${
                                    holder.first_name
                                        ? holder.first_name
                                        : holder.name
                                }'s Care Providers.`}
                                onChange={(e) =>
                                    setInternalContext(e.target.value)
                                }
                            />
                        </div>
                        <div
                            className={classes.unenrollContent}
                            style={{ margin: '30px 0 20px 0' }}
                        >
                            <div className={classes.cardContentTitle}>
                                Final Update to Supporters
                            </div>
                            <textarea
                                className={classes.textInput}
                                ref={externalTextAreaRef}
                                rows={1}
                                value={externalContext}
                                name="externalContext"
                                placeholder={`We'll share this parting message with ${
                                    holder.first_name
                                        ? holder.first_name
                                        : holder.name
                                }'s supporters.`}
                                onChange={(e) =>
                                    setExternalContext(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={classes.headerSubTitle}
                        ref={optionalStepRef}
                        style={{ marginTop: '50px' }}
                    >
                        <div className={classes.headerSubTitleDiv}>
                            <span className={classes.headerSubTitle}>
                                Here are some recommended but optional steps in
                                closing out{' '}
                                {holder.first_name
                                    ? holder.first_name
                                    : holder.name}
                                's membership.
                            </span>
                        </div>
                    </div>
                    <div
                        className={
                            !sdohStatus
                                ? classes.stepContainer
                                : `${classes.stepContainer} ${classes.disabled}`
                        }
                        onClick={() => openSDOHSurvey()}
                    >
                        <div className={classes.cardContent}>
                            <i
                                className={`${classes.icon} fal fa-tachometer-alt`}
                            />
                            <div className={classes.stepContent}>
                                <div className={classes.stepTitle}>
                                    Quality of Life Assessment
                                </div>
                                <span className={classes.stepSubTitle}>
                                    Capture a final snapshot of your client's
                                    progress
                                </span>
                            </div>
                        </div>
                        {sdohStatus ? (
                            <i
                                className={`${classes.checkIcon} fas fa-check-circle`}
                            />
                        ) : (
                            <i
                                className={`${classes.chevronIcon} fal fa-chevron-right`}
                            />
                        )}
                    </div>
                    <div
                        className={
                            holder.balance > 0
                                ? classes.stepContainer
                                : `${classes.stepContainer} ${classes.disabled}`
                        }
                        onClick={() => openRedeemForm()}
                    >
                        <div className={classes.cardContent}>
                            <i
                                className={`${classes.icon} fal fa-money-bill`}
                            />
                            <div className={classes.stepContent}>
                                <div className={classes.stepTitle}>
                                    Spend Remaining Funds
                                </div>
                                <span className={classes.balance}>
                                    Balance: ${holder.balance}
                                </span>
                                <span className={classes.stepSubTitle}>
                                    Help your client spend the remainder of
                                    their balance
                                </span>
                            </div>
                        </div>
                        {redeemStatus ? (
                            <i
                                className={`${classes.checkIcon} fas fa-check-circle`}
                            />
                        ) : (
                            <i
                                className={`${classes.chevronIcon} fal fa-chevron-right`}
                            />
                        )}
                    </div>
                    <div className={classes.submitBtnContainer}>
                        <Button
                            className={classes.submitBtn}
                            onClick={() => {
                                deletePageState();
                                handleSubmitUnenroll();
                            }}
                        >
                            <span>
                                Unenroll{' '}
                                {holder.first_name
                                    ? holder.first_name
                                    : holder.name}
                            </span>
                        </Button>
                    </div>
                </>
            ) : null}
        </div>
    );
}

const mapStateToProps = (state) => ({
    externalContextState: state.unenrollment.externalContext,
    internalContextState: state.unenrollment.internalContext,
    redeemStatus: state.unenrollment.redeemStatus,
    sdohStatus: state.unenrollment.sdohStatus,
    successStatusActiveState: state.unenrollment.successStatusActive,
    unenrollStatusState: state.unenrollment.unenrollStatus
});
UnenrollMember.propTypes = {
    holder: PropTypes.object.isRequired,
    setShowUnenrollMember: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(UnenrollMember);
