import AssignBodyItem from './AssignBodyItem';

import { DebitCardOptions } from 'types';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            position: 'relative',
            background: '#FBFBFF',
            boxSizing: 'border-box',
            padding: '24px 24px 0 24px',
            width: '100%'
        },
        bodyItems: {
            position: 'relative',
            top: '-56px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#EBEBFF',
            borderRadius: '16px',
            color: '#1F2152',
            paddingTop: '64px'
        },
        photoContainer: {
            position: 'absolute',
            left: 'calc(50% - 50px)',
            top: '4px',
            borderRadius: '50%',
            height: '100px',
            width: '100px',
            '& img': {
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        subtitle: {
            fontWeight: 300,
            padding: '0 8px 48px 8px',
            textAlign: 'center',
            '& b': {
                fontWeight: 900
            }
        }
    })
);

type AssignBodyProps = {
    debitCardOptions: DebitCardOptions[];
    photo: string;
    setAssignPopUp?: (assignView: boolean) => void;
    setDebitType: (type: 'givecard' | 'pex' | null) => void;
    setReplaceView?: (replaceView: boolean) => void;
};

const AssignBody: React.FC<AssignBodyProps> = ({
    debitCardOptions,
    photo,
    setAssignPopUp,
    setDebitType,
    setReplaceView
}) => {
    const classes = useStyles();

    return (
        <div className="default-body-container">
            <div className={classes.photoContainer}>
                <img src={photo} alt="profile" />
            </div>
            <div className={classes.content}>
                <h2 style={{ margin: '1rem 0 0.5rem 0' }}>
                    Assigning Debit Card
                </h2>
                <span className={classes.subtitle}>
                    Are you issuing a GiveCard or PEX Card? Identify the card by
                    looking for either "GiveCard" or "PEX" somewhere on the card
                </span>
                <div className={classes.body}>
                    <div className={classes.bodyItems}>
                        {debitCardOptions.map((cardOption) => (
                            <AssignBodyItem
                                key={cardOption.type}
                                cardOption={cardOption}
                                setAssignPopUp={setAssignPopUp}
                                setDebitType={setDebitType}
                                setReplaceView={setReplaceView}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignBody;
