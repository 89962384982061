import ReactGA from 'react-ga4';

import Card from '../../CaseManagement/components/Card';

import { renderLanguage } from '../utils';

import Button from '@mui/material/Button';

type Props = {
    classes: {
        body: string;
        contactInfoContainer: string;
        editButton: string;
        editButtonDiv: string;
        name: string;
        title: string;
    };
    exited: boolean;
    formatPhoneNumber: (phone: string) => string;
    holder: {
        email: string;
        language_preference: string;
        phone_number: string;
    };
    setIsEditing: (isEditing: boolean) => void;
};

const ContactInfo: React.FC<Props> = ({ classes, holder, ...props }) => {
    const { exited, formatPhoneNumber, setIsEditing } = props;
    const { email, language_preference, phone_number } = holder;

    const handleEditClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Edit Contact Info Click'
        });

        setIsEditing(true);

        const contactInfoContainer = document.getElementById(
            'contact-info-container'
        );

        if (contactInfoContainer) {
            contactInfoContainer.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div
            className={classes.contactInfoContainer}
            id="contact-info-container"
        >
            <Card info contact />
            <div className={classes.body}>
                {!exited && (
                    <div className={classes.editButtonDiv}>
                        <Button
                            onClick={() => handleEditClick()}
                            title="Edit Private Info"
                            className={classes.editButton}
                        >
                            <i className="far fa">&#xf040;</i>
                        </Button>
                    </div>
                )}
                <h5 className={classes.title}>Phone Number</h5>
                <h4 className={classes.name}>
                    {phone_number &&
                    phone_number !== null &&
                    phone_number.length !== 0
                        ? formatPhoneNumber(phone_number)
                        : '-'}
                </h4>
                <h5 className={classes.title}>Email</h5>
                <h4 className={classes.name}>
                    {email && email !== null && email.length !== 0
                        ? email
                        : '-'}
                </h4>
                <h5 className={classes.title}>Language Preference</h5>
                <h4 className={classes.name}>
                    {language_preference &&
                    language_preference !== null &&
                    language_preference.length !== 0
                        ? renderLanguage(language_preference)
                        : '-'}
                </h4>
            </div>
        </div>
    );
};

export default ContactInfo;
