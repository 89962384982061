import * as CONSTANTS from '../constants/constants';

import { GoalActions } from 'types';

import { removeObjectFromArrayByValue, updateObjectInArray } from '../../util';

const initialState = {
    hasFetchedHomelessGoalActions: false,
    homelessGoalActions: [],
    isEditingGoalAction: false,
    isFetchingHomelessGoalActions: false,
    isPostingGoalAction: false,
    isPostingGoalActionErrored: false,
    isPostingGoalActionSuccess: false,
    newHomelessGoalActions: []
};

export default function goal_actions_reducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case CONSTANTS.GET_HOMELESS_GOAL_ACTIONS:
            return {
                ...state,
                isFetchingHomelessGoalActions: true
            };
        case CONSTANTS.SET_HOMELESS_GOAL_ACTIONS:
            return {
                ...state,
                isFetchingHomelessGoalActions: false,
                hasFetchedHomelessGoalActions: true,
                homelessGoalActions: action.payload.map((ga: GoalActions) => ({
                    ...ga,
                    requestState: 'idle'
                })),
                newHomelessGoalActions: []
            };
        case CONSTANTS.SET_HOMELESS_GOAL_ACTION_REQUEST_STATE:
            return {
                ...state,
                homelessGoalActions: state.homelessGoalActions.map(
                    (ga: GoalActions) => {
                        return ga.id === action.payload.id
                            ? {
                                  ...ga,
                                  requestState: action.payload.requestState
                              }
                            : ga;
                    }
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_GOAL_ACTION_STATUS:
            return {
                ...state,
                homelessGoalActions: updateObjectInArray(
                    state.homelessGoalActions,
                    (item) => item.id === action.payload.id,
                    'status',
                    action.payload.status
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_GOAL_ACTION_PENDING_PAYMENTS:
            return {
                ...state,
                homelessGoalActions: updateObjectInArray(
                    state.homelessGoalActions,
                    (item) => item.id === action.payload.id,
                    'pending_payments',
                    action.payload.pendingPayments
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_GOAL_ACTION_MATCH_AMOUNT:
            return {
                ...state,
                homelessGoalActions: updateObjectInArray(
                    state.homelessGoalActions,
                    (item) => item.id === action.payload.id,
                    'match_value',
                    parseFloat(action.payload.matchAmount)
                )
            };
        case CONSTANTS.REMOVE_HOMELESS_GOAL_ACTION:
            return {
                ...state,
                homelessGoalActions: removeObjectFromArrayByValue(
                    state.homelessGoalActions,
                    'id',
                    action.payload
                )
            };

        case CONSTANTS.POSTING_NEW_HOMELESS_GOAL_ACTION:
            return {
                ...state,
                isPostingGoalAction: true,
                isPostingGoalActionErrored: false,
                isPostingGoalActionSuccess: false,
                newHomelessGoalActions: [
                    ...state.newHomelessGoalActions,
                    {
                        status: 'loading',
                        error: null,
                        tempRef: action.tempRef,
                        data: action.payload
                    }
                ]
            };
        case CONSTANTS.POSTED_NEW_HOMELESS_GOAL_ACTION: {
            const filteredHGA = state.newHomelessGoalActions.filter(
                (ga: GoalActions) => ga.tempRef !== action.tempRef
            );

            return {
                ...state,
                homelessGoalActions: [
                    ...state.homelessGoalActions,
                    {
                        ...action.newGoalAction,
                        requestState: 'idle'
                    }
                ],
                isPostingGoalAction: false,
                isPostingGoalActionErrored: false,
                isPostingGoalActionSuccess: true,
                newHomelessGoalActions: filteredHGA
            };
        }

        case CONSTANTS.FAILED_NEW_HOMELESS_GOAL_ACTION: {
            const mappedHGA = state.newHomelessGoalActions.map(
                (ga: GoalActions) => {
                    if (ga.tempRef === action.tempRef) {
                        return {
                            ...ga,
                            status: 'failed',
                            error: action.error
                        };
                    }
                    return ga;
                }
            );

            return {
                ...state,
                isPostingGoalAction: false,
                isPostingGoalActionErrored: true,
                newHomelessGoalActions: mappedHGA
            };
        }

        case CONSTANTS.MARK_SDOH_COMPLETED:
            const markedGoalAction = state.homelessGoalActions.map(
                (ga: GoalActions) => {
                    if (ga.id === action.goalActionId) {
                        return {
                            ...ga,
                            status: 'completed'
                        };
                    }
                    return ga;
                }
            );

            return {
                ...state,
                homelessGoalActions: markedGoalAction
            };
        case CONSTANTS.EDITING_HOMELESS_GOAL_ACTION:
            return {
                ...state,
                isEditingGoalAction: true
            };
        case CONSTANTS.EDITED_HOMELESS_GOAL_ACTION:
            return {
                ...state,
                isEditingGoalAction: false,
                homelessGoalActions: state.homelessGoalActions.map(
                    (ga: GoalActions) => {
                        if (ga.id === action.newGoalAction.id) {
                            return {
                                ...ga,
                                ...action.newGoalAction
                            };
                        }
                        return ga;
                    }
                )
            };
        default:
            return state;
    }
}
