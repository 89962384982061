import { Link } from 'react-router-dom';

import Logo from 'assets/icon-logo.png';
import NavItem from './Item';

import Routes from 'routes';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    sideNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh'
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        padding: '30px 20px 0 20px'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        fontSize: '28px',
        fontWeight: 600,
        padding: '40px 0 10px 28px',
        '& img': {
            height: '40px',
            marginRight: '16px',
            width: '40px'
        }
    },
    logoLink: {
        color: '#FFFFFF',
        textDecoration: 'none'
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    divider: {
        backgroundColor: '#A6A6BF',
        height: 1,
        margin: '20px auto',
        width: '85%'
    },
    ctpInfo: {
        display: 'flex',
        flexDirection: 'column',
        color: '#FFFFFF',
        padding: '10px 30px 40px 30px'
    },
    ctpName: {
        fontWeight: 700,
        marginBottom: 5
    },
    '@media (max-width: 768px)': {
        sideNavContainer: {
            height: 'calc(100vh - 75px)'
        }
    }
}));

type SideNavProps = {
    email: string;
    name: string;
    last_name: string;
};

export default function SideNav({ email, name, last_name }: SideNavProps) {
    const classes = useStyles();

    return (
        <div className={classes.sideNavContainer}>
            <div className={classes.header}>
                <img src={Logo} alt="logo" />
                <Link to="/holders" className={classes.logoLink}>
                    samaritan
                </Link>
            </div>
            <div className={classes.list}>
                <NavItem to={Routes.holder_list} title="Client List" />
                <NavItem to={Routes.intake} title="Member Intake" />
                <NavItem to={Routes.wiki} title="FAQ" newTab />
            </div>
            <div className={classes.footer}>
                <div className={classes.list}>
                    <NavItem to={Routes.settings} title="Settings" />
                    <NavItem to={Routes.logout} title="Logout" />
                </div>
                <div className={classes.divider} />
                <div className={classes.ctpInfo}>
                    <span
                        className={classes.ctpName}
                    >{`${name} ${last_name}`}</span>
                    <span>{email}</span>
                </div>
            </div>
        </div>
    );
}
