import SamaritanFetch from './httpClient';

export const fetchTouchpointQuestions = (): Promise<object> =>
    SamaritanFetch.v3('/touchpoint_questions', {
        query: {
            'touchpoint_question[relations][slider_options][relations][determinant_rank]':
                '{}',
            'touchpoint_questions[active]': true
        }
    });

export const fetchSupplementalQuestions = (): Promise<object> =>
    SamaritanFetch.v3('/touchpoint_questions', {
        query: {
            'touchpoint_questions[active]': true,
            'touchpoint_questions[question_type]': 'SUPPLEMENTAL_QUESTION'
        }
    });

export const submitSupplementalQuestionAnswer = (
    answer: object
): Promise<object> =>
    SamaritanFetch.v3.post('/touchpoint_answers', {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ touchpoint_answer: answer })
    });
