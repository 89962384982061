import { useEffect, useCallback } from 'react';

import Body from './components/Body';
import LoadingCircle from '../../_shared/LoadingCircle';

import AuthManager from '../../../auth';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    wrapper: {
        height: '100%',
        margin: '0 10px 10px',
        maxWidth: '800px',
        width: '75%'
    }
});

type TermsOfServiceProps = {
    agreed: boolean;
    answers: {};
    birthDay: string;
    deploymentId: number;
    email: string;
    firstName: string;
    goals: {
        amount: number;
        customGoals?: string;
        default?: boolean;
        description: string;
        due_at: string;
        goal?: {
            actions: {
                action_type: string;
                description: string;
                due_at: string;
                quality_of_life_measure_id: number[];
                stake_match_amount: number;
            }[];
            description: string;
            needs: [];
        };
        goals?: string[];
        quality_of_life_measure_id?: number[] | undefined;
    }[];
    icebreaker: string;
    id: number;
    intakeTouchpoints: { answer: string; questionId: number }[];
    interviewer: string;
    interviewerId: number;
    isSubmitting: boolean;
    isSubmitted: boolean;
    lastName: string;
    middleName: string;
    orgId: number | string;
    orgName: string;
    phoneNo: string;
    profileName: string;
    profilePic?: Blob;
    relevantId: string;
    setIntakeIcebreaker: (arg0: string) => void;
    submit: (arg0: any, arg1: any) => void;
    submitTouchpoint: (arg0: any, arg1: any) => void;
    toggleAgreed: () => void;
    touchpointIsSubmitted: boolean;
    touchpointIsSubmitting: boolean;
};

const TermsOfService = (props: TermsOfServiceProps) => {
    const AuthInstance = AuthManager.getInstance();
    const apiToken = AuthInstance.getApiToken();
    const classes = useStyles();
    const sessionToken = AuthInstance.getToken();

    const {
        agreed,
        birthDay,
        deploymentId,
        email,
        firstName,
        goals,
        icebreaker,
        id,
        intakeTouchpoints,
        interviewer,
        isSubmitting,
        isSubmitted,
        lastName,
        middleName,
        orgId,
        orgName,
        phoneNo,
        profileName,
        profilePic,
        relevantId,
        setIntakeIcebreaker,
        submit,
        submitTouchpoint,
        toggleAgreed,
        touchpointIsSubmitted,
        touchpointIsSubmitting,
        interviewerId
    } = props;

    const formData = () => {
        const _formData = new FormData();

        _formData.append('homeless[icebreaker]', icebreaker);
        _formData.append('homeless[interviewed_by]', interviewer);
        _formData.append(
            'homeless[interviewed_by_id]',
            interviewerId.toString()
        );
        _formData.append('homeless[name]', profileName);
        _formData.append('homeless[organization_id]', orgId.toString());

        _formData.append('is_virtual', 'true');
        _formData.append('send_email_update', 'true');
        _formData.append('SESSION_TOKEN', sessionToken?.toString() || '');

        if (birthDay.length > 0) {
            _formData.append('homeless[date_of_birth]', birthDay);
        }

        if (deploymentId) {
            _formData.append(
                'homeless[deployment_id]',
                deploymentId.toString()
            );
        }

        if (email.length > 0) {
            _formData.append('homeless[email]', email);
        }

        if (firstName.length > 0) {
            _formData.append('homeless[pii_first_name]', firstName);
        }

        if (lastName.length > 0) {
            _formData.append('homeless[pii_last_name]', lastName);
        }

        if (middleName.length > 0) {
            _formData.append('homeless[pii_middle_name]', middleName);
        }

        if (phoneNo.length > 0) {
            _formData.append(
                'homeless[phone]',
                `+1${phoneNo.replace(/\D/g, '')}`
            );
        }

        if (profilePic) {
            _formData.append('homeless[photo]', profilePic);
        }

        if (relevantId.length > 0) {
            _formData.append('homeless[pii_member_id]', relevantId);
        }

        return _formData;
    };

    const formattedGoalActionsForSubmission = useCallback(() => {
        const formattedGoalActions: {
            description: string;
            due_at: string;
            stake_match_amount: number;
            quality_of_life_measure_id: number[] | undefined;
        }[] = [];

        goals.forEach((goal) => {
            if (!goal.customGoals) {
                formattedGoalActions.push({
                    description: goal.description,
                    due_at: goal.due_at,
                    stake_match_amount: goal.amount,
                    quality_of_life_measure_id: goal.quality_of_life_measure_id
                });
            }
        });

        return formattedGoalActions;
    }, [goals]);

    const formattedGoalsForSubmission = useCallback(() => {
        const mapActions: { [key: string]: any[] } = {};

        goals.forEach((goal) => {
            if (goal.goal && goal.customGoals) {
                if (Object.keys(mapActions).includes(goal.goal.description)) {
                    mapActions[goal.goal.description] = [
                        ...mapActions[goal.goal.description],
                        goal
                    ];
                } else {
                    mapActions[goal.goal.description] = [goal];
                }
            }
        });

        const response: {
            description: string;
            actions: {
                action_type: string;
                description: string;
                due_at: string;
                quality_of_life_measure_id: number[];
                stake_match_amount: number;
            }[];
        }[] = [];

        Object.entries(mapActions).forEach((entry) => {
            const [description, goalActions] = entry;
            const actions: {
                action_type: string;
                description: string;
                due_at: string;
                quality_of_life_measure_id: number[];
                stake_match_amount: number;
            }[] = [];

            goalActions.forEach((goal) => {
                goal.goal.actions.forEach(
                    (a: {
                        action_type: string;
                        description: string;
                        due_at: string;
                        quality_of_life_measure_id: number[];
                        stake_match_amount: number;
                    }) => {
                        actions.push({
                            ...a,
                            description: a.description,
                            stake_match_amount: a.stake_match_amount
                        });
                    }
                );
            });
            response.push({ description, actions });
        });

        return response;
    }, [goals]);

    const formattedIntakeTouchpoints = useCallback(() => {
        const formattedTouchpoints: {
            answer: string;
            touchpoint_question_id: number;
        }[] = [];

        intakeTouchpoints.forEach((question) => {
            const formattedQuestion = {
                touchpoint_question_id: question.questionId,
                answer: question.answer
            };

            formattedTouchpoints.push(formattedQuestion);
        });

        return formattedTouchpoints;
    }, [intakeTouchpoints]);

    const setIcebreaker = () => {
        const _icebreaker = `EULA:${profileName.toUpperCase()} agreed to the Samaritan terms of service privacy policy on ${new Date().toLocaleDateString()} at ${new Date().toLocaleTimeString(
            'en-US'
        )}`;

        setIntakeIcebreaker(_icebreaker);
    };

    const submitHandler = () => {
        if (!agreed) return null;

        submit(sessionToken, formData());
    };

    const touchpointData = useCallback(() => {
        const data: {
            description: string;
            goals?: any[];
            goal_actions?: any[];
            homeless_id: number;
            is_test: boolean;
            public: boolean;
            touchpoint_answers_attributes?: any[];
        } = {
            description: 'default',
            homeless_id: id,
            is_test: false,
            public: true
        };

        if (goals.length > 0) {
            data['goals'] = formattedGoalsForSubmission();
            data['goal_actions'] = formattedGoalActionsForSubmission();
        }

        if (intakeTouchpoints.length > 0) {
            data['touchpoint_answers_attributes'] =
                formattedIntakeTouchpoints();
        }

        return { touchpoint: data };
    }, [
        formattedGoalActionsForSubmission,
        formattedGoalsForSubmission,
        formattedIntakeTouchpoints,
        goals.length,
        id,
        intakeTouchpoints.length
    ]);

    useEffect(() => {
        if (
            id &&
            isSubmitted &&
            !touchpointIsSubmitted &&
            !touchpointIsSubmitting
        ) {
            submitTouchpoint(apiToken, touchpointData());
        }
    }, [
        apiToken,
        id,
        isSubmitted,
        submitTouchpoint,
        touchpointData,
        touchpointIsSubmitted,
        touchpointIsSubmitting
    ]);

    if (isSubmitting || touchpointIsSubmitting) {
        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <LoadingCircle />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Body
                    agreed={agreed}
                    org={orgName}
                    profileName={profileName}
                    setIcebreaker={setIcebreaker}
                    submitHandler={submitHandler}
                    toggleAgreed={toggleAgreed}
                />
            </div>
        </div>
    );
};

export default TermsOfService;
