import { connect } from 'react-redux';

// TODO: Fix ts-ignore HolderProfile
// @ts-ignore
import HolderProfile from '../components/holder_profile';

import actions from '../redux/actions';

// state is object that holds reducers from combineReducers and hence the state in them
function mapStateToProps(state: any) {
    const {
        debitCardOptions,
        debitHistory,
        debitInfo,
        fullDebitHistory,
        isFetchingDebitCardOptions,
        isFetchingDebitHistory,
        isFetchingDebitInfo
    } = state.debitCard;
    const {
        deploymentOrgs,
        deploymentUsers,
        isFetchingDeploymentOrgs,
        isFetchingDeploymentUsers,
        isReferringMember,
        referMemberError
    } = state.deployments;
    const { determinantRanks, isFetchingDeterminantRanks } =
        state.determinantRanks; // don't need anymore
    const { isFetchingHomelessGoals } = state.goals;
    const {
        homelessGoalActions,
        isEditingGoalAction,
        isFetchingHomelessGoalActions,
        newHomelessGoalActions
    } = state.goalActions;
    const {
        assignedBonusAmount,
        catchUpItems,
        historyFilters,
        homelessHistory,
        homelessInfo,
        homelessLifeStory,
        isFetchingCatchUpItems,
        isFetchingHomelessHistory,
        isFetchingHomelessInfo,
        isFetchingHomelessLifeStory,
        notFoundHomelessInfo,
        profileCompletionPercentage,
        profileItems,
        profileRemainingItems,
        remainingBonusAmount
    } = state.homeless;
    const { caseloads } = state.homelesses;
    const { intakeId } = state.intake;
    const {
        homelessNeeds,
        isEditingNeed,
        isFetchingHomelessNeeds,
        newHomelessNeeds
    } = state.needs;
    const { fullMemberPayments, memberPayments, isFetchingMemberPayments } =
        state.payments;
    const { redemptionList } = state.redemption;
    const {
        goalActionCompletePopupId,
        layoutTitles,
        showGoalActionCompletePopup
    } = state.samaritan; // don't use goalActionPopups, update layoutTitles
    const { homelessTouchpoints, isFetchingHomelessTouchpoints } =
        state.touchpoints;
    const {
        improvedStatus,
        improvedStatusCount,
        isFetchingSupplementalQuestionAnswers,
        isFetchingTouchpointAnswers,
        isFetchingTouchpointAnswersById,
        isSubmittingTouchpointAnswers,
        previousAnswers,
        supplementalQuestionAnswers,
        touchpointAnswers,
        touchpointAnswersById,
        updatedAnswerIds,
        updatedQuestionIds
    } = state.touchpointAnswers;
    const {
        isFetchingSupplementalQuestions,
        isFetchingTouchpointQuestions,
        isFetchingTouchpointQuestionIds,
        isFetchingTouchpointQuestionQOLs,
        supplementalQuestions,
        supplementalQuestionIds,
        touchpointQuestions,
        touchpointQuestionIds,
        touchpointQuestionQOLs
    } = state.touchpointQuestions;
    const { route } = state.unenrollment;
    const { maxBonusAmount, user, state: userState } = state.user;

    return {
        assignedBonusAmount,
        caseloads,
        catchUpItems,
        debitCardOptions,
        debitHistory,
        debitInfo,
        deploymentOrgs,
        deploymentUsers,
        determinantRanks,
        fullDebitHistory,
        fullMemberPayments,
        goalActionCompletePopupId,
        historyFilters,
        homelessGoalActions,
        homelessHistory,
        homelessInfo,
        homelessLifeStory,
        homelessNeeds,
        homelessTouchpoints,
        improvedStatus,
        improvedStatusCount,
        intakeId,
        isEditingGoalAction,
        isEditingNeed,
        isFetchingCatchUpItems,
        isFetchingDebitCardOptions,
        isFetchingDebitHistory,
        isFetchingDebitInfo,
        isFetchingDeploymentOrgs,
        isFetchingDeploymentUsers,
        isFetchingDeterminantRanks,
        isFetchingHomelessGoals,
        isFetchingHomelessGoalActions,
        isFetchingHomelessHistory,
        isFetchingHomelessInfo,
        isFetchingHomelessLifeStory,
        isFetchingHomelessNeeds,
        isFetchingHomelessTouchpoints,
        isFetchingMemberPayments,
        isFetchingSupplementalQuestions,
        isFetchingSupplementalQuestionAnswers,
        isFetchingTouchpointAnswers,
        isFetchingTouchpointAnswersById,
        isFetchingTouchpointQuestions,
        isFetchingTouchpointQuestionIds,
        isFetchingTouchpointQuestionQOLs,
        isReferringMember,
        isSubmittingTouchpointAnswers,
        layoutTitles,
        maxBonusAmount,
        memberPayments,
        newHomelessGoalActions,
        newHomelessNeeds,
        notFoundHomelessInfo,
        previousAnswers,
        profileCompletionPercentage,
        profileItems,
        profileRemainingItems,
        redemptionList,
        referMemberError,
        remainingBonusAmount,
        route,
        showGoalActionCompletePopup,
        supplementalQuestions,
        supplementalQuestionAnswers,
        supplementalQuestionIds,
        touchpointAnswers,
        touchpointAnswersById,
        touchpointQuestions,
        touchpointQuestionIds,
        touchpointQuestionQOLs,
        updatedAnswerIds,
        updatedQuestionIds,
        user,
        userState
    };
}

const mapDispatchToProps = {
    assignDebitCard: actions.assignDebitCardRequest,
    createMemberCaseload: actions.createMemberCaseload,
    getCaseloads: actions.getCaseloads,
    getCatchUpItems: actions.fetchCatchUpItemsRequest,
    getDebitCardHistory: actions.getDebitCardHistory,
    getDebitCardInfo: actions.getDebitCardInfo,
    getDebitCardOptions: actions.fetchDebitCardOptionsRequest,
    getDeploymentOrgs: actions.fetchDeploymentOrgsRequest,
    getDeploymentUsers: actions.fetchDeploymentUsersRequest,
    getDeterminantRanks: actions.getDeterminantRanksRequest,
    getHistoryFilters: actions.getHistoryFilters,
    getHomelessGoalActions: actions.getHomelessGoalActions,
    getHomelessInfo: actions.getHomelessInfo,
    getHomelessLifeStory: actions.getHomelessLifeStory,
    getHomelessNeeds: actions.getHomelessNeeds,
    getMemberHistory: actions.getHomelessHistory,
    getMemberPayments: actions.getMemberPayments,
    getSupplementalQuestionAnswersRequest:
        actions.getSupplementalQuestionAnswersRequest,
    getSupplementalQuestionsRequest: actions.getSupplementalQuestionsRequest,
    getTouchpointQuestionsRequest: actions.getTouchpointQuestionsRequest,
    getTouchpointAnswersRequest2: actions.getTouchpointAnswersRequest2,
    getTouchpointAnswersByIdRequest: actions.getTouchpointAnswersByIdRequest,
    referMember: actions.referMemberRequest,
    removeMemberFromMyMembers: actions.removeMemberFromMyMembers,
    resetIntake: actions.resetIntake,
    saveMemberToMyMembers: actions.saveMemberToMyMembers,
    setGoalActionCompletePopupId: actions.setGoalActionCompletePopupId,
    setLayoutTitles: actions.setLayoutTitles,
    setProfileCompletedItems: actions.setProfileCompletedItems,
    toggleGoalActionCompletePopup: actions.setGoalActionCompletePopup,
    updateGoalActionMatchAmount: actions.updateGoalActionMatchAmount,
    updateGoalActionStatus: actions.updateGoalActionStatus,
    updateHomelessInfoField: actions.updateHomelessInfoField,
    updateHomelessLifeStory: actions.updateHomelessLifeStoryRequest,
    updateProfileCompletedItems: actions.updateProfileCompletedItems,
    updateTouchpointAnswers: actions.updateTouchpointAnswers
};

export default connect(mapStateToProps, mapDispatchToProps)(HolderProfile);
