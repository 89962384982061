import CONSTANTS from '../constants/constants';

import { DebitCard, DebitCardOptions } from 'types';

import * as homelessAPI from 'api/homeless';
import * as giveCardAPI from 'api/givecard';
import * as pexAPI from 'api/pex';

import { refreshHomelessBalance, updateHomelessBalance } from './homeless';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

function assignDebitCard() {
    return {
        type: CONSTANTS.ASSIGN_DEBIT_CARD
    };
}

function closeDebitCard() {
    return {
        type: CONSTANTS.CLOSE_DEBIT_CARD
    };
}

function fetchDebitCardHistory() {
    return {
        type: CONSTANTS.FETCH_DEBIT_CARD_HISTORY
    };
}

function fetchDebitCardInfo() {
    return {
        type: CONSTANTS.GET_DEBIT_CARD_INFO
    };
}

function fetchDebitCardOptions() {
    return {
        type: CONSTANTS.GET_DEBIT_CARD_OPTIONS
    };
}

function setDebitCardError(error: string) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_ERROR,
        payload: error
    };
}

function setDebitCardHistory(response: any) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_HISTORY,
        payload: response
    };
}

function setDebitCardFullHistory(response: any) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_FULL_HISTORY,
        payload: response
    };
}

export function setDebitCardInfo(response: DebitCard) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_INFO,
        payload: response
    };
}

function setDebitCardOptions(response: DebitCardOptions) {
    return {
        type: CONSTANTS.SET_DEBIT_CARD_OPTIONS,
        payload: response
    };
}

function setSuccessfulDebitResponse() {
    return {
        type: CONSTANTS.SET_SUCCESSFUL_DEBIT_RESPONSE
    };
}

function transferBalance() {
    return {
        type: CONSTANTS.TRANSFER_TO_DEBIT_CARD
    };
}

function updateDebitCardPin() {
    return {
        type: CONSTANTS.UPDATE_DEBIT_CARD_PIN
    };
}

function updateDebitCardPinError(error: string) {
    return {
        type: CONSTANTS.UPDATE_DEBIT_CARD_PIN_ERROR,
        payload: error
    };
}

function updateDebitCardPinSuccess(message: string) {
    return {
        type: CONSTANTS.UPDATE_DEBIT_CARD_PIN_SUCCESS,
        payload: message
    };
}

export function assignDebitCardRequest(
    memberID: number,
    type: 'givecard' | 'pex',
    last4: string,
    token: string,
    externalID?: string
) {
    return async (dispatch: Function) => {
        dispatch(assignDebitCard());

        try {
            const assignFunction =
                type === 'givecard'
                    ? () =>
                          giveCardAPI.assignGiveCard(
                              memberID,
                              last4,
                              token,
                              externalID
                          )
                    : () =>
                          pexAPI.assignPexCard(
                              memberID,
                              last4,
                              token,
                              externalID
                          );

            const response = await assignFunction();
            const responseJson = await response.json();

            if (!response.ok) {
                dispatch(setDebitCardError(responseJson.errors[0].description));

                handleSamaritanApiError(
                    responseJson.errors[0].description,
                    dispatch
                );
            } else {
                return dispatch(setDebitCardInfo(responseJson));
            }
        } catch (error: any) {
            return dispatch(handleSamaritanApiError(error, dispatch));
        }
    };
}

export function closeDebitCardRequest(
    memberID: number,
    type: 'givecard' | 'pex'
) {
    return async (dispatch: Function) => {
        dispatch(closeDebitCard());

        try {
            const closeFunction =
                type === 'givecard'
                    ? () => giveCardAPI.closeGiveCard(memberID)
                    : () => pexAPI.closePexCard(memberID);

            const response = await closeFunction();
            const responseJson = await response.json();

            if (!response.ok) {
                dispatch(setDebitCardError(responseJson.errors[0].description));

                handleSamaritanApiError(
                    responseJson.errors[0].description,
                    dispatch
                );
            } else {
                // console.log('message', responseJson.message);

                if (responseJson.success) {
                    fetchUpdatedMemberBalanceRequest(memberID)(dispatch);
                    dispatch(setSuccessfulDebitResponse());

                    setTimeout(() => {
                        dispatch(
                            setDebitCardInfo({
                                account_id: null,
                                available_balance: 0,
                                card_last4: null,
                                card_type: null,
                                total_balance: 0
                            })
                        );
                    }, 2000);
                } else {
                    dispatch(setDebitCardError(responseJson.message));
                }
            }
        } catch (error: any) {
            return dispatch(handleSamaritanApiError(error, dispatch));
        }
    };
}

export function fetchDebitCardOptionsRequest() {
    return async (dispatch: Function) => {
        dispatch(fetchDebitCardOptions());

        try {
            const response = await giveCardAPI.fetchDebitTypes();
            handleSamaritanApiResponse(response.clone(), dispatch);

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();
            const cardOptions = data.remote_copy?.card_options;

            if (cardOptions) {
                dispatch(setDebitCardOptions(cardOptions));
            }
        } catch (error: any) {
            handleSamaritanApiError(
                {
                    message: `Failed to fetch debit card options: ${error}`
                },
                dispatch
            );
        }
    };
}

export function fetchUpdatedMemberBalanceRequest(memberID: number) {
    return (dispatch: Function) => {
        return homelessAPI
            .fetchHomelessInfo(memberID)
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: { samaritan_member: { balance: number } }) => {
                dispatch(
                    refreshHomelessBalance(
                        responseJson.samaritan_member.balance
                    )
                );
            })
            .catch((error: any) => {
                if (error) {
                    return handleSamaritanApiError(error, dispatch);
                } else {
                    return handleSamaritanApiError(
                        {
                            message: `Failed to fetch homeless info for member: ${memberID}`
                        },
                        dispatch
                    );
                }
            });
    };
}

export function getDebitCardHistory(
    memberID: number,
    type: string,
    full?: boolean
) {
    return (dispatch: Function) => {
        dispatch(fetchDebitCardHistory());

        const fetchFunction =
            type === 'givecard'
                ? giveCardAPI.fetchGiveCardHistory(memberID, full || false)
                : pexAPI.fetchPexHistory(memberID, full || false);

        return fetchFunction
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: any) => {
                const actionCreator = full
                    ? setDebitCardFullHistory
                    : setDebitCardHistory;

                return dispatch(actionCreator(responseJson.message));
            })
            .catch((error: any) => {
                if (error) {
                    return handleSamaritanApiError(error, dispatch);
                } else {
                    return handleSamaritanApiError(
                        {
                            message: `Failed to fetch ${type} history for member: ${memberID}`
                        },
                        dispatch
                    );
                }
            });
    };
}

export function getDebitCardInfo(memberID: number) {
    return (dispatch: Function) => {
        dispatch(fetchDebitCardInfo());

        return homelessAPI
            .fetchHomelessInfo(memberID)
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then(
                (responseJson: {
                    samaritan_member: { balance: number; card: DebitCard };
                }) => {
                    dispatch(
                        refreshHomelessBalance(
                            responseJson.samaritan_member.balance
                        )
                    );
                    return dispatch(
                        setDebitCardInfo(responseJson.samaritan_member.card)
                    );
                }
            )
            .catch((_error: any) => {
                if (_error) {
                    return handleSamaritanApiError(_error, dispatch);
                } else {
                    return handleSamaritanApiError(
                        {
                            message: `Failed to fetch GiveCard/PEX info for member: ${memberID}`
                        },
                        dispatch
                    );
                }
            });
    };
}

export function resetDebitCardError() {
    return {
        type: CONSTANTS.RESET_DEBIT_ERROR
    };
}

export function transferBalanceRequest(
    memberID: number,
    type: 'givecard' | 'pex',
    amount: number,
    token: string
) {
    return (dispatch: Function) => {
        dispatch(transferBalance());

        const transferFunction =
            type === 'givecard'
                ? giveCardAPI.transferGiveCardFunds(memberID, amount)
                : pexAPI.transferPexFunds(memberID, amount, token);

        return transferFunction
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: any) => {
                dispatch(updateHomelessBalance(amount * -1));
                dispatch(
                    updateDebitCardBalance(responseJson.message.available)
                );
            })
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
}

export function updateDebitCardPinRequest(
    memberID: number,
    type: string,
    pin: number,
    token: string
) {
    return (dispatch: Function) => {
        dispatch(updateDebitCardPin());

        const updateFunction =
            type === 'givecard'
                ? giveCardAPI.updateGiveCardPin(memberID, pin)
                : pexAPI.updatePexPin(memberID, pin, token);

        return updateFunction
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: any) => {
                dispatch(updateDebitCardPinSuccess(responseJson.message));
            })
            .catch((error: any) => {
                console.log('error', error);
                dispatch(updateDebitCardPinError(error));
                return handleSamaritanApiError(error, dispatch);
            });
    };
}

export function updateDebitCardBalance(balance: number) {
    return {
        type: CONSTANTS.UPDATE_DEBIT_CARD_BALANCE,
        payload: { balance }
    };
}
