import CONSTANTS from '../constants/constants';

import { CatchUpItems } from 'types';

import { updateObject } from '../../util';

const profileItems = {
    action_steps: {
        status: 'incomplete',
        zIndex: 3
    },
    birthday: {
        status: 'incomplete',
        zIndex: 5
    },
    contact_info: {
        status: 'incomplete',
        zIndex: 3
    },
    full_name: {
        status: 'incomplete',
        zIndex: 1
    },
    member_id: {
        status: 'incomplete',
        zIndex: 0
    },
    name: {
        status: 'incomplete',
        zIndex: 6
    },
    needs: {
        status: 'incomplete',
        zIndex: 8
    },
    photo: {
        status: 'complete',
        zIndex: 2
    },
    status: {
        status: 'complete',
        zIndex: 4
    },
    supplemental_questions: {
        status: 'incomplete',
        zIndex: 7
    }
} as { [key: string]: { status: string; zIndex: number } };

const sortedProfileItems = Object.fromEntries(
    Object.entries(profileItems).sort(
        ([, itemA], [, itemB]) => itemA.zIndex - itemB.zIndex
    )
);

const initialState = {
    assignedBonusAmount: null,
    catchUpItems: null as CatchUpItems[] | null,
    // catchUpItems: [
    //     {
    //         id: 12,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_insurance_id',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:05:10-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 11,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_profile_photo',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:05:10-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 13,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_contact_info',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:05:10-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 14,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_date_of_birth',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:05:10-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 15,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_supplemental_answers',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:05:10-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 16,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'funds_above_25_dollars',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:10:37-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 17,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_status_capture_in_last_30_days',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:12:01-0700',
    //         relations: {
    //             record: {}
    //         }
    //     },
    //     {
    //         id: 18,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'action_step_due',
    //         record_type: 'GoalAction',
    //         record_id: 5,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:12:36-0700',
    //         relations: {
    //             record: {
    //                 id: 5,
    //                 description: 'asdasdasda',
    //                 status: 'pending',
    //                 touchpoint_id: null,
    //                 homeless_id: 8,
    //                 user_id: 1,
    //                 action_type: 'one_time',
    //                 ctp_caseload_referral_id: null,
    //                 goal: {
    //                     id: null,
    //                     description: null,
    //                     touchpoint_id: null,
    //                     homeless_id: null,
    //                     created_at: null,
    //                     type: null
    //                 },
    //                 quality_of_life: {
    //                     id: null,
    //                     description: null
    //                 },
    //                 quality_of_life_measures: [],
    //                 source_answer: null,
    //                 resulting_answer: null,
    //                 due_at: '2024-08-08T12:00:00-0700',
    //                 finished_at: null,
    //                 created_at: '2024-07-09T14:11:37-0700',
    //                 match_value: 12.0,
    //                 system_created: false
    //             }
    //         }
    //     },
    //     {
    //         id: 20,
    //         homeless_id: 8,
    //         status: 'active',
    //         type: 'no_full_name',
    //         record_type: null,
    //         record_id: null,
    //         snooze_till: null,
    //         created_at: '2024-07-09T14:16:38-0700',
    //         relations: {
    //             record: {}
    //         }
    //     }
    // ] as CatchUpItems[] | null,
    hasFetchedHomelessGoalActions: false,
    hasFetchedHomelessNeeds: false,
    historyFilters: [],
    homelessHistory: [],
    homelessInfo: {} as { [key: string]: any },
    homelessLifeStory: [],
    isFetchingHistoryFilters: false,
    isFetchingHomelessHistory: false,
    isFetchingHomelessInfo: false,
    isFetchingHomelessLifeStory: false,
    maxBonusAmount: null,
    notFoundHomelessInfo: false,
    profileItems: sortedProfileItems,
    remainingBonusAmount: null,
    updatedCatchUpItems: []
};

export default function homeless_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.COMPLETE_CATCH_UP_ITEM:
            const completedCatchUpItems = state.catchUpItems?.map((item) => {
                if (item.id === action.payload.catchUpItemId) {
                    return {
                        ...item,
                        status: 'completed', // remove this when the endpoint is live
                        snooze_till: action.payload.snoozeTill
                    };
                }
                return item;
            });

            return {
                ...state,
                catchUpItems: completedCatchUpItems
            };
        case CONSTANTS.FETCH_CATCH_UP_ITEMS:
            return {
                ...state,
                isFetchingCatchUpItems: true
            };
        case CONSTANTS.FETCH_HOMELESS_LIFESTORY:
            return {
                ...state,
                isFetchingHomelessLifeStory: true
            };
        case CONSTANTS.FETCH_HISTORY_FILTERS:
            return {
                ...state,
                isFetchingHistoryFilters: true
            };
        case CONSTANTS.FETCH_MEMBER_HISTORY:
            return {
                ...state,
                isFetchingHomelessHistory: true
            };
        case CONSTANTS.GET_HOMELESS_INFO:
            return {
                ...state,
                hasFetchedHomelessGoalActions: false,
                hasFetchedHomelessNeeds: false,
                isFetchingHomelessInfo: true
            };
        case CONSTANTS.REFRESH_HOMELESS_BALANCE:
            return {
                ...state,
                homelessInfo: updateObject(
                    state.homelessInfo,
                    'balance',
                    action.payload
                )
            };
        case CONSTANTS.RESET:
            return initialState;
        case CONSTANTS.RESET_HOMELESS_INFO:
            return {
                ...state,
                catchUpItems: initialState.catchUpItems,
                hasFetchedHomelessGoalActions: false,
                hasFetchedHomelessNeeds: false,
                homelessHistory: [],
                homelessInfo: {},
                isFetchingHomelessInfo: false,
                profileItems: initialState.profileItems,
                notFoundHomelessInfo: false
            };
        case CONSTANTS.SET_CATCH_UP_ITEMS:
            return {
                ...state,
                catchUpItems: action.payload,
                isFetchingCatchUpItems: false
            };
        case CONSTANTS.SET_HISTORY_FILTERS:
            return {
                ...state,
                historyFilters: action.payload,
                isFetchingHistoryFilters: false
            };
        case CONSTANTS.SET_HOMELESS_INFO:
            return {
                ...state,
                assignedBonusAmount:
                    action.payload.action_step_bonus_monthly_total,
                homelessInfo: action.payload,
                isFetchingHomelessInfo: false,
                remainingBonusAmount:
                    state.maxBonusAmount !== null
                        ? state.maxBonusAmount -
                          action.payload.action_step_bonus_monthly_total
                        : 0
            };
        case CONSTANTS.SET_HOMELESS_INFO_ERROR: {
            return {
                ...state,
                hasFetchedHomelessGoalActions: false,
                hasFetchedHomelessNeeds: false,
                isFetchingHomelessInfo: false,
                notFoundHomelessInfo: true
            };
        }
        case CONSTANTS.SET_HOMELESS_LIFESTORY:
            return {
                ...state,
                homelessLifeStory: action.payload,
                isFetchingHomelessLifeStory: false
            };
        case CONSTANTS.SET_MAX_BONUS_AMOUNT:
            return {
                ...state,
                maxBonusAmount: action.payload
            };
        case CONSTANTS.SET_MEMBER_HISTORY:
            return {
                ...state,
                homelessHistory: action.payload,
                isFetchingHomelessHistory: false
            };
        case CONSTANTS.SET_PROFILE_COMPLETED_ITEMS:
            const updatedProfileItems = Object.keys(state.profileItems).reduce(
                (acc, key) => {
                    if (action.payload.includes(key)) {
                        acc[key] = {
                            ...state.profileItems[key],
                            status: 'complete'
                        };
                    }
                    return acc;
                },
                { ...state.profileItems }
            );

            return {
                ...state,
                profileItems: updatedProfileItems
            };
        case CONSTANTS.SNOOZE_CATCH_UP_ITEM:
            const updatedCatchUpItems = state.catchUpItems?.map((item) => {
                if (item.id === action.payload.catchUpItemId) {
                    return {
                        ...item,
                        status: 'snoozed', // remove this when the endpoint is live
                        snooze_till: action.payload.snoozeTill
                    };
                }
                return item;
            });

            return {
                ...state,
                catchUpItems: updatedCatchUpItems
            };
        case CONSTANTS.UPDATE_BONUS_AMOUNT:
            return {
                ...state,
                assignedBonusAmount: state.assignedBonusAmount + action.payload,
                remainingBonusAmount:
                    (state.remainingBonusAmount ?? 0) - action.payload
            };
        case CONSTANTS.UPDATE_HOMELESS_BALANCE:
            return {
                ...state,
                homelessInfo: updateObject(
                    state.homelessInfo,
                    'balance',
                    state.homelessInfo.balance + action.payload.balance
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_CARD_BALANCE:
            return {
                ...state,
                homelessInfo: {
                    ...state.homelessInfo,
                    card: updateObject(
                        state.homelessInfo.card,
                        'available_balance',
                        state.homelessInfo.card.available_balance +
                            action.payload.balance
                    )
                }
            };
        case CONSTANTS.UPDATE_HOMELESS_INFO_FIELD:
            const mapProfileItemToCatchUpItem = {
                contact_info: 'no_contact_info',
                birthday: 'no_date_of_birth',
                full_name: 'no_full_name',
                member_id: 'no_insurance_id',
                photo: 'no_profile_photo',
                supplemental_questions: 'no_supplemental_answers'
            } as { [key: string]: string };

            const updatedProfileItemToCatchUpItem =
                mapProfileItemToCatchUpItem[action.payload.field];

            if (updatedProfileItemToCatchUpItem) {
                const updatedCatchUpItems = state.catchUpItems?.map((item) => {
                    if (item.type === updatedProfileItemToCatchUpItem) {
                        return {
                            ...item,
                            status: 'complete'
                        };
                    }
                    return item;
                });

                return {
                    ...state,
                    catchUpItems: updatedCatchUpItems,
                    homelessInfo: updateObject(
                        state.homelessInfo,
                        action.payload.field,
                        action.payload.value
                    )
                };
            }

            return {
                ...state,
                homelessInfo: updateObject(
                    state.homelessInfo,
                    action.payload.field,
                    action.payload.value
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_LIFESTORY:
            return {
                ...state,
                homelessLifeStory: [action.payload, ...state.homelessLifeStory],
                isFetchingHomelessLifeStory: false
            };
        case CONSTANTS.UPDATE_PROFILE_COMPLETED_ITEMS:
            return {
                ...state,
                profileItems: {
                    ...state.profileItems,
                    [action.payload]: {
                        status: 'pending'
                    }
                }
            };
        default:
            return state;
    }
}
