import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import {
    DebitCard,
    DebitCardOptions,
    Deployments,
    Homeless,
    TouchpointQuestions,
    User
} from 'types';

import Actions from './Actions';
import Account from './Account';
import Alert from './Alert';
import CongratsPage from './CongratsPage';
import Guide from './Guide';
import IntakeHeader from './Header';
import LoadingCircle from '../_shared/LoadingCircle';
import Modal from '../_shared/Modal';
import StatusCapture from './StatusCapture';
import TermsOfService from './terms_of_service';

import { isObjectEmpty } from 'util/index';

import AuthManager from '../../auth';
import { useRemoteCopy, useActionTemplate } from 'hooks';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto',
        height: '100vh',
        overflow: 'auto',
        width: '100%'
    },
    alert: {
        position: 'fixed',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        height: '100vh',
        width: 'calc(100vw - 300px)',
        zIndex: 3,
        '@media (max-width: 768px)': {
            left: 0,
            width: '100vw'
        }
    },
    guideBtn: {
        position: 'fixed',
        bottom: 30,
        right: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        border: '1px solid #FFFFFF80',
        borderRadius: '50px',
        boxShadow: '5px 6px 20px 0px #00000033',
        color: '#7378E8',
        fontSize: '24px',
        height: '64px',
        marginRight: '16px',
        minWidth: '64px !important'
    }
});

type IntakeProps = {
    ctpEmail: string;
    ctpId: number;
    ctpName: string;
    ctpPartner: User['partner'];
    decrementStep: () => void;
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    deployments: Deployments[]; // check this type
    deployment_id: number;
    getApiError: boolean;
    getApiErrorResponse: { error?: string; message?: string; text?: string };
    getTouchpointQuestions: (token: string) => void;
    incrementStep: () => void;
    homelesses: Homeless[];
    intakeAgreed: boolean;
    intakeBirthDay: string;
    intakeDeploymentId: number;
    intakeEmail: string;
    intakeGoals: any[]; // check this type
    intakeGuideMessages: any[]; // check this type
    intakeGuideSteps: any[]; // check this type
    intakeIcebreaker: string;
    intakeId: number;
    intakeIsSubmitted: boolean;
    intakeIsSubmitting: boolean;
    intakeNeeds: any[]; // check this type
    intakePhoneNo: string;
    intakePrivateFirstName: string;
    intakePrivateFullName: string;
    intakePrivateLastName: string;
    intakePrivateMiddleName: string;
    intakeProfileName: string;
    intakeProfilePic: any; // check this type
    intakeProgressWidth: { step: number; width: number }[];
    intakeQuestionsAndAnswers: any[]; // check this type
    intakeRelevantId: string;
    intakeStep: number;
    intakeTouchpointIsSubmitted: boolean;
    intakeTouchpointIsSubmitting: boolean;
    intakeTouchpoints: any[]; // check this type
    intake_steps: string[];
    isFetchingTouchpointQuestions: boolean;
    is_showing_intake_guides: boolean;
    layoutTitles: {
        title: string;
        subtitle: string;
    };
    maxBonusAmount: number;
    maxIntakeStep: number;
    members_enrolled: number;
    minIntakeStep: number;
    orgId: number;
    resetError: () => void;
    resetIntake: () => void;
    setIntakeBirthDay: (birthDay: string) => void;
    setIntakeDeploymentId: (deploymentId: number) => void;
    setIntakeEmail: (email: string) => void;
    setIntakeGoals: (goals: any[]) => void;
    setIntakeIcebreaker: (icebreaker: string) => void;
    setIntakePhoneNo: (phoneNo: string) => void;
    setIntakePrivateFirstName: (firstName: string) => void;
    setIntakePrivateFullName: (fullName: string) => void;
    setIntakePrivateLastName: (lastName: string) => void;
    setIntakePrivateMiddleName: (middleName: string) => void;
    setIntakeProfileName: (profileName: string) => void;
    setIntakeProfilePicture: (profilePic: any) => void;
    setIntakeRelevantId: (relevantId: string) => void;
    setIntakeTouchpoints: (questionId: number, answer: string) => void;
    setLayoutTitles: (title: string, subtitle: string) => void;
    setViewGuideMessage: (message: string) => void;
    submitIntake: (formData: any, token: string) => void;
    submitIntakeTouchpoint: (formData: any, token: string) => void;
    toggleIntakeAgreed: () => void;
    touchpointQuestions: TouchpointQuestions[]; // check this type
    updateUserSettings: (settings: any) => void;
};

// TODO: REFACTOR.
const Intake: React.FC<IntakeProps> = ({
    ctpEmail,
    ctpId,
    ctpName,
    ctpPartner,
    debitCardOptions,
    debitInfo,
    decrementStep,
    deployments,
    deployment_id,
    getApiError,
    getApiErrorResponse,
    getTouchpointQuestions,
    homelesses,
    incrementStep,
    intakeAgreed,
    intakeBirthDay,
    intakeDeploymentId,
    intakeEmail,
    intakeGoals,
    intakeGuideMessages,
    intakeGuideSteps,
    intakeIcebreaker,
    intakeId,
    intakeIsSubmitted,
    intakeIsSubmitting,
    intakePhoneNo,
    intakePrivateFirstName,
    intakePrivateFullName,
    intakePrivateLastName,
    intakePrivateMiddleName,
    intakeProfileName,
    intakeProfilePic,
    intakeProgressWidth,
    intakeQuestionsAndAnswers,
    intakeRelevantId,
    intakeStep,
    intakeTouchpointIsSubmitted,
    intakeTouchpointIsSubmitting,
    intakeTouchpoints,
    intake_steps,
    isFetchingTouchpointQuestions,
    is_showing_intake_guides,
    layoutTitles,
    maxBonusAmount,
    maxIntakeStep,
    members_enrolled,
    minIntakeStep,
    orgId,
    resetError,
    resetIntake,
    setIntakeBirthDay,
    setIntakeDeploymentId,
    setIntakeEmail,
    setIntakeGoals,
    setIntakeIcebreaker,
    setIntakePhoneNo,
    setIntakePrivateFirstName,
    setIntakePrivateFullName,
    setIntakePrivateLastName,
    setIntakePrivateMiddleName,
    setIntakeProfileName,
    setIntakeProfilePicture,
    setIntakeRelevantId,
    setIntakeTouchpoints,
    setLayoutTitles,
    setViewGuideMessage,
    submitIntake,
    submitIntakeTouchpoint,
    toggleIntakeAgreed,
    touchpointQuestions,
    updateUserSettings
}) => {
    const classes = useStyles();

    const AuthInstance = AuthManager.getInstance();
    const refetchActionCount = useRef(0);
    const refetchCount = useRef(0);
    const remoteIntake = useRemoteCopy(`intake`);

    const {
        isError: templateActionsError,
        isLoaded: templateActionsLoaded,
        refetch,
        templateActions
    } = useActionTemplate(parseInt(AuthInstance.getUserOrganizationId()!));

    const intakeSteps = intake_steps
        ? intake_steps
        : AuthInstance.getIntakeSteps() || [];

    const [guide, setGuide] = useState(
        is_showing_intake_guides ? is_showing_intake_guides : false
    );
    const [isErrored, setIsErrored] = useState(false);
    const [progressBarWidth, setProgressBarWidth] = useState(
        intakeProgressWidth ? intakeProgressWidth[intakeStep]?.width : 0
    );
    const [state, setState] = useState({
        showAlert: false,
        alertText: ''
    });
    const [toggleGuide, setToggleGuide] = useState(false);

    //
    // Alert
    //
    const closeAlert = () => {
        setState({
            ...state,
            alertText: '',
            showAlert: false
        });

        resetError();
    };

    const setAlert = useCallback(
        (text: string) => {
            setState({
                ...state,
                alertText: text,
                showAlert: true
            });
        },
        [state]
    );

    const alertComponent = () => {
        if (!state.showAlert) return null;
        return (
            <div className={classes.alert} onClick={closeAlert}>
                <Alert text={state.alertText} />
            </div>
        );
    };

    useEffect(() => {
        if (
            layoutTitles.title !== '' ||
            layoutTitles.subtitle !== 'Member Intake'
        ) {
            setLayoutTitles('', 'Member Intake');
            resetIntake();
        }
    }, [
        layoutTitles.subtitle,
        layoutTitles.title,
        resetIntake,
        setLayoutTitles
    ]);

    useEffect(() => {
        if (
            !state.showAlert &&
            getApiError &&
            getApiErrorResponse !== state.alertText &&
            intakeStep !== maxIntakeStep
        ) {
            let alertMessage = 'An error occurred. Please try again.';

            if (typeof getApiErrorResponse === 'object') {
                if (getApiErrorResponse.error) {
                    alertMessage = getApiErrorResponse.error;
                } else if (getApiErrorResponse.message) {
                    alertMessage = getApiErrorResponse.message;
                } else if (getApiErrorResponse.text) {
                    alertMessage = getApiErrorResponse.text;
                }
            } else if (typeof getApiErrorResponse === 'string') {
                alertMessage = getApiErrorResponse;
            }

            setAlert(alertMessage);

            if (getApiError) {
                return setAlert(alertMessage);
            }
        }
    }, [
        getApiError,
        getApiErrorResponse,
        intakeStep,
        maxIntakeStep,
        setAlert,
        state.alertText,
        state.showAlert
    ]);

    useEffect(() => {
        if (remoteIntake.isError && refetchCount.current < 5) {
            refetchCount.current++;
            remoteIntake.refetch();
        }

        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [remoteIntake]);

    useEffect(() => {
        if (templateActionsError && refetchActionCount.current < 5) {
            refetchActionCount.current++;
            refetch();
        }

        if (refetchActionCount.current >= 5) {
            setIsErrored(true);
        }
    }, [templateActionsError, refetch]);

    const callback = useCallback(() => {
        const token = AuthInstance.getToken();

        if (
            !isFetchingTouchpointQuestions &&
            token &&
            touchpointQuestions.length === 0
        ) {
            getTouchpointQuestions(token);
        }
    }, [
        AuthInstance,
        getTouchpointQuestions,
        isFetchingTouchpointQuestions,
        touchpointQuestions.length
    ]);

    //
    // Component Props
    //
    const accountProps = {
        deployments,
        incrementStep,
        intakeBirthDay,
        intakeDeploymentId,
        intakeEmail,
        intakePhoneNo,
        intakePrivateFirstName,
        intakePrivateFullName,
        intakePrivateLastName,
        intakePrivateMiddleName,
        intakeProfileName,
        intakeProfilePic,
        intakeRelevantId,
        progressBarWidth,
        remoteCopy: remoteIntake,
        setGuide,
        setIntakeBirthDay,
        setIntakeDeploymentId,
        setIntakeEmail,
        setIntakePhoneNo,
        setIntakePrivateFirstName,
        setIntakePrivateFullName,
        setIntakePrivateLastName,
        setIntakePrivateMiddleName,
        setIntakeProfileName,
        setIntakeProfilePicture,
        setIntakeRelevantId
    };

    const actionProps = {
        ctpId,
        intakeGoals,
        maxBonusAmount,
        progressBarWidth,
        remoteCopy: remoteIntake.remoteCopy,
        setGuide,
        setIntakeGoals,
        templateActions
    };

    const congratsProps = {
        debitCardOptions,
        debitInfo,
        homelesses,
        intakeId,
        memberName: intakeProfileName
    };

    const guideIntakeProps = {
        ctpId,
        ctpName,
        ctpPartner,
        intakeGuideMessages,
        intakeGuideSteps,
        intakeId,
        intakeIsSubmitted,
        intakeProfileName,
        intakeStep,
        intakeSteps,
        intakeTouchpointIsSubmitted,
        members_enrolled,
        minIntakeStep,
        guide,
        setGuide,
        setToggleGuide,
        setViewGuideMessage,
        toggleGuide,
        updateUserSettings
    };

    const headerStatusProps = {
        intakeAgreed,
        intakeBirthDay,
        intakeDeploymentId,
        intakeEmail,
        intakeGoals,
        intakePhoneNo,
        intakePrivateFullName,
        intakeProfileName,
        intakeProfilePic,
        intakeProgressWidth,
        intakeRelevantId,
        intakeTouchpoints,
        is_showing_intake_guides,
        progressBarWidth,
        setProgressBarWidth,
        touchpointQuestions:
            touchpointQuestions?.filter(
                (question: TouchpointQuestions) =>
                    question.type === 'StatusQuestion'
            ) || []
    };

    const statusCaptureProps = {
        incrementStep,
        intakeTouchpoints,
        progressBarWidth,
        setIntakeTouchpoints,
        touchpointQuestions
    };

    const termsOfServiceProps = {
        agreed: intakeAgreed,
        answers: intakeQuestionsAndAnswers,
        birthDay: intakeBirthDay,
        deploymentId: intakeDeploymentId ? intakeDeploymentId : deployment_id,
        email: intakeEmail,
        firstName: intakePrivateFirstName,
        goals: intakeGoals,
        icebreaker: intakeIcebreaker,
        id: intakeId,
        intakeTouchpoints,
        interviewer: ctpEmail,
        interviewerId: ctpId,
        isSubmitted: intakeIsSubmitted,
        isSubmitting: intakeIsSubmitting,
        lastName: intakePrivateLastName,
        middleName: intakePrivateMiddleName,
        orgId,
        orgName: ctpPartner?.name ? ctpPartner.name : '',
        phoneNo: intakePhoneNo,
        profileName: intakeProfileName,
        profilePic: intakeProfilePic,
        relevantId: intakeRelevantId,
        setIntakeIcebreaker,
        submit: submitIntake,
        submitTouchpoint: submitIntakeTouchpoint,
        toggleAgreed: toggleIntakeAgreed,
        touchpointIsSubmitted: intakeTouchpointIsSubmitted,
        touchpointIsSubmitting: intakeTouchpointIsSubmitting
    };

    const shortIntake = Array.isArray(intakeSteps)
        ? intakeSteps.filter(
              (step: string) => step !== 'Needs' && step !== 'Public Info'
          )
        : [];

    const dynamicIntake = () => {
        if (!shortIntake || shortIntake.length === 0) {
            return [
                <Account {...accountProps} />,
                <Actions {...actionProps} />,
                <StatusCapture {...statusCaptureProps} />
            ];
        }

        const intakeBody = shortIntake?.map((step: string) => {
            switch (step) {
                case `Action Steps`:
                    return <Actions {...actionProps} />;
                case `Private Info`:
                    return <Account {...accountProps} />;
                case `Status Capture`:
                    return <StatusCapture {...statusCaptureProps} />;
                default:
                    return null;
            }
        });

        return intakeBody;
    };

    const bodyComponents = [
        ...dynamicIntake(),
        <TermsOfService {...termsOfServiceProps} />,
        <CongratsPage {...congratsProps} />
    ];

    const isLoading =
        !remoteIntake.isLoaded ||
        !templateActionsLoaded ||
        (intakeIsSubmitted &&
            (isObjectEmpty(homelesses) ||
                (Array.isArray(homelesses) &&
                    !homelesses.find((homeless) => homeless.id === intakeId))));

    if (isErrored) {
        return (
            <Modal open={isErrored}>
                <div style={{ padding: 20 }}>
                    <div className="font-lg">Something has gone wrong.</div>
                    <div>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </div>
                </div>
            </Modal>
        );
    }

    if (isLoading) {
        return <LoadingCircle />;
    }

    return (
        <div ref={callback} className={classes.root} id="intake-container">
            <Helmet title="Intake" />
            {(is_showing_intake_guides || toggleGuide) &&
                intakeStep !== maxIntakeStep &&
                !intakeTouchpointIsSubmitting && (
                    <Guide {...guideIntakeProps} />
                )}
            {intakeStep < maxIntakeStep && !isLoading && (
                <IntakeHeader
                    decrementStep={decrementStep}
                    intakeSteps={shortIntake}
                    maxStep={maxIntakeStep}
                    minStep={minIntakeStep}
                    step={intakeStep}
                    {...headerStatusProps}
                />
            )}
            {alertComponent()}
            {bodyComponents[intakeStep - 1]}
            {!is_showing_intake_guides && (
                <Button
                    className={classes.guideBtn}
                    onClick={() => setToggleGuide(true)}
                >
                    <i className="fas fa-question" />
                </Button>
            )}
        </div>
    );
};

export default Intake;