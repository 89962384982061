import CONSTANTS from "../constants/constants";

import { SupplementalQuestionAnswers, TouchpointAnswers } from 'types';

const initialState = {
    improvedStatusCount: null,
    improvedStatus: [],
    isFetchingSupplementalQuestionAnswers: false,
    isFetchingTouchpointAnswers: false,
    isFetchingTouchpointAnswersById: false,
    isSubmittingStatusCapture: false,
    isSubmittingTouchpointAnswers: false,
    isUpdatingTouchpointAnswers: false,
    supplementalQuestionAnswers: null,
    touchpointAnswers: null,
    touchpointAnswersById: [],
    previousAnswers: [],
    updatedAnswerIds: [],
    updatedQuestionIds: []
} as {
    improvedStatusCount: number | null;
    improvedStatus: any[];
    isFetchingSupplementalQuestionAnswers: boolean;
    isFetchingTouchpointAnswers: boolean;
    isFetchingTouchpointAnswersById: boolean;
    isSubmittingStatusCapture: boolean;
    isSubmittingTouchpointAnswers: boolean;
    isUpdatingTouchpointAnswers: boolean;
    supplementalQuestionAnswers: SupplementalQuestionAnswers[] | null;
    touchpointAnswers: TouchpointAnswers[] | null;
    touchpointAnswersById: TouchpointAnswers[];
    previousAnswers: TouchpointAnswers[];
    updatedAnswerIds: number[];
    updatedQuestionIds: number[];
};

export default function touchpoint_answer_reducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case CONSTANTS.GET_TOUCHPOINT_ANSWERS:
            return {
                ...state,
                isFetchingTouchpointAnswers: true
            };
        case CONSTANTS.GET_TOUCHPOINT_ANSWERS_BY_ID:
            return {
                ...state,
                isFetchingTouchpointAnswersById: true
            };
        case CONSTANTS.GET_SUPPLEMENTAL_QUESTION_ANSWERS:
            return {
                ...state,
                isFetchingSupplementalQuestionAnswers: true
            };
        case CONSTANTS.SET_SUPPLEMENTAL_QUESTION_ANSWERS:
            return {
                ...state,
                isFetchingSupplementalQuestionAnswers: false,
                supplementalQuestionAnswers: state.supplementalQuestionAnswers
                    ? [...state.supplementalQuestionAnswers, ...action.payload]
                    : action.payload
            };
        case CONSTANTS.SET_TOUCHPOINT_ANSWERS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    isFetchingTouchpointAnswers: false,
                    touchpointAnswers: null
                };
            }

            return {
                ...state,
                isFetchingTouchpointAnswers: false,
                touchpointAnswers: action.payload
            };
        case CONSTANTS.SET_TOUCHPOINT_ANSWERS_BY_ID:
            return {
                ...state,
                isFetchingTouchpointAnswersById: false,
                touchpointAnswersById: action.payload
            };
        case CONSTANTS.UPDATE_SUPPLEMENTAL_QUESTION_ANSWERS:
            const index = state.supplementalQuestionAnswers ? state.supplementalQuestionAnswers.findIndex(
                (answer) =>
                    answer.touchpoint_question_id ===
                    action.payload.touchpoint_question_id
            ) : -1;

            if (index === -1) {
                return {
                    ...state,
                    supplementalQuestionAnswers: [
                        ...(state.supplementalQuestionAnswers || []),
                        action.payload
                    ]
                };
            } else {
                return {
                    ...state,
                    supplementalQuestionAnswers:
                        state.supplementalQuestionAnswers
                            ? state.supplementalQuestionAnswers.map((answer) => {
                                  if (
                                      answer.touchpoint_question_id ===
                                      action.payload.touchpoint_question_id
                                  ) {
                                      return action.payload;
                                  } else {
                                      return answer;
                                  }
                              })
                            : null
                };
            }
        case CONSTANTS.UPDATE_TOUCHPOINT_ANSWERS:
            const { answers } = action.payload;
            const { touchpointAnswers } = state;

            const questionIds = Array.isArray(answers)
                ? answers.map(
                      (answer) => answer.question.quality_of_life_measure_id
                  )
                : [];
            const answerIds = Array.isArray(answers)
                ? answers.map((answer) => answer.id)
                : [];

            const updatedAnswers =
                touchpointAnswers && !questionIds.length
                    ? touchpointAnswers.map((answer) => {
                          if (
                              answers.touchpoint_question_id ===
                                  answer.touchpoint_question_id &&
                              (answer.answer || answer.answers)
                          ) {
                              return {
                                  ...answer,
                                  answers: answer.answers
                                      ? [answers, ...answer.answers]
                                      : [answers, answer.answer],
                                  question_type: 'StatusQuestion'
                              };
                          } else if (
                              answer.touchpoint_question_id ===
                              answers.touchpoint_question_id
                          ) {
                              return {
                                  ...answers,
                                  quality_of_life_measure_id:
                                      answer.quality_of_life_measure_id,
                                  question_type: 'StatusQuestion'
                              };
                          } else {
                              return answer;
                          }
                      })
                    : answers;

            const existingAnswerIndex = updatedAnswers.findIndex(
                (ans: TouchpointAnswers) =>
                    ans.touchpoint_question_id ===
                    answers.touchpoint_question_id
            );

            if (!questionIds.length) {
                if (existingAnswerIndex === -1) {
                    updatedAnswers.push(...answers);
                } else {
                    return {
                        ...state,
                        isUpdatingTouchpointAnswers: false,
                        isSubmittingTouchpointAnswers: false,
                        isSubmittingStatusCapture: false,
                        touchpointAnswers: updatedAnswers,
                        updatedAnswerIds: [
                            ...state.updatedAnswerIds,
                            answers.id
                        ],
                        updatedQuestionIds: [
                            ...state.updatedQuestionIds,
                            touchpointAnswers && touchpointAnswers[existingAnswerIndex]
                                ? touchpointAnswers[existingAnswerIndex].quality_of_life_measure_id
                                : null
                        ],
                        previousAnswers: [
                            ...state.previousAnswers,
                            touchpointAnswers && touchpointAnswers[existingAnswerIndex]?.answers?.[0]
                        ]
                    };
                }
            }

            return {
                ...state,
                isUpdatingTouchpointAnswers: false,
                isSubmittingTouchpointAnswers: false,
                isSubmittingStatusCapture: false,
                touchpointAnswers: updatedAnswers,
                updatedAnswerIds: [...state.updatedAnswerIds, ...answerIds],
                updatedQuestionIds: [
                    ...state.updatedQuestionIds,
                    ...questionIds
                ],
                previousAnswers: [
                    ...state.previousAnswers,
                    ...(touchpointAnswers ? touchpointAnswers.map((answer) =>
                        answer.answers ? answer.answers?.[0] : answer
                    ) : [])
                ]
            };
        case CONSTANTS.SET_TOUCHPOINT_STATUS:
            return {
                ...state,
                isUpdatingTouchpointAnswers: false,
                touchpointAnswersById: action.payload
            };
        case CONSTANTS.SET_IMPROVED_TOUCHPOINT_STATUS:
            const improvedTouchpointAnswer = state.touchpointAnswers ? state.touchpointAnswers.map(
                (answer) => {
                    if (
                        answer.touchpoint_question_id ===
                        action.payload.answer.touchpoint_question_id
                    ) {
                        return {
                            ...answer,
                            answers: answer.answers.map((matchingAnswer) => {
                                if (
                                    matchingAnswer.id ===
                                    action.payload.answer.id
                                ) {
                                    return {
                                        ...matchingAnswer,
                                        question_type: 'StatusQuestion',
                                        classified_statuses:
                                            action.payload.answer
                                                .classified_statuses
                                    };
                                }

                                return matchingAnswer;
                            })
                        };
                    }

                    return answer;
                }
            ) : [];

            const improvedStatusIndex = state.improvedStatus.findIndex(
                (status) =>
                    status.answer.id === action.payload.answer.id &&
                    status.oldAnswer.id === action.payload.oldAnswer.id
            );

            if (improvedStatusIndex !== -1) {
                return {
                    ...state,
                    touchpointAnswers: improvedTouchpointAnswer
                };
            } else {
                return {
                    ...state,
                    improvedStatus: [...state.improvedStatus, action.payload],
                    improvedStatusCount: state.improvedStatusCount
                        ? state.improvedStatusCount + 1
                        : 1,
                    touchpointAnswers: improvedTouchpointAnswer
                };
            }
        case CONSTANTS.SET_REDUCED_TOUCHPOINT_STATUS:
            const reducedTouchpointAnswer = state.touchpointAnswers ? state.touchpointAnswers.map(
                (answer) => {
                    if (
                        answer.touchpoint_question_id ===
                        action.payload.touchpoint_question_id
                    ) {
                        return {
                            ...answer,
                            answers: answer.answers.map((matchingAnswer) => {
                                if (matchingAnswer.id === action.payload.id) {
                                    return {
                                        ...matchingAnswer,
                                        question_type: 'StatusQuestion',
                                        classified_statuses:
                                            action.payload.classified_statuses
                                    };
                                }

                                return matchingAnswer;
                            })
                        };
                    }

                    return answer;
                }
            ) : [];

            return {
                ...state,
                touchpointAnswers: reducedTouchpointAnswer
            };
        case CONSTANTS.INCREMENT_IMPROVED_STATUS_COUNT:
            return {
                ...state,
                improvedStatusCount: state.improvedStatusCount
                    ? state.improvedStatusCount + 1
                    : 1
            };
        case CONSTANTS.DECREMENT_IMPROVED_STATUS_COUNT:
            const removedImprovedStatusAnswerId =
                state.improvedStatus[0]?.answer.id;
            const removedImprovedStatusQuestionId =
                state.improvedStatus[0]?.oldAnswer.quality_of_life_measure_id;

            return {
                ...state,
                improvedStatus: state.improvedStatus.filter(
                    (status) =>
                        status.answer.id !== removedImprovedStatusAnswerId
                ),
                improvedStatusCount: state.improvedStatusCount
                    ? state.improvedStatusCount - 1
                    : 0,
                updatedAnswerIds: state.updatedAnswerIds.filter(
                    (id) => id !== removedImprovedStatusAnswerId
                ),
                updatedQuestionIds: state.updatedQuestionIds.filter(
                    (id) => id !== removedImprovedStatusQuestionId
                )
            };
        case CONSTANTS.SUBMIT_TOUCHPOINT_ANSWERS:
            return {
                ...state,
                isSubmittingTouchpointAnswers: true
            };
        case CONSTANTS.SUBMIT_STATUS_CAPTURE:
            return {
                ...state,
                isSubmittingStatusCapture: true
            };
        case CONSTANTS.RESET_TOUCHPOINT_ANSWERS:
            return initialState;
        case CONSTANTS.RESET:
            return initialState;
        default:
            return state;
    }
}
