import SamaritanFetch from './httpClient';

export const fetchSupplementalQuestionAnswers = (
    questionID: number,
    homelessID: number
): Promise<object> =>
    SamaritanFetch.v3('/touchpoint_answers', {
        query: {
            'touchpoint_answers[touchpoint_question_id]': questionID,
            'touchpoint_answers[homeless_id]': homelessID,
            page: 1,
            per_page: 1
        }
    });

export const fetchTouchpointAnswers = (
    tpQuestionId: string,
    page: number = 1
): Promise<object> =>
    SamaritanFetch.v2('/touchpoint_answers', {
        query: {
            touchpoint_question_id: tpQuestionId,
            page
        }
    });

export const fetchTouchpointAnswers2 = (
    homelessId: number,
    questionId: number,
    all: boolean
): Promise<object> => {
    const query: { [key: string]: string | number } = {
        'touchpoint_answers[homeless_id]': homelessId,
        'touchpoint_answers[touchpoint_question_id][]': questionId
    };

    if (all) {
        return SamaritanFetch.v3(`/touchpoint_answers`, { query });
    } else {
        query.page = 1;
        query.per_page = 1;
        return SamaritanFetch.v3(`/touchpoint_answers`, { query });
    }
};

// fix any
export const submitTouchpointAnswer = (
    touchpointAnswer: any
): Promise<object> => {
    return SamaritanFetch.v3.post(`/touchpoint_answers`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ touchpoint_answer: touchpointAnswer })
    });
};

export const submitStatusCapture = (
    homelessId: number,
    statusCapture: Array<object>
): Promise<object> => {
    return SamaritanFetch.v3.post(`/touchpoints`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            description: 'Status Capture Update',
            homeless_id: homelessId,
            touchpoint_answers_attributes: statusCapture
        })
    });
};

export const submitTouchpointUpdateContext = (
    touchpointAnswerId: number | string,
    context: string
): Promise<object> => {
    return SamaritanFetch.v3.put(`/touchpoint_answers/${touchpointAnswerId}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ touchpoint_answer: { context: context } })
    });
};
