import { CircularProgress } from '@mui/material';

type Props = {
    children?: React.ReactNode;
    style?: React.CSSProperties;
};

export const LoadingCircle: React.FC<Props> = ({ children, ...props }) => {
    return (
        <div
            {...props}
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...(props ? props.style : {})
            }}
        >
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                {children && <div>{children}</div>}
            </div>
        </div>
    );
};

export default LoadingCircle;
