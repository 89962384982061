import AddButton from '../../CaseManagement/components/AddButton';
import Card from '../../CaseManagement/components/Card';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        enrollmentStatusContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px'
        },
        dateContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
        },
        inputTitle: {
            fontSize: '14px',
            fontWeight: 700,
            letterSpacing: '0.15em',
            color: '#1F1F51',
            opacity: '50%',
            textTransform: 'uppercase'
        },
        date: {
            fontSize: '32px',
            fontWeight: 700,
            color: '#1F1F51'
        }
    })
);

type Props = {
    exited: boolean;
    holder: {
        enrolled_on: string;
        exited_internal_context: string;
        name: string;
        unenrolled_on?: string;
    };
    setReenroll: (value: boolean) => void;
    setUnenroll: (value: boolean) => void;
};

const Enrollment: React.FC<Props> = ({
    exited,
    holder,
    setReenroll,
    setUnenroll
}) => {
    const classes = useStyles();

    const { enrolled_on, exited_internal_context, unenrolled_on } = holder;

    const calculateMonthDifference = (enrollDate: string) => {
        const date = new Date(enrollDate).getTime();
        const now = new Date().getTime();
        const diff = now - date;
        const diffInDays = Math.round(diff / (1000 * 60 * 60 * 24));
        const diffInMonths = Math.round(diff / (1000 * 60 * 60 * 24 * 30.44));

        if (diffInDays === 1) {
            return `(${diffInDays} day)`;
        }

        if (diffInDays < 30) {
            return `(${diffInDays} days)`;
        }

        if (diffInMonths === 1) {
            return `(${diffInMonths} month)`;
        } else {
            return `(${diffInMonths} months)`;
        }
    };

    const formatEnrolledOn = (enrollDate: string) => {
        const date = new Date(enrollDate);
        const options = {
            year: '2-digit',
            month: 'short',
            day: '2-digit'
        } as const;

        return date.toLocaleDateString('en-US', options);
    };

    const formatExpirationDate = (enrollDate: string) => {
        const date = new Date(enrollDate);
        const targetDate = new Date(date);
        targetDate.setDate(targetDate.getDate() + 365);

        const difference = targetDate.getTime() - new Date().getTime();

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const months = Math.floor(days / 30.44);

        if (months === 0) {
            return `${days} days`;
        } else if (months === 1) {
            return `${months} month, ${Math.floor(days % 30.44)} days`;
        } else if (months < 0) {
            return `${months * -1 - 1} months, ${
                Math.floor(days % 30.44) * -1
            } days ago`;
        } else {
            return `${months} months, ${Math.floor(days % 30.44)} days`;
        }
    };

    return (
        <div className={classes.enrollmentStatusContainer}>
            <Card info enrollment exited name={holder.name} />
            <Card
                id="enrollment-info"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '40px'
                }}
            >
                {!exited && (
                    <div className={classes.dateContainer} id="registered">
                        <span className={classes.inputTitle}>Member Since</span>
                        <span className={classes.date}>
                            {`${formatEnrolledOn(
                                enrolled_on
                            )} ${calculateMonthDifference(enrolled_on)}`}
                        </span>
                    </div>
                )}
                <div className={classes.dateContainer} id="expiring">
                    <span className={classes.inputTitle}>
                        {!exited ? `Membership Expiring In` : `Unenrolled On`}
                    </span>
                    <span className={classes.date}>
                        {!exited ? (
                            formatExpirationDate(enrolled_on)
                        ) : (
                            <span>{`${formatEnrolledOn(
                                unenrolled_on ?? ''
                            )} ${calculateMonthDifference(
                                unenrolled_on ?? ''
                            )}`}</span>
                        )}
                    </span>
                </div>
                {exited && (
                    <div className={classes.dateContainer} id="context">
                        <span className={classes.inputTitle}>
                            Additional Context
                        </span>
                        <span className={classes.date}>
                            {exited_internal_context ?? '-'}
                        </span>
                    </div>
                )}
            </Card>
            <AddButton
                action={!exited ? setUnenroll : setReenroll}
                type={!exited ? 'unenroll' : 'reenroll'}
            />
        </div>
    );
};

export default Enrollment;
