import { useCallback, useState } from 'react';
import { updateHomelessPrivateInfo } from '../api/homeless';
import { useHistory } from 'react-router-dom';

const useUpdatePrivateProfile = () => {
    const history = useHistory();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);

    const updatePrivateProfile = useCallback(
        (memberId: number, data: any) => {
            setIsLoading(true);
            setIsError(false);
            updateHomelessPrivateInfo(memberId, data)
                .then(
                    (res: {
                        errors: { attribute: string; description: string }[];
                    }) => {
                        if (res.errors) {
                            const { attribute, description } = res.errors[0];
                            setIsSuccess(false);
                            setIsError(true);
                            setIsLoading(false);
                            setError(
                                `${attribute ? attribute : ''} ${description}`
                            );
                        } else {
                            setIsSuccess(true);
                            setIsError(false);
                            setIsLoading(false);
                            setError(null);

                            history.push({ state: { from: 'account' } });
                        }
                    }
                )
                .catch((err: any) => {
                    setIsSuccess(false);
                    setIsError(true);
                    setIsLoading(false);
                    setError(err);
                });
        },
        [history]
    );

    const reset = useCallback(() => {
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
        setError(null);
    }, []);

    return {
        updatePrivateProfile,
        isSuccess,
        isError,
        isLoading,
        error,
        reset
    };
};

export default useUpdatePrivateProfile;
