// TODO: Fix ts-nocheck PublicInfo
// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import BasicInfo from './components/BasicInfo';
import LifeStory from './LifeStory';
import LoadingCircle from '../../../_shared/LoadingCircle';
import Needs from './Needs';
import SupplementalQuestions from './SupplementalQuestions';

import { isObjectEmpty } from '../../../../util';
import RequestErrorModal from '../../../_shared/RequestErrorModal';
import useUpdatePublicProfile from '../../../../hooks/useUpdatePublicProfile';

import { createStyles, makeStyles } from '@mui/styles';
import { getHomelessInfo } from '../../../../redux/actions/homeless';

const useStyles = makeStyles(() =>
    createStyles({
        publicInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '60px',
            padding: '30px 12% 60px 12%'
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
        }
    })
);

type Props = {
    holder: {
        description: string;
        id: number;
        name: string;
        photo: string;
    };
    lifeStory: string;
    needs: any[];
    newHomelessNeeds: any[];
    supplementalQuestions: any[];
    supplementalQuestionAnswers: [
        { answer: string; created_at: string; touchpoint_question_id: number }
    ];
    touchpointAnswers: any[];
    touchpointQuestions: any[];
    touchpointQuestionQOLs: any[];
    updateHomelessLifeStory: (id: number, description: string) => void;
    updateProfileCompletedItems: (item: string) => void;
    user: any;
};

const PublicInfo: React.FC<Props> = ({
    holder,
    lifeStory,
    needs,
    newHomelessNeeds,
    supplementalQuestions,
    supplementalQuestionAnswers,
    touchpointAnswers,
    touchpointQuestions,
    touchpointQuestionQOLs,
    updateHomelessLifeStory,
    updateProfileCompletedItems,
    user
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { description, id, name, photo } = holder;
    const { updatePublicProfile, isError, isLoading, isSuccess, reset } =
        useUpdatePublicProfile();

    const [editName, setEditName] = useState(false);
    const [newName, setNewName] = useState(name);
    const [editProfilePicture, setEditProfilePicture] = useState(false);
    const [newProfilePicture, setNewProfilePicture] = useState(null);

    const handleSave = useCallback(() => {
        const changedState = {};
        const _formData = new FormData();

        if (newName !== name && newName.length > 0) {
            changedState['name'] = newName;
            updateProfileCompletedItems('name');
        }

        if (newProfilePicture) {
            changedState['photo'] = newProfilePicture;
            updateProfileCompletedItems('photo');
        }

        if (!isObjectEmpty(changedState)) {
            for (const [key, value] of Object.entries(changedState)) {
                _formData.append(`samaritan_member[${key}]`, value);
            }

            updatePublicProfile(id, _formData);
        } else {
            setEditName(false);
            setEditProfilePicture(false);
        }
    }, [
        id,
        name,
        newName,
        newProfilePicture,
        updateProfileCompletedItems,
        updatePublicProfile
    ]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(getHomelessInfo('', '', '', id));
        }
    }, [dispatch, id, isSuccess, newProfilePicture]);

    const basicInfoProps = {
        name,
        editName,
        setEditName,
        newName,
        setNewName,
        handleSave,
        photo,
        editProfilePicture,
        setEditProfilePicture,
        newProfilePicture,
        setNewProfilePicture
    };

    if (isLoading) {
        return (
            <div className={classes.publicInfoContainer}>
                <div style={{ width: '100%', height: '100px' }}>
                    <LoadingCircle>Saving Changes</LoadingCircle>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.publicInfoContainer}>
            {isError && (
                <RequestErrorModal
                    open={isError}
                    onClose={() => reset()}
                    message="Changes couldn't be saved."
                    retryFunc={handleSave}
                />
            )}
            <BasicInfo {...basicInfoProps} />
            <Needs
                holder={holder}
                needs={needs}
                newHomelessNeeds={newHomelessNeeds}
                touchpointAnswers={touchpointAnswers}
                touchpointQuestions={touchpointQuestions}
                touchpointQuestionQOLs={touchpointQuestionQOLs}
                updateProfileCompletedItems={updateProfileCompletedItems}
                user={user}
            />
            <LifeStory
                description={description}
                id={id}
                lifeStory={lifeStory}
                name={name}
                updateHomelessLifeStory={updateHomelessLifeStory}
            />
            {supplementalQuestions.length > 0 && (
                <SupplementalQuestions
                    answers={supplementalQuestionAnswers}
                    id={id}
                    name={name}
                    questions={supplementalQuestions}
                    updateProfileCompletedItems={updateProfileCompletedItems}
                />
            )}
        </div>
    );
};

export default PublicInfo;
