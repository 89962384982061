import CONSTANTS from '../constants/constants';

import { DeploymentUsers } from 'types';

import * as api from 'api/deployments';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

function fetchDeploymentOrgsAction() {
    return {
        type: CONSTANTS.GET_DEPLOYMENT_ORGS
    };
}

function fetchDeploymentUsersAction() {
    return {
        type: CONSTANTS.GET_DEPLOYMENT_USERS
    };
}

function referMemberAction() {
    return {
        type: CONSTANTS.REFER_MEMBER
    };
}

function referMemberFailAction() {
    return {
        type: CONSTANTS.REFER_MEMBER_FAIL
    };
}

function referMemberSuccessAction() {
    return {
        type: CONSTANTS.REFER_MEMBER_SUCCESS
    };
}

export function setDeploymentOrgs(response: object) {
    return {
        type: CONSTANTS.SET_DEPLOYMENT_ORGS,
        payload: response
    };
}

function setDeploymentUsers(response: object) {
    return {
        type: CONSTANTS.SET_DEPLOYMENT_USERS,
        payload: response
    };
}

export function fetchDeploymentOrgsRequest(id: number) {
    return (dispatch: Function) => {
        dispatch(fetchDeploymentOrgsAction());
        return api
            .fetchDeployment(id)
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then(
                (responseJson: {
                    deployment: { relations: { organizations: object[] } };
                }) => {
                    if (
                        responseJson.deployment &&
                        responseJson.deployment.relations
                    ) {
                        return dispatch(
                            setDeploymentOrgs(
                                responseJson.deployment.relations.organizations
                            )
                        );
                    } else {
                        return dispatch(setDeploymentOrgs([]));
                    }
                }
            )
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function fetchDeploymentUsersRequest() {
    return (dispatch: Function) => {
        dispatch(fetchDeploymentUsersAction());
        return api
            .fetchDeploymentUsers()
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { users: DeploymentUsers[] }) => {
                if (responseJson.users) {
                    return dispatch(setDeploymentUsers(responseJson.users));
                } else {
                    return dispatch(setDeploymentUsers([]));
                }
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function referMemberRequest({
    homeless_id,
    organization_id,
    referee_id
}: {
    homeless_id: number;
    organization_id: number;
    referee_id: number;
}) {
    return (dispatch: Function) => {
        dispatch(referMemberAction());
        return api
            .referMember({ homeless_id, organization_id, referee_id })
            .then((response: any) => {
                if (response && response.ok) {
                    dispatch(referMemberSuccessAction());
                } else {
                    dispatch(referMemberFailAction());
                }
            })
            .catch((_error: any) => {
                handleSamaritanApiError(
                    { message: 'Failed to refer member' },
                    dispatch
                );
            });
    };
}