import { connect } from 'react-redux';
import actions from '../redux/actions';
import SDOHSurvey from '../components/holder_profile/profile/SDOH_3';

function mapStateToProps(state: any) {
    const { homelessInfo, isFetchingHomelessInfo, notFoundHomelessInfo } =
        state.homeless;
    const {
        touchpointQuestions,
        touchpointQuestionIds,
        touchpointQuestionQOLs,
        isFetchingTouchpointQuestions
    } = state.touchpointQuestions;
    const {
        touchpointAnswers,
        touchpointAnswersById,
        isFetchingTouchpointAnswers,
        isFetchingTouchpointAnswersById,
        isSubmittingTouchpointAnswers,
        isSubmittingStatusCapture
    } = state.touchpointAnswers;
    const { sdohStatus } = state.unenrollment;

    return {
        homelessInfo,
        isFetchingHomelessInfo,
        notFoundHomelessInfo,
        touchpointQuestions,
        touchpointQuestionIds,
        touchpointQuestionQOLs,
        isFetchingTouchpointQuestions,
        touchpointAnswers,
        touchpointAnswersById,
        isFetchingTouchpointAnswers,
        isFetchingTouchpointAnswersById,
        isSubmittingTouchpointAnswers,
        isSubmittingStatusCapture,
        sdohStatus
    };
}

const mapDispatchToProps = {
    getTouchpointAnswersRequest: actions.getTouchpointAnswersRequest2,
    setSdohStatus: actions.setSdohStatus,
    submitTouchpointAnswer: actions.submitTouchpointAnswer,
    submitStatusCapture: actions.submitStatusCaptureRequest,
    updateGoalActionStatus: actions.updateGoalActionStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(SDOHSurvey);
