import CONSTANTS from "../constants/constants";

import { handleSamaritanApiError, getApiError } from './error';
import { getHomelesses, getCaseloads } from './homelesses';
import { handleSamaritanApiResponse } from './index';
import { incrementEnrolledMembers } from './user';

import { submitTouchpointAnswers } from '../../api/touchpoint';
import { submitIntake as submitIntakeToApi } from '../../api/intake';

export function resetIntake() {
    return {
        type: CONSTANTS.RESET_INTAKE
    };
}

export function decrementIntakeStep(step) {
    return {
        type: CONSTANTS.DECREMENT_INTAKE_STEP,
        payload: step ? step : null
    };
}

export function incrementIntakeStep(progressWidth) {
    return {
        type: CONSTANTS.INCREMENT_INTAKE_STEP,
        payload: progressWidth
    };
}

export function setIntakeDeploymentId(deploymentId) {
    return {
        type: CONSTANTS.SET_INTAKE_DEPLOYMENT_ID,
        payload: deploymentId
    };
}

export function setIntakeProfilePicture(profilePic) {
    return {
        type: CONSTANTS.SET_INTAKE_PROFILE_PICTURE,
        payload: profilePic
    };
}

// remove
export function setIntakePictureToggle(showPic) {
    return {
        type: CONSTANTS.SET_INTAKE_PICTURE_TOGGLE,
        payload: showPic
    };
}

export function setIntakeProfileName(profileName) {
    return {
        type: CONSTANTS.SET_INTAKE_PROFILE_NAME,
        payload: profileName
    };
}

export function setIntakeGoals(goals) {
    return {
        type: CONSTANTS.SET_INTAKE_GOALS,
        payload: goals
    };
}

export function setIntakeNeeds(needs) {
    return {
        type: CONSTANTS.SET_INTAKE_NEEDS,
        payload: needs
    };
}

// remove
export function setIntakeLifeStory(lifeStory) {
    return {
        type: CONSTANTS.SET_INTAKE_LIFESTORY,
        payload: lifeStory
    };
}

// remove
export function setIntakeSkills(skills) {
    return {
        type: CONSTANTS.SET_INTAKE_SKILLS,
        payload: skills
    };
}

// remove
export function removeIntakeSkill(skill) {
    return {
        type: CONSTANTS.REMOVE_INTAKE_SKILL,
        payload: skill
    };
}

export function setIntakeBirthDay(birthday) {
    return {
        type: CONSTANTS.SET_INTAKE_BIRTH_DAY,
        payload: birthday
    };
}

// remove
export function setIntakeBirthMonth(month) {
    return {
        type: CONSTANTS.SET_INTAKE_BIRTH_MONTH,
        payload: month
    };
}

export function setIntakeEmail(email) {
    return {
        type: CONSTANTS.SET_INTAKE_EMAIL,
        payload: email
    };
}

export function setIntakePhoneNo(phoneNo) {
    return {
        type: CONSTANTS.SET_INTAKE_PHONE_NUMBER,
        payload: phoneNo
    };
}

export function setIntakeAnswer(questionId, answer, color) {
    return {
        type: CONSTANTS.SET_INTAKE_QUESTION_ANSWER,
        payload: { questionId, answer, color }
    };
}

export function setIntakeBeaconId(beaconId) {
    return {
        type: CONSTANTS.SET_INTAKE_BEACON_ID,
        payload: beaconId
    };
}

export function toggleIntakeAgreed() {
    return {
        type: CONSTANTS.TOGGLE_INTAKE_AGREED
    };
}

function setIntakeError() {
    return {
        type: CONSTANTS.SET_INTAKE_ERROR
    };
}

function getIntakeSubmit() {
    return {
        type: CONSTANTS.GET_INTAKE_SUBMIT
    };
}

function setIntakeSubmit(id, beaconId) {
    return {
        type: CONSTANTS.SET_INTAKE_SUBMIT,
        payload: { id, beaconId }
    };
}

export function submitIntake(token, data) {
    return (dispatch, getState) => {
        dispatch(getIntakeSubmit());

        const beaconId = getState().intake.intakeBeaconId;

        return submitIntakeToApi(data, beaconId)
            .then((response) => {
                return handleSamaritanApiResponse(response, dispatch); // this function doesn't handle 403's because we have to throw an error inside of a response Promise
            })
            .then((responseJson) => {
                if (Object.keys(responseJson).includes('errors')) {
                    dispatch(getApiError(responseJson.errors));
                    dispatch(setIntakeError());
                } else if (
                    Object.keys(responseJson).includes('id') &&
                    Object.keys(responseJson).includes('beacon_id')
                ) {
                    dispatch(
                        setIntakeSubmit(responseJson.id, responseJson.beacon_id)
                    );
                    dispatch(getCaseloads());
                    dispatch(incrementEnrolledMembers());
                    dispatch(getHomelesses());
                } else {
                    throw new Error('no errors or beacon_id in response');
                }

                return responseJson;
            })
            .catch((error) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

function setIntakeTouchpointError() {
  return {
    type: CONSTANTS.SET_INTAKE_TOUCHPOINT_ERROR,
  };
}

function getIntakeTouchpointSubmit() {
  return {
    type: CONSTANTS.GET_INTAKE_TOUCHPOINT_SUBMIT,
  };
}

function setIntakeTouchpointSubmit() {
  return {
    type: CONSTANTS.SET_INTAKE_TOUCHPOINT_SUBMIT,
  };
}

export function submitIntakeTouchpoint(token, data) {
  return (dispatch) => {
    dispatch(getIntakeTouchpointSubmit());
    return submitTouchpointAnswers(data)
      .then((response) => {
        return handleSamaritanApiResponse(response, dispatch); // this function doesn't handle 403's because we have to throw an error inside of a response Promise
      })
      .then((responseJson) => {
        if (Object.keys(responseJson).includes("errors")) {
          dispatch(getApiError(responseJson.errors));
          dispatch(setIntakeTouchpointError());
        } else if (Object.keys(responseJson.touchpoint).includes("id")) {
          dispatch(setIntakeTouchpointSubmit());
        } else {
          throw new Error("no errors in unsuccessful touchpoint response");
        }

        return responseJson;
      })
      .catch((error) => {
        handleSamaritanApiError(error, dispatch);
      });
  };
}

export function setIntakeIcebreaker(icebreaker) {
    return {
        type: CONSTANTS.SET_INTAKE_ICEBREAKER,
        payload: icebreaker
    };
}

// remove
export function setIntakeQr(qr) {
    return {
        type: CONSTANTS.SET_INTAKE_QR,
        payload: qr
    };
}

export function setIntakeRelevantId(relevantId) {
    return {
        type: CONSTANTS.SET_INTAKE_RELEVANT_ID,
        payload: relevantId
    };
}

export function setIntakeInterviewer(interviewer) {
    return {
        type: CONSTANTS.SET_INTAKE_INTERVIEWER,
        payload: interviewer
    };
}

export function setIntakeInterviewerId(interviewerId) {
    return {
        type: CONSTANTS.SET_INTAKE_INTERVIEWER_ID,
        payload: interviewerId
    };
}

export function setIntakePrivateFirstName(privateFirstName) {
  return {
    type: CONSTANTS.SET_INTAKE_PRIVATE_FIRST_NAME,
    payload: privateFirstName,
  };
}

export function setIntakePrivateMiddleName(privateMiddleName) {
  return {
    type: CONSTANTS.SET_INTAKE_PRIVATE_MIDDLE_NAME,
    payload: privateMiddleName,
  };
}

export function setIntakePrivateLastName(privateLastName) {
  return {
    type: CONSTANTS.SET_INTAKE_PRIVATE_LAST_NAME,
    payload: privateLastName,
  };
}

export function setIntakePrivateFullName(privateFullName) {
  return {
    type: CONSTANTS.SET_INTAKE_PRIVATE_FULL_NAME,
    payload: privateFullName,
  };
}

export function setIntakeOrgId(orgId) {
  return {
    type: CONSTANTS.SET_INTAKE_ORG_ID,
    payload: orgId,
  };
}

export function setIntakeTouchpointAnswer(questionId, answer) {
    return {
        type: CONSTANTS.SET_INTAKE_TOUCHPOINT_ANSWER,
        payload: { questionId, answer }
    };
}

export function setViewGuideMessage(messageType) {
    return {
        type: CONSTANTS.SET_VIEW_GUIDE_MESSAGE,
        payload: messageType
    };
}