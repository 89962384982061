import { ChangeEvent, Fragment, Key, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CardBackground from 'assets/background-card.png';
import MessageBubble from 'components/_shared/MessageBubble';

import { formatDate } from '../../CaseManagement/SDOHStatus/utils';

import { submitTouchpointAnswer } from 'redux/actions/touchpointAnswers';

import Button from '@mui/material/Button';
import TextAreaAutosize from '@mui/material/TextareaAutosize';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        statusContainer: {
            background: '#2F365B',
            position: 'relative',
            width: '100%'
        },
        statusTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 600,
            color: '#FFFFFF',
            lineHeight: '26px',
            paddingTop: '30px',
            marginBottom: '30px',
            position: 'relative'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '80px 0 40px 0'
        },
        cancelButton: {
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: 600,
            color: '#7378E8',
            borderRadius: '36px',
            padding: '10px 30px',
            marginBottom: '10px'
        },
        saveButton: {
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: 700,
            color: '#FBFBFF',
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '18px',
            padding: '16px 100px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.08)',
            marginBottom: '10px',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #918ED7 100%)'
            }
        },
        saveButtonDisabled: {
            opacity: '0.3',
            color: '#FFFFFF !important'
        },
        suggestionContainer: {
            display: 'flex',
            flexDirection: 'column',
            background: '#FFFFFF',
            borderRadius: '20px',
            padding: '20px 30px',
            width: '75%',
            margin: '0 auto 60px auto',
            boxShadow: '0px 10px 80px rgba(0, 0, 0, 0.1)',
            position: 'relative'
        },
        suggestionTitle: {
            fontSize: '14px',
            fontWeight: 800,
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
            background: '#8882D8',
            borderRadius: '32px',
            padding: '5px 20px',
            color: '#FFFFFF',
            position: 'relative',
            top: '0.5em',
            left: '10%',
            zIndex: 2
        },
        suggestionList: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '10px',
            gap: '10px'
        },
        suggestion: {
            background: '#EAEBFB',
            color: '#1F1F51',
            width: 'fit-content',
            padding: '10px 20px',
            borderRadius: '32px',
            fontSize: '14px',
            letterSpacing: '0.01em',
            opacity: '0.7',
            cursor: 'pointer',
            textTransform: 'none'
        },
        customContainer: {},
        customInputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        customButton: {
            fontSize: '16px',
            fontWeight: 300,
            color: '#A8A8BF',
            letterSpacing: '0.01em',
            opacity: '0.7',
            marginTop: '10px',
            borderRadius: '40px',
            border: '2px dashed #EAEBFB',
            textTransform: 'none',
            padding: '5px 20px',
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        customInput: {
            fontFamily: 'Manrope',
            fontSize: '30px',
            fontWeight: 600,
            lineHeight: '45px',
            color: '#1F1F51',
            marginTop: '10px',
            padding: '12px 10px',
            border: 'none',
            outline: 'none',
            resize: 'none',
            textDecoration: 'underline',
            textDecorationColor: '#E3E4FA',
            '&::placeholder': {
                color: '#A8A8BF',
                opacity: '0.7',
                fontWeight: '300',
                letterSpacing: '0.02em',
                fontStyle: 'italic',
                textDecoration: 'underline',
                textDecorationColor: '#E3E4FA'
            }
        },
        historyContainer: {
            margin: '30px 0'
        },
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '16px',
            fontWeight: 300,
            color: '#1F1F51'
        },
        date: {
            fontSize: '12px',
            color: '#A6A6BF'
        },
        answerList: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            margin: '15px 0'
        },
        answer: {
            background: 'rgba(234, 235, 251, 0.3)',
            borderRadius: '32px',
            padding: '10px 20px',
            fontSize: '14px',
            letterSpacing: '0.01em',
            color: '#1F1F51',
            opacity: '0.7'
        },
        cardBackground: {
            background: '#EAEFF3',
            position: 'absolute',
            bottom: '0',
            height: '85%',
            borderRadius: '40px 40px 10px 10px'
        },
        backgroundImage: {
            height: 'auto',
            width: '100%',
            bottom: '0',
            opacity: '0.6',
            borderRadius: '40px 40px 0 0'
        },
        doneButton: {
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 800,
            border: '3px solid #E3E4FA',
            borderRadius: '36px',
            padding: '4px 28px 4px 14px',
            color: '#1F1F51'
        },
        checkButton: {
            color: '#FFFFFF',
            background:
                'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3',
            borderRadius: '36px',
            padding: '22px',
            fontSize: '28px',
            position: 'absolute',
            right: '-20px',
            boxShadow: '-2.44929e-16px 4px 20px rgba(0, 0, 0, 0.121569)',
            '&:hover': {
                background:
                    'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3'
            }
        }
    })
);

type Props = {
    action: boolean;
    answers: any[];
    homelessID: number;
    name: string;
    setUpdateStatus: any;
    questions: any[];
};

type InputRefs = {
    [key: number]: HTMLTextAreaElement | HTMLInputElement | null;
};

const UpdateStatusWrapper: React.FC<Props> = ({
    action,
    answers,
    homelessID,
    name,
    setUpdateStatus,
    questions
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const inputRefs = useRef<InputRefs>({});

    const [selectedAnswers, setSelectedAnswers] = useState<{
        [key: number]: string[];
    }>({});
    const [showCustomInput, setShowCustomInput] = useState<{
        [key: number]: boolean;
    }>({});
    const [tempCustomAnswers, setTempCustomAnswers] = useState<{
        [key: number]: string;
    }>({});

    const statusAnswers = answers.filter(
        (answer) => answer.answer || answer.answers
    );

    const focusInput = (questionID: number) => {
        if (inputRefs.current[questionID]) {
            inputRefs.current[questionID]?.focus();
        }
    };

    const handleCustomInputChange = (
        e: ChangeEvent<HTMLTextAreaElement>,
        questionID: number
    ) => {
        setTempCustomAnswers((prev) => ({
            ...prev,
            [questionID]: e.target.value
        }));
    };

    const renderBody = () => {
        if (!questions) return;

        return questions.map((question, index) => {
            const suggestionList = question.suggestions.split(', ');

            return (
                <Fragment key={index}>
                    <div className={classes.statusTitle}>
                        {questions.length === 1 ? question.friendly_name : ''}
                    </div>
                    <MessageBubble
                        name={name}
                        question={question.question}
                        questionLength={questions.length}
                        questionNum={index}
                    />
                    <span className={classes.suggestionTitle}>
                        Click All That Apply
                    </span>
                    <div className={classes.suggestionContainer}>
                        <div className={classes.suggestionList}>
                            {suggestionList.map(
                                (suggestion: string, index: Key) => (
                                    <Button
                                        className={classes.suggestion}
                                        key={index}
                                        onClick={() =>
                                            selectAnswer(
                                                suggestion,
                                                question.id
                                            )
                                        }
                                        style={renderSelectedAnswerStyle(
                                            suggestion,
                                            question.id
                                        )}
                                    >
                                        {suggestion}
                                    </Button>
                                )
                            )}
                            {selectedAnswers[question.id]?.map(
                                (customAnswer: string, customIndex: Key) => {
                                    if (
                                        !suggestionList.includes(customAnswer)
                                    ) {
                                        return (
                                            <Button
                                                className={classes.suggestion}
                                                key={customIndex}
                                                onClick={() =>
                                                    selectAnswer(
                                                        customAnswer,
                                                        question.id
                                                    )
                                                }
                                                style={renderSelectedAnswerStyle(
                                                    customAnswer,
                                                    question.id
                                                )}
                                            >
                                                {customAnswer}
                                            </Button>
                                        );
                                    }

                                    return null;
                                }
                            )}
                        </div>
                        <div className={classes.customContainer}>
                            {!showCustomInput[question.id] ? (
                                <Button
                                    className={classes.customButton}
                                    onClick={() => {
                                        setShowCustomInput((prev) => ({
                                            ...prev,
                                            [question.id]: true
                                        }));

                                        setTimeout(() => {
                                            focusInput(question.id);
                                        }, 100);
                                    }}
                                >
                                    Write your own...
                                </Button>
                            ) : (
                                showCustomInput[question.id] && (
                                    <div
                                        className={classes.customInputContainer}
                                    >
                                        <TextAreaAutosize
                                            className={classes.customInput}
                                            onChange={(e) =>
                                                handleCustomInputChange(
                                                    e,
                                                    question.id
                                                )
                                            }
                                            placeholder={'Write your own...'}
                                            ref={(el: HTMLTextAreaElement) =>
                                                (inputRefs.current[
                                                    question.id
                                                ] = el)
                                            }
                                            spellCheck={'false'}
                                            value={
                                                tempCustomAnswers[question.id]
                                                    ? tempCustomAnswers[
                                                          question.id
                                                      ]
                                                    : ''
                                            }
                                        />
                                        <div
                                            className={classes.doneButton}
                                            onClick={() =>
                                                saveCustomInput(question.id)
                                            }
                                        >
                                            Done
                                        </div>
                                        <Button
                                            className={classes.checkButton}
                                            onClick={() =>
                                                saveCustomInput(question.id)
                                            }
                                        >
                                            <i className="fas fa-check" />
                                        </Button>
                                    </div>
                                )
                            )}
                        </div>
                        {renderLastAnswered(index)}
                    </div>
                </Fragment>
            );
        });
    };

    const renderLastAnswered = (questionIndex: number) => {
        if (!statusAnswers) return;

        const statusAnswer = statusAnswers[questionIndex];

        const { answer, created_at } = statusAnswer.answer
            ? statusAnswer
            : statusAnswer.answers[0];
        const answerArr = answer.split(', ');

        return (
            <div className={classes.historyContainer}>
                <div className={classes.dateContainer}>
                    <span>Last Answered:</span>
                    <span className={classes.date}>
                        {formatDate(created_at)}
                    </span>
                </div>
                <div className={classes.answerList}>
                    {answerArr.map((answer: string, index: Key) => (
                        <span key={index} className={classes.answer}>
                            {answer}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    const renderSelectedAnswerStyle = (answer: any, questionID: number) => {
        if (
            selectedAnswers[questionID] &&
            selectedAnswers[questionID].includes(answer)
        ) {
            return {
                background: '#9DA2F7',
                color: '#FFFFFF',
                opacity: '1'
            };
        }
    };

    const saveCustomInput = (questionID: number) => {
        const trimmedInput = tempCustomAnswers[questionID]?.trim();

        if (!trimmedInput) {
            setShowCustomInput((prev) => ({
                ...prev,
                [questionID]: false
            }));
        } else {
            setSelectedAnswers((prev) => ({
                ...prev,
                [questionID]: [...(prev[questionID] || []), trimmedInput]
            }));
        }

        setTempCustomAnswers((prev) => ({
            ...prev,
            [questionID]: ''
        }));
    };

    const saveStatus = () => {
        const answersToSubmit = questions.map((question) => ({
            touchpoint_question_id: question.id,
            quality_of_life_measure_id: question.quality_of_life_measure_id,
            answer: selectedAnswers[question.id]?.join(', ') || '',
            homeless_id: homelessID
        }));

        answersToSubmit.forEach((answer) => {
            dispatch(submitTouchpointAnswer(answer));
        });

        setSelectedAnswers({});
        setUpdateStatus(false);

        if (action) {
            history.push({ state: { from: 'case' } });
        } else {
            history.push({ state: { from: 'public' } });
        }
    };

    const selectAnswer = (answer: any, questionID: number) => {
        setSelectedAnswers((prevState) => {
            const updatedAnswers = { ...prevState };
            const questionAnswers = updatedAnswers[questionID] || [];

            const answerIndex = questionAnswers.indexOf(answer);

            if (answerIndex !== -1) {
                questionAnswers.splice(answerIndex, 1);
            } else {
                questionAnswers.push(answer);
            }

            if (questionAnswers.length === 0) {
                delete updatedAnswers[questionID];
            } else {
                updatedAnswers[questionID] = questionAnswers;
            }

            return { ...updatedAnswers };
        });
    };

    return (
        <div className={classes.statusContainer}>
            <div className={classes.cardBackground}>
                <img
                    className={classes.backgroundImage}
                    src={CardBackground}
                    alt={'card background'}
                />
            </div>
            {renderBody()}
            <div className={classes.buttonContainer}>
                <Button
                    className={
                        Object.keys(selectedAnswers).length > 0
                            ? classes.saveButton
                            : `${classes.saveButtonDisabled} ${classes.saveButton}`
                    }
                    disabled={Object.keys(selectedAnswers).length === 0}
                    onClick={() => saveStatus()}
                >
                    Save Status
                </Button>
            </div>
        </div>
    );
};

export default UpdateStatusWrapper;
