import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Add from '../../CaseManagement/components/Add';
import AddButton from '../../CaseManagement/components/AddButton';
import Card from '../../CaseManagement/components/Card';
import LoadingCard from '../../CaseManagement/components/LoadingCard';
import NeedCard from './components/NeedCard';
import PopUp from '../../PopUp';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    needsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    }
}));

const Needs = ({
    holder,
    needs,
    isEditingNeed,
    newHomelessNeeds,
    touchpointAnswers,
    touchpointQuestions,
    touchpointQuestionQOLs,
    updateProfileCompletedItems,
    user
}) => {
    const classes = useStyles();
    const needContainerRef = useRef(null);

    const [add, setAdd] = useState(false);
    const [completeNeed, setCompleteNeed] = useState(false);
    const [customGoal, setCustomGoal] = useState(null);
    const [dateISO, setDateISO] = useState('');
    const [edit, setEdit] = useState(false);
    const [editNeed, setEditNeed] = useState(null);
    const [hasQOLMeasures, setHasQOLMeasures] = useState(false);
    const [needID, setNeedID] = useState(null);
    const [qolIDs, setQolIDs] = useState([]);
    const [savedQOLs, setSavedQOLs] = useState([]);
    const [updatedNeedIDs, setUpdatedNeedIDs] = useState([]);
    const [updateNeed, setUpdateNeed] = useState(null);
    const [updateSDOHStatus, setUpdateSDOHStatus] = useState(false);

    const pendingNeeds = needs.filter(
        (need) => need.status === 'pending' || updatedNeedIDs.includes(need.id)
    );

    const addProps = {
        cancelAction: setAdd,
        customGoal,
        setCustomGoal,
        dateISO,
        setDateISO,
        holder,
        qolIDs,
        setQolIDs,
        savedQOLs,
        setSavedQOLs,
        updateProfileCompletedItems,
        userID: user.id
    };

    const editProps = {
        cancelAction: setEdit,
        customGoal,
        setCustomGoal,
        dateISO,
        setDateISO,
        editNeed,
        setEditNeed,
        isEditingNeed,
        setNeedID,
        savedQOLs,
        setSavedQOLs,
        updatedNeedIDs,
        setUpdatedNeedIDs
    };

    useEffect(() => {
        if (edit) {
            const need = pendingNeeds.find((need) => need.id === needID);

            setEditNeed(need);
        }
    }, [edit, needID, pendingNeeds]);

    useEffect(() => {
        if (updateSDOHStatus && needID) {
            const need = pendingNeeds.find((need) => need.id === needID);

            setUpdateNeed(need);
        }
    }, [needID, pendingNeeds, updateSDOHStatus]);

    return (
        <div className={classes.needsContainer} ref={needContainerRef}>
            <Card info need count={pendingNeeds.length} name={holder.name} />
            {pendingNeeds.map((need, index) => (
                <NeedCard
                    key={index}
                    need={need}
                    setCompleteNeed={setCompleteNeed}
                    setEdit={setEdit}
                    setHasQOLMeasures={setHasQOLMeasures}
                    setNeedID={setNeedID}
                    touchpointQuestionQOLs={touchpointQuestionQOLs}
                    updatedNeedIDs={updatedNeedIDs}
                />
            ))}
            {newHomelessNeeds.length ? <LoadingCard /> : null}
            {add ? (
                <Add type="need" {...addProps} />
            ) : (
                <AddButton action={() => setAdd(true)} type="need" />
            )}
            {completeNeed && (
                <PopUp
                    content="complete need"
                    needID={needID}
                    handleModalClick={setCompleteNeed}
                    hasQOLMeasures={hasQOLMeasures}
                    holder={holder}
                    updatedNeedIDs={updatedNeedIDs}
                    setUpdatedNeedIDs={setUpdatedNeedIDs}
                    setUpdateSDOHStatus={setUpdateSDOHStatus}
                />
            )}
            {edit && (
                <PopUp
                    content="edit need"
                    needID={needID}
                    handleModalClick={setEdit}
                    holder={holder}
                    {...editProps}
                />
            )}
            {updateSDOHStatus && updateNeed && (
                <PopUp
                    content="update SDOH status"
                    handleModalClick={setUpdateSDOHStatus}
                    holder={holder}
                    setUpdateSDOHStatus={setUpdateSDOHStatus}
                    touchpointAnswers={touchpointAnswers}
                    touchpointQuestions={touchpointQuestions}
                    updateNeed={updateNeed}
                />
            )}
        </div>
    );
};

Needs.propTypes = {
    holder: PropTypes.object.isRequired,
    needs: PropTypes.array.isRequired,
    newHomelessNeeds: PropTypes.array.isRequired
};

export default Needs;
