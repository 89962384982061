import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthManager from 'auth';

import {
    assignDebitCardRequest,
    closeDebitCardRequest,
    transferBalanceRequest,
    updateDebitCardPinRequest,
    resetDebitCardError
} from 'redux/actions/debit_card';

export const useDebitCardActions = () => {
    const apiToken = AuthManager.getInstance().getApiToken();
    const dispatch = useDispatch();

    const debitInfo = useSelector((state: any) => state.debitCard.debitInfo);
    const error = useSelector((state: any) => state.debitCard.isErrored);
    const errorMessage = useSelector((state: any) => state.debitCard.error);
    const loading = useSelector((state: any) => state.debitCard.isLoading);
    const success = useSelector((state: any) => state.debitCard.isSuccessful);

    const { card_type } = debitInfo;

    const assignDebitCard = useCallback(
        async (
            memberID: number,
            type: 'givecard' | 'pex',
            last4: string,
            externalID?: string
        ) => {
            try {
                if (!apiToken) {
                    throw new Error('No API token found');
                }

                dispatch(
                    assignDebitCardRequest(
                        memberID,
                        type,
                        last4,
                        apiToken,
                        externalID
                    )
                );
            } catch (error) {
                console.error('Failed to assign debit card:', error);
            }
        },
        [apiToken, dispatch]
    );

    const closeDebitCard = useCallback(
        async (memberID: number) => {
            try {
                dispatch(closeDebitCardRequest(memberID, card_type));
            } catch (error) {
                console.error('Failed to close debit card:', error);
            }
        },
        [card_type, dispatch]
    );

    const transferDebitBalance = useCallback(
        async (memberID: number, amount: number) => {
            try {
                if (!apiToken) {
                    throw new Error('No API token found');
                }

                dispatch(
                    transferBalanceRequest(
                        memberID,
                        card_type,
                        amount,
                        apiToken
                    )
                );
            } catch (error) {
                console.error('Failed to transfer balance:', error);
            }
        },
        [apiToken, card_type, dispatch]
    );

    const updateDebitPin = useCallback(
        async (memberID: number, pin: number) => {
            try {
                if (!apiToken) {
                    throw new Error('No API token found');
                }

                dispatch(
                    updateDebitCardPinRequest(
                        memberID,
                        card_type,
                        pin,
                        apiToken
                    )
                );
            } catch (error) {
                console.error('Failed to update debit pin:', error);
            }
        },
        [apiToken, card_type, dispatch]
    );

    const resetDebitState = useCallback(() => {
        dispatch(resetDebitCardError());
    }, [dispatch]);

    return {
        assignDebitCard,
        closeDebitCard,
        debitInfo,
        error,
        errorMessage,
        loading,
        resetDebitState,
        success,
        transferDebitBalance,
        updateDebitPin
    };
};
