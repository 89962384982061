import * as CONSTANTS from '../constants/constants';

import { GoalActions } from 'types';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import { setGoalActionCompletePopup } from './samaritan';
import {
    updateBonusAmount,
    updateHomelessBalance,
    updateProfileCompletedItems
} from './homeless';
import { memoGoalActionStatusUpdated } from './local';

import * as api from '../../api/goal_actions';
import { postHomelessGoal } from '../../api/goals';
import { postingNewGoal, postedNewGoal, failedNewGoal } from './goals';

import randomString from '../../util/randomString';

function fetchHomelessGoalActions() {
    return {
        type: CONSTANTS.GET_HOMELESS_GOAL_ACTIONS
    };
}

// TODO: Fix any types
function setHomelessGoalActions(response: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_GOAL_ACTIONS,
        payload: response
    };
}

function setHomelessGoalActionRequestState({
    id,
    requestState
}: {
    id: number;
    requestState: string;
}) {
    return {
        type: CONSTANTS.SET_HOMELESS_GOAL_ACTION_REQUEST_STATE,
        payload: { id, requestState }
    };
}

function updateHomelessGoalActionStatus(id: number, status: string) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_GOAL_ACTION_STATUS,
        payload: { id, status }
    };
}

// function updateHomelessGoalActionPendingPayments(id, pendingPayments) {
//     return {
//         type: CONSTANTS.UPDATE_HOMELESS_GOAL_ACTION_PENDING_PAYMENTS,
//         payload: { id, pendingPayments }
//     };
// }

export function updateGoalActionStatus(
    id: number,
    status: 'cancelled' | 'completed'
) {
    return (dispatch: Function) => {
        dispatch(
            setHomelessGoalActionRequestState({ id, requestState: 'loading' })
        );
        return api
            .updateGoalActionStatus({ id, status })
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: any) => {
                dispatch(updateHomelessGoalActionStatus(id, status));
                dispatch(
                    setHomelessGoalActionRequestState({
                        id,
                        requestState: 'idle'
                    })
                );
                dispatch(memoGoalActionStatusUpdated(id));

                if (status === 'completed') {
                    dispatch(setGoalActionCompletePopup(true));
                    dispatch(
                        updateHomelessBalance(
                            responseJson.goal_action.match_value
                        )
                    );
                }
            })
            .catch((error: any) => {
                dispatch(
                    setHomelessGoalActionRequestState({
                        id,
                        requestState: 'idle'
                    })
                );

                handleSamaritanApiError(error, dispatch);
            });
    };
}

function updateHomelessGoalActionMatchAmount(id: number, matchAmount: number) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_GOAL_ACTION_MATCH_AMOUNT,
        payload: { id, matchAmount }
    };
}

export function updateGoalActionMatchAmount(
    _token: string,
    id: number,
    matchAmount: number
) {
    return (dispatch: Function) => {
        return api
            .updateGoalActionMatchAmount({ id, matchAmount })
            .then((response) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson) => {
                dispatch(
                    updateHomelessGoalActionMatchAmount(
                        responseJson.goal_action.id,
                        matchAmount
                    )
                );
            })
            .catch((error) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

// TODO: Fix any types
export function getHomelessGoalActions(_token: string, id: number) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessGoalActions());
        return api
            .fetchGoalActions(id)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { goal_actions: any[] }) => {
                return dispatch(
                    setHomelessGoalActions(responseJson.goal_actions)
                );
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

// TODO: Fix any types
function postingNewGoalAction(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.POSTING_NEW_HOMELESS_GOAL_ACTION,
        payload,
        tempRef
    };
}

// TODO: Fix any types
function postedNewGoalAction(tempRef: string, newGoalAction: any) {
    return {
        type: CONSTANTS.POSTED_NEW_HOMELESS_GOAL_ACTION,
        tempRef,
        newGoalAction
    };
}

function failedNewGoalAction(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_NEW_HOMELESS_GOAL_ACTION,
        tempRef,
        error
    };
}

// TODO: Fix any types
function editingGoalAction(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.EDITING_HOMELESS_GOAL_ACTION,
        payload,
        tempRef
    };
}

// TODO: Fix any types
function editedGoalAction(tempRef: string, newGoalAction: any) {
    return {
        type: CONSTANTS.EDITED_HOMELESS_GOAL_ACTION,
        tempRef,
        newGoalAction
    };
}

function failedEditGoalAction(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_EDIT_HOMELESS_GOAL_ACTION,
        tempRef,
        error
    };
}

// TODO: Fix any types
export function newGoalActionThunk(
    homeless_id: number,
    goal_id: number,
    goalDescription: string,
    date: string,
    amount: number,
    customGoalDescription: string | null,
    qols: number | null
) {
    const tempRef = randomString();

    return (dispatch: Function) => {
        dispatch(
            postingNewGoalAction(
                {
                    goal: goalDescription,
                    action: customGoalDescription,
                    due_at: date,
                    stake_match_amount: amount,
                    quality_of_life_measure_id: qols
                },
                tempRef
            )
        );

        return api
            .postNewGoalAction({
                goal_id,
                description: goalDescription,
                homeless_id,
                due_at: date,
                stake_match_amount: amount,
                qol_measure_id: qols
            })
            .then((res: Response) => {
                return handleSamaritanApiResponse(res, dispatch);
            })
            .then(
                (resJson: {
                    errors?: {
                        due_at: string[];
                    };
                    goal_action: GoalActions;
                }) => {
                    if (resJson.errors) {
                        return dispatch(
                            failedNewGoalAction(
                                tempRef,
                                resJson.errors.due_at[0]
                            )
                        );
                    } else {
                        dispatch(
                            updateBonusAmount(resJson.goal_action.match_value)
                        );
                        dispatch(updateProfileCompletedItems('action_steps'));
                        return dispatch(
                            postedNewGoalAction(tempRef, resJson.goal_action)
                        );
                    }
                }
            )
            .catch((e: any) => {
                dispatch(failedNewGoalAction(tempRef, e.error));
                return handleSamaritanApiError(e, dispatch);
            });
    };
}

// TODO: Fix any types
export function editGoalActionThunk(
    goalAction: string,
    amount: number,
    date: string,
    id: number,
    goal: string,
    qols: number[]
) {
    const tempRef = randomString();

    return async (dispatch: Function, getState: any) => {
        const payload = {
            goal_action: {
                due_at: date,
                stake_match_amount: amount,
                description: goalAction,
                quality_of_life_measure_id: qols
            } as {
                due_at: string;
                stake_match_amount: number;
                description: string;
                quality_of_life_measure_id: number[];
                goal_id?: null | number;
            }
        };

        if (goal) {
            const homeless_id = getState().homeless.homelessInfo.id;
            const currentGoals = getState().goals.homelessGoals;
            const matchedGoals = currentGoals.filter(
                (goalItem: { description: string }) =>
                    goalItem.description === goal
            )[0];

            if (matchedGoals === undefined) {
                const goalPayload = {
                    homeless_id,
                    description: goal
                };

                dispatch(postingNewGoal(goalPayload, tempRef));

                let res = await postHomelessGoal({
                    homelessId: homeless_id,
                    description: goal
                });

                if (res && !res.ok) {
                    const e = await res.json();
                    return dispatch(failedNewGoal(tempRef, e.error));
                }

                let resJson = await res.json();
                dispatch(postedNewGoal(tempRef, resJson.goal));

                const goal_id = resJson.goal.id;

                payload.goal_action.goal_id = goal_id;
            }
        }

        if (goal === null) {
            payload.goal_action.goal_id = null;
        }

        dispatch(editingGoalAction(payload, tempRef));

        let res = await api.editGoalAction(payload, id);
        const resJson = await res.json();

        if (res && !res.ok) {
            return dispatch(failedEditGoalAction(tempRef, resJson.error));
        }

        return dispatch(editedGoalAction(tempRef, resJson.goal_action));
    };
}
