import { useSelector } from 'react-redux';

import Card from '../../CaseManagement/components/Card';

import StatusArrow from 'assets/arrow-status.png';

import { getColor } from '../../CaseManagement/SDOHStatus/utils';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        improvementCardContainer: {
            width: 'calc(100% - 64px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            top: '-40px',
            fontSize: '18px',
            '@media (max-width: 494px)': {
                width: 'calc(100% - 32px)'
            }
        },
        statusName: {
            color: '#9A9EE7',
            fontWeight: 800
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            marginTop: '16px'
        },
        statusPill: {
            padding: '4px 12px',
            borderRadius: '200px',
            fontSize: '14px',
            fontWeight: 700,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        arrow: {
            height: '16px',
            width: 'auto'
        }
    })
);

type Props = {
    oldStatus: any;
    newStatus: any;
    touchpointQuestionID: number;
};

const ImprovementCard: React.FC<Props> = ({
    oldStatus,
    newStatus,
    touchpointQuestionID
}) => {
    const classes = useStyles();

    const determinantRanks = useSelector(
        (state: {
            determinantRanks: {
                determinantRanks: {
                    id: number;
                    friendly_name: string;
                    z_index: number;
                }[];
            };
        }) => state.determinantRanks.determinantRanks
    );
    const touchpointQuestions = useSelector(
        (state: {
            touchpointQuestions: {
                touchpointQuestions: {
                    id: number;
                    friendly_name: string;
                    icon_name: string;
                }[];
            };
        }) => state.touchpointQuestions.touchpointQuestions
    );
    const question = touchpointQuestions.find(
        (question: { id: number }) => question.id === touchpointQuestionID
    );
    const questionName = question?.friendly_name.split('Access to ')[1];

    const renderStatus = (status: number) => {
        return `${determinantRanks[status]?.id} ${determinantRanks[status]?.friendly_name}`;
    };

    return (
        <div className={classes.improvementCardContainer}>
            <Card>
                <span>
                    Access to{' '}
                    <span className={classes.statusName}>{questionName}</span>{' '}
                    improved from...
                </span>
                <div className={classes.statusContainer}>
                    <span
                        className={classes.statusPill}
                        style={{
                            color: getColor(oldStatus - 1, 'primary', 5),
                            backgroundColor: getColor(
                                oldStatus - 1,
                                'secondary',
                                5
                            )
                        }}
                    >
                        {renderStatus(oldStatus - 1)}
                    </span>
                    <img
                        className={classes.arrow}
                        src={StatusArrow}
                        alt="status arrow"
                    />
                    <span
                        className={classes.statusPill}
                        style={{
                            color: getColor(newStatus - 1, 'primary', 5),
                            backgroundColor: getColor(
                                newStatus - 1,
                                'secondary',
                                5
                            )
                        }}
                    >
                        {renderStatus(newStatus - 1)}
                    </span>
                </div>
            </Card>
        </div>
    );
};

export default ImprovementCard;
