import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { updateHomelessPublicInfo } from 'api/homeless';

const useUpdatePublicProfile = (from?: 'catchup' | 'public') => {
    const history = useHistory();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const updatePublicProfile = useCallback(
        (memberId: number, data: any) => {
            setIsLoading(true);
            setIsError(false);
            updateHomelessPublicInfo(memberId, data)
                .then((res: Response) => {
                    if (res && res.ok) return res.json();

                    const error = new Error('server error') as any;
                    error.response = res;
                    throw error;
                })
                .then(() => {
                    setIsSuccess(true);
                    setIsError(false);
                    setIsLoading(false);
                    setError(null);

                    if (from !== 'catchup') {
                        history.push({ state: { from: 'public' } });
                    }
                })
                .catch((err: Error) => {
                    setIsSuccess(false);
                    setIsError(true);
                    setIsLoading(false);
                    setError(err);
                });
        },
        [from, history]
    );

    const reset = useCallback(() => {
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
        setError(null);
    }, []);

    return {
        updatePublicProfile,
        isSuccess,
        isError,
        isLoading,
        error,
        reset
    };
};

export default useUpdatePublicProfile;
