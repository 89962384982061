import { useState } from 'react';
import { useDispatch } from 'react-redux';

import ContextField from '../../Celebration/components/ContextField';

import { updateProfileCompletedItems } from 'redux/actions/homeless';
import { completeItemRequest } from 'redux/actions/homelessProfile';
import { updateSupplementalQuestionAnswers } from 'redux/actions/touchpointAnswers';
import { submitSupplementalQuestionAnswer } from 'api/touchpointQuestions';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        newPrompt: {
            display: 'flex',
            alignSelf: 'flex-start',
            color: '#999DFF',
            marginTop: '10px',
            fontSize: '12px',
            fontWeight: 700,
            '& .MuiButton-label': {
                gap: '8px'
            }
        },
        prompt: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
            color: '#1F2152',
            fontSize: '20px',
            lineHeight: '27px',
            padding: '32px',
            width: '90%',
            margin: 'auto'
        },
        promptText: {
            marginBottom: '24px'
        }
    })
);

type Props = {
    answer: string | undefined;
    catchUpID?: number;
    homelessID: number;
    profileItemType?: null | string;
    prompt: string;
    questionID: number;
    questionIndex?: number;
    questionsLength?: number;
    setAddAnswer: (value: boolean) => void;
    setQuestionIndex?: (value: number) => void;
};

const Prompt: React.FC<Props> = ({
    answer,
    catchUpID,
    homelessID,
    prompt,
    profileItemType,
    questionID,
    questionIndex,
    questionsLength,
    setAddAnswer,
    setQuestionIndex
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [context, setContext] = useState<string>(answer ? answer : '');

    const handleNewPrompt = () => {
        if (!questionsLength || !setQuestionIndex) return;

        let newQuestionIndex = Math.floor(Math.random() * questionsLength);

        while (newQuestionIndex === questionIndex) {
            newQuestionIndex = Math.floor(Math.random() * questionsLength);
        }

        if (setQuestionIndex) {
            setQuestionIndex(newQuestionIndex);
        }
    };

    const handleSubmit = async (setSuccess: (value: boolean) => void) => {
        try {
            const answer = {
                answer: context,
                created_at: new Date().toISOString(),
                homeless_id: homelessID,
                touchpoint_question_id: questionID
            };

            await submitSupplementalQuestionAnswer(answer);

            dispatch(updateSupplementalQuestionAnswers(answer));

            if (profileItemType) {
                dispatch(updateProfileCompletedItems(profileItemType));
            } else {
                dispatch(updateProfileCompletedItems('supplemental_questions'));
            }

            if (catchUpID) {
                dispatch(completeItemRequest(catchUpID));
            }

            setSuccess(true);
        } catch (error) {
            console.error('Error submitting context:', error);
        }

        setTimeout(() => {
            setAddAnswer(false);
        }, 2000);
    };

    return (
        <div className={classes.prompt}>
            <span className={classes.promptText}>
                <b>Prompt:</b> {prompt}
                {profileItemType && (
                    <Button
                        className={classes.newPrompt}
                        onClick={handleNewPrompt}
                    >
                        <span>Get New Prompt</span>
                        <i className="fas fa-random" />
                    </Button>
                )}
            </span>
            <ContextField
                context={context}
                setContext={setContext}
                handleModalClick={setAddAnswer}
                handleSubmit={handleSubmit}
                placeholder={false}
            />
        </div>
    );
};

export default Prompt;
