import { useEffect, useRef, useState } from 'react';

import {
    DebitCard as DebitCardType,
    DebitCardOptions,
    Homeless,
    Needs,
    PexHistory
} from 'types';

import DetailsView from './Views/DetailsView';
import History from '../History';

import { fetchPexHistory } from 'api/pex';
import { fetchGiveCardHistory } from 'api/givecard';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        debitCardContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            color: '#1F2152',
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '25px',
            '& i': {
                backgroundColor: '#E7EFFE',
                borderRadius: '50%',
                fontSize: '12px',
                padding: '10px'
            }
        },
        description: {
            color: '#A6A6BF',
            fontSize: '21px',
            fontWeight: 300,
            lineHeight: '28px'
        }
    })
);

type DebitCardProps = {
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCardType;
    debitType: 'givecard' | 'pex' | null;
    fullCardHistory?: PexHistory[];
    fromCatchUp?: boolean;
    handleModalClick?: (modal: boolean) => void;
    holder: Homeless;
    isFetchingDebitHistory?: boolean;
    needs?: Needs[];
    recentCardHistory?: PexHistory[];
    replaceCardView?: boolean;
    setAssignDebit?: (assignDebit: boolean) => void;
    setDebitType?: (type: number | string | null) => void;
    setFullCardHistory?: (fullCardHistory: any[]) => void;
    setPopUp?: (popUp: boolean) => void;
    setRecentCardHistory?: (recentCardHistory: any[]) => void;
    updatePinView?: boolean;
};

const DebitCard: React.FC<DebitCardProps> = ({
    debitCardOptions,
    debitInfo,
    debitType,
    fullCardHistory,
    fromCatchUp,
    handleModalClick,
    holder,
    isFetchingDebitHistory,
    needs,
    recentCardHistory,
    replaceCardView,
    setAssignDebit,
    setDebitType,
    setFullCardHistory,
    setPopUp,
    setRecentCardHistory,
    updatePinView
}) => {
    const classes = useStyles();
    const viewRef = useRef(null);

    const { account_id, available_balance, card_last4 } = debitInfo || {};
    const { balance = 0, id, organization, photo } = holder;
    const {
        card_auto_transfer_balance = false,
        pex_auto_transfer_balance = false
    } = organization || {};

    const hasDebitAccount =
        debitInfo && account_id && card_last4 && card_last4.length
            ? true
            : false;

    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assignView, setAssignView] = useState(
        hasDebitAccount || (fromCatchUp && hasDebitAccount) ? false : true
    );
    const [changeView, setChangeView] = useState(updatePinView ? true : false);
    const [replaceView, setReplaceView] = useState(
        replaceCardView ? true : false
    );
    const [transferView, setTransferView] = useState(
        fromCatchUp && hasDebitAccount ? true : false
    );

    useEffect(() => {
        const fetchHistoryData = (fetchFullHistory: boolean) => {
            setLoading(true);

            const fetchHistoryFunction =
                debitInfo?.card_type === 'givecard'
                    ? fetchGiveCardHistory
                    : fetchPexHistory;

            if (setFullCardHistory && setRecentCardHistory) {
                fetchHistoryFunction(id, fetchFullHistory)
                    .then((res: Response) => res.json())
                    .then((data: any) => {
                        if (fetchFullHistory) {
                            setFullCardHistory(data.message);
                        } else {
                            setRecentCardHistory(data.message);
                        }
                        setLoading(false);
                    })
                    .catch((error: any) => {
                        console.error(
                            `Error fetching ${debitInfo.card_type} history:`,
                            error
                        );
                        setLoading(false);
                    });
            }
        };

        if (expanded && hasDebitAccount) {
            fetchHistoryData(true);
        }

        if (available_balance !== balance) {
            fetchHistoryData(false);
        }
    }, [
        available_balance,
        balance,
        debitInfo?.card_type,
        expanded,
        hasDebitAccount,
        id,
        setFullCardHistory,
        setRecentCardHistory
    ]);

    useEffect(() => {
        if (isFetchingDebitHistory) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetchingDebitHistory]);

    return (
        <>
            <div className={classes.debitCardContainer} ref={viewRef}>
                <DetailsView
                    assignView={assignView}
                    balance={balance}
                    changeView={changeView}
                    debitCardOptions={debitCardOptions}
                    debitInfo={debitInfo}
                    debitType={debitType}
                    fromCatchUp={fromCatchUp}
                    giveAutoTransfer={card_auto_transfer_balance}
                    handleModalClick={handleModalClick}
                    holder={holder}
                    needs={needs}
                    pexAutoTransfer={pex_auto_transfer_balance}
                    photo={photo}
                    replaceCardView={replaceCardView}
                    replaceView={replaceView}
                    setAssignDebit={setAssignDebit}
                    setAssignView={setAssignView}
                    setChangeView={setChangeView}
                    setDebitType={setDebitType}
                    setPopUp={setPopUp}
                    setReplaceView={setReplaceView}
                    setTransferView={setTransferView}
                    transferView={transferView}
                    updatePinView={updatePinView}
                />
            </div>
            {hasDebitAccount &&
            !assignView &&
            !changeView &&
            !replaceView &&
            !transferView ? (
                <div style={{ padding: '0 30px 30px 30px' }}>
                    <History
                        debitCard={debitInfo}
                        expanded={expanded}
                        fullHistoryItems={fullCardHistory!}
                        historyItems={recentCardHistory!}
                        loading={loading}
                        setExpanded={setExpanded}
                    />
                </div>
            ) : null}
        </>
    );
};

export default DebitCard;
