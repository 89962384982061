import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import EditIcon from './EditIcon';
import Popup from '../../../../_shared/PhotoPopup';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    editProfilePictureContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        margin: '0 auto',
        maxWidth: '50%'
    },
    profilePictureContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        '& img': {
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'cover',
            borderRadius: '50%',
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& input': {
            display: 'none'
        }
    }
}));

const EditProfilePicture = ({
    photo,
    editProfilePicture,
    setEditProfilePicture,
    newProfilePicture,
    setNewProfilePicture,
    handleSave
}) => {
    const classes = useStyles();
    const imgRef = useRef(null);

    const [popup, setPopup] = useState(false);

    const handleImageClick = () => {
        setPopup(true);
    };

    const handleProfilePictureChange = (e) => {
        const img = e.target.files[0];

        if (img) {
            setNewProfilePicture(img);
        }
    };

    return (
        <div className={classes.editProfilePictureContainer}>
            <div className={classes.profilePictureContainer}>
                {newProfilePicture && (
                    <img
                        alt="profile"
                        className={classes.profilePicture}
                        src={URL.createObjectURL(newProfilePicture)}
                        onClick={handleImageClick}
                    />
                )}
                {!newProfilePicture && (
                    <img
                        alt="profile"
                        className={classes.profilePicture}
                        src={photo}
                        onClick={handleImageClick}
                    />
                )}
                <input
                    type="file"
                    accept="image/*"
                    ref={imgRef}
                    onChange={handleProfilePictureChange}
                    id="photo-upload"
                    title=""
                />
                <EditIcon
                    edit={editProfilePicture}
                    setEdit={setEditProfilePicture}
                    handleImageClick={handleImageClick}
                    handleSave={handleSave}
                    newProfilePicture={newProfilePicture}
                    picturePosition={{ bottom: '8px', right: '24px' }}
                />
            </div>
            {popup && (
                <Popup
                    handleModalClick={() => setPopup(false)}
                    profilePicture={newProfilePicture}
                    setProfilePicture={setNewProfilePicture}
                />
            )}
        </div>
    );
};

EditProfilePicture.propTypes = {
    photo: PropTypes.string.isRequired
};

export default EditProfilePicture;
