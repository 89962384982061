import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    listItemText: {
        color: '#FFFFFF',
        fontWeight: 500,
        textDecoration: 'none',
        whiteSpace: 'pre-wrap',
        width: '100%',
        fontSize: '18px',
        lineHeight: '20px',
        padding: '18px 0',
        margin: '6px 0',
        borderRadius: '8px',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.08)'
        },
        '& > i': {
            fontSize: '20px',
            margin: '0 15px'
        },
        '&.active': {
            textDecoration: 'none',
            color: 'white',
            backgroundColor: '#7378E8',
            padding: '18px 0',
            transition: 'all 0.3s ease-in-out'
        }
    }
}));

type NavItemProps = {
    to: string;
    title: string;
    newTab?: boolean;
};

export default function NavItem({ to, title, newTab = false }: NavItemProps) {
    const classes = useStyles();

    const navIcons = {
        'Client List': 'far fa-users',
        'Member Intake': 'far fa-user-plus',
        FAQ: 'far fa-question-circle',
        Settings: 'far fa-cog',
        Logout: 'far fa-sign-out'
    } as Record<string, string>;

    const handleGAClick = (title: string) => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Click',
            label: title
        });
    };

    return (
        <NavLink
            to={{ pathname: to }}
            target={newTab ? '_blank' : undefined}
            className={classes.listItemText}
            activeClassName={`${classes.listItemText} active`}
            isActive={(match, location) => {
                if (!match) {
                    if (location.pathname === '/') {
                        return title === 'Client List';
                    }
                    return false;
                }

                return true;
            }}
            onClick={() => handleGAClick(title)}
        >
            <i className={`${navIcons[title]}`} />
            {title}
        </NavLink>
    );
}
