function formatDate(dateString: string) {
    var temp = new Date(dateString);
    var formattedDate = temp.toISOString().split('T')[0];
    var splitDate = formattedDate.split('-');
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const day = String(parseInt(splitDate[2]));
    const month = months[parseInt(splitDate[1]) - 1];
    const year = splitDate[0];
    return `${day} ${month} ${year}`;
}

function formatHolderPhone(phoneInput: string | null) {
    if (phoneInput === null) return '';
    if (phoneInput.length > 0) {
        const formattedNumber =
            phoneInput.slice(2, 5) +
            phoneInput.slice(5, 8) +
            phoneInput.slice(8, 12);
        return formatNumber(formattedNumber);
    } else {
        return '';
    }
}

function formatNumber(phoneInput: string) {
    const phoneLength = phoneInput.length;
    if (phoneLength < 4) return phoneInput;
    if (phoneLength < 7) {
        return `(${phoneInput.slice(0, 3)}) ${phoneInput.slice(3)}`;
    }
    return `(${phoneInput.slice(0, 3)}) ${phoneInput.slice(
        3,
        6
    )}-${phoneInput.slice(6, 10)}`;
}

function formatPhoneNumber(phoneNumber: string) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    if (cleaned.length === 11 && cleaned[0] === '1') {
        cleaned = cleaned.slice(1);
    }
    if (cleaned.length !== 10) {
        return null;
    }
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ')-' + match[2] + '-' + match[3];
    }
    return null;
}

const validateEmail = (emailInput: string) => {
    if (emailInput === null) return false;
    if (emailInput.length === 0) return true;
    const validEmailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return validEmailRegex.test(emailInput);
};

const validatePhone = (phoneInput: string | null) => {
    if (phoneInput === null) return false;
    if (phoneInput.length === 0) return true;
    const validPhoneRegex = /^\d{10}$/;
    const rawPhoneInput = phoneInput.replace(/[^0-9]/g, ``);

    return validPhoneRegex.test(rawPhoneInput);
};

const renderLanguage = (language: string) => {
    switch (language) {
        case 'english':
            return 'English';
        case 'spanish':
            return 'Español';
        default:
            return '-';
    }
};

export {
    formatDate,
    formatHolderPhone,
    formatNumber,
    formatPhoneNumber,
    renderLanguage,
    validateEmail,
    validatePhone
};
