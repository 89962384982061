import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CardBackground from 'assets/background-card.png';
import MessageBubble from 'components/_shared/MessageBubble';

import { completeItemRequest } from 'redux/actions/homelessProfile';
import { submitTouchpointAnswer } from 'redux/actions/touchpointAnswers';

import { formatDate } from './utils';

import Button from '@mui/material/Button';
import TextAreaAutosize from '@mui/material/TextareaAutosize';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        statusContainer: {
            background: '#2F365B',
            borderRadius: '16px',
            position: 'relative'
        },
        statusTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 600,
            color: '#FFFFFF',
            lineHeight: '26px',
            paddingTop: '30px',
            marginBottom: '30px',
            position: 'relative'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '80px 0 40px 0'
        },
        cancelButton: {
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: 600,
            color: '#7378E8',
            borderRadius: '36px',
            padding: '10px 30px',
            marginBottom: '10px'
        },
        saveButton: {
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: 700,
            color: '#FBFBFF',
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '18px',
            padding: '16px 100px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.08)',
            marginBottom: '10px',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #918ED7 100%)'
            }
        },
        saveButtonDisabled: {
            opacity: '0.3',
            color: '#FFFFFF !important'
        },
        suggestionContainer: {
            display: 'flex',
            flexDirection: 'column',
            background: '#FFFFFF',
            borderRadius: '20px',
            padding: '20px 30px',
            width: '75%',
            margin: '0 auto 60px auto',
            boxShadow: '0px 10px 80px rgba(0, 0, 0, 0.1)',
            position: 'relative'
        },
        suggestionTitle: {
            fontSize: '14px',
            fontWeight: 800,
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
            background: '#8882D8',
            borderRadius: '32px',
            padding: '5px 20px',
            color: '#FFFFFF',
            position: 'relative',
            top: '0.5em',
            left: '10%',
            zIndex: 2
        },
        suggestionList: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '10px',
            gap: '10px'
        },
        suggestion: {
            background: '#EAEBFB',
            color: '#1F1F51',
            width: 'fit-content',
            padding: '10px 20px',
            borderRadius: '32px',
            fontSize: '14px',
            letterSpacing: '0.01em',
            opacity: '0.7',
            cursor: 'pointer',
            textTransform: 'none'
        },
        customContainer: {},
        customInputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        customButton: {
            fontSize: '16px',
            fontWeight: 300,
            color: '#A8A8BF',
            letterSpacing: '0.01em',
            opacity: '0.7',
            marginTop: '10px',
            borderRadius: '40px',
            border: '2px dashed #EAEBFB',
            textTransform: 'none',
            padding: '5px 20px',
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        customInput: {
            fontFamily: 'Manrope',
            fontSize: '30px',
            fontWeight: 600,
            lineHeight: '45px',
            color: '#1F1F51',
            marginTop: '10px',
            padding: '12px 10px',
            border: 'none',
            outline: 'none',
            resize: 'none',
            textDecoration: 'underline',
            textDecorationColor: '#E3E4FA',
            '&::placeholder': {
                color: '#A8A8BF',
                opacity: '0.7',
                fontWeight: '300',
                letterSpacing: '0.02em',
                fontStyle: 'italic',
                textDecoration: 'underline',
                textDecorationColor: '#E3E4FA'
            }
        },
        historyContainer: {
            margin: '30px 0'
        },
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '16px',
            fontWeight: 300,
            color: '#1F1F51'
        },
        date: {
            fontSize: '12px',
            color: '#A6A6BF'
        },
        answerList: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            margin: '15px 0'
        },
        answer: {
            background: 'rgba(234, 235, 251, 0.3)',
            borderRadius: '32px',
            padding: '10px 20px',
            fontSize: '14px',
            letterSpacing: '0.01em',
            color: '#1F1F51',
            opacity: '0.7'
        },
        cardBackground: {
            background: '#EAEFF3',
            position: 'absolute',
            bottom: '0',
            height: '85%',
            borderRadius: '40px 40px 10px 10px'
        },
        backgroundImage: {
            height: 'auto',
            width: '100%',
            bottom: '0',
            opacity: '0.6',
            borderRadius: '40px 40px 0 0'
        },
        doneButton: {
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 800,
            border: '3px solid #E3E4FA',
            borderRadius: '36px',
            padding: '4px 28px 4px 14px',
            color: '#1F1F51'
        },
        checkButton: {
            color: '#FFFFFF',
            background:
                'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3',
            borderRadius: '36px',
            padding: '22px',
            fontSize: '28px',
            position: 'absolute',
            right: '-20px',
            boxShadow: '-2.44929e-16px 4px 20px rgba(0, 0, 0, 0.121569)',
            '&:hover': {
                background:
                    'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3'
            }
        }
    })
);

type UpdateStatusProps = {
    answers: any[];
    catchUpID?: number;
    id: number;
    name: string;
    setAddStatus?: (status: boolean) => void;
    setUpdateStatus: (status: boolean) => void;
    statusId: number;
    questions: any[];
};

const UpdateStatus: React.FC<UpdateStatusProps> = ({
    answers,
    catchUpID,
    id,
    name,
    setAddStatus,
    setUpdateStatus,
    statusId,
    questions
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const [customAnswer, setCustomAnswer] = useState<string>('');
    const [customAnswerList, setCustomAnswerList] = useState<string[]>([]);
    const [selectedAnswer, setSelectedAnswer] = useState<string[]>([]);
    const [showCustomInput, setShowCustomInput] = useState<boolean>(false);

    const statusAnswers = answers.filter(
        (answer) => answer.answer || answer.answers
    );
    const statusQuestion = questions.find(
        (question) =>
            question.id === statusId ||
            question.quality_of_life_measure_id === statusId
    );
    const updateAnswer = statusAnswers.filter(
        (answer) => answer.touchpoint_question_id === statusId
    );
    const { friendly_name, question, suggestions } = statusQuestion;
    const suggestionList = suggestions.split(', ');

    const getAnswer = () => {
        let answer = '';

        if (selectedAnswer.length) {
            answer = selectedAnswer.join(', ');
        }

        if (customAnswer) {
            answer += `, ${customAnswer}`;
        }

        return answer;
    };

    const renderLastAnswered = () => {
        if (!statusAnswers || !updateAnswer.length) return;

        const statusAnswer = statusAnswers.filter((answer) => {
            if (answer.answer) {
                return answer.touchpoint_question_id === statusId;
            }

            if (answer.answers) {
                return answer.answers[0].touchpoint_question_id === statusId;
            }

            return false;
        });
        const { answer, created_at } = statusAnswer[0].answer
            ? statusAnswer[0]
            : statusAnswer[0].answers[0];
        const answerArr = answer.split(', ');

        return (
            <div className={classes.historyContainer}>
                <div className={classes.dateContainer}>
                    <span>Last Answered:</span>
                    <span className={classes.date}>
                        {formatDate(created_at)}
                    </span>
                </div>
                <div className={classes.answerList}>
                    {answerArr.map((answer: string, index: number) => (
                        <span key={index} className={classes.answer}>
                            {answer}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    const renderSelectedAnswerStyle = (answer: string) => {
        if (selectedAnswer.includes(answer)) {
            return {
                background: '#9DA2F7',
                color: '#FFFFFF',
                opacity: '1'
            };
        }
    };

    const saveCustomInput = () => {
        if (!customAnswer.length) {
            setShowCustomInput(false);
            return;
        }

        setCustomAnswerList((prev) => [...prev, customAnswer]);
        selectAnswer(customAnswer);
        setCustomAnswer('');
        setShowCustomInput(false);
    };

    const saveStatus = () => {
        const answerId = statusQuestion.id;
        const qolMeasureId = statusQuestion.quality_of_life_measure_id;
        const answer = {
            touchpoint_question_id: answerId,
            quality_of_life_measure_id: qolMeasureId,
            answer: getAnswer(),
            homeless_id: id
        };

        dispatch(submitTouchpointAnswer(answer));

        setCustomAnswer('');
        setSelectedAnswer([]);
        setUpdateStatus(false);

        if (catchUpID && setAddStatus) {
            dispatch(completeItemRequest(catchUpID));
            setAddStatus(false);
        }

        if (!history.location.state && !catchUpID) {
            history.push({ state: { from: 'case', status: true } });
        }
    };

    const selectAnswer = (answer: string) => {
        if (selectedAnswer.includes(answer)) {
            setSelectedAnswer((prev) =>
                prev.filter((prevAnswer) => prevAnswer !== answer)
            );
        } else {
            setSelectedAnswer((prev) => [...prev, answer]);
        }
    };

    useEffect(() => {
        if (showCustomInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showCustomInput]);

    return (
        <div className={classes.statusContainer}>
            <div className={classes.cardBackground}>
                <img
                    className={classes.backgroundImage}
                    src={CardBackground}
                    alt={'card background'}
                />
            </div>
            <div className={classes.statusTitle}>{friendly_name}</div>
            <MessageBubble name={name} question={question} />
            <span className={classes.suggestionTitle}>
                Click All That Apply
            </span>
            <div className={classes.suggestionContainer}>
                <div className={classes.suggestionList}>
                    {suggestionList.map((suggestion: string, index: number) => (
                        <Button
                            className={classes.suggestion}
                            key={index}
                            onClick={() => selectAnswer(suggestion)}
                            style={renderSelectedAnswerStyle(suggestion)}
                        >
                            {suggestion}
                        </Button>
                    ))}
                    {customAnswerList.map((answer, index) => (
                        <Button
                            className={classes.suggestion}
                            key={index}
                            onClick={() => selectAnswer(answer)}
                            style={renderSelectedAnswerStyle(answer)}
                        >
                            {answer}
                        </Button>
                    ))}
                </div>
                <div className={classes.customContainer}>
                    {!showCustomInput ? (
                        <Button
                            className={classes.customButton}
                            onClick={() => setShowCustomInput(true)}
                        >
                            Write your own...
                        </Button>
                    ) : (
                        <div className={classes.customInputContainer}>
                            <TextAreaAutosize
                                className={classes.customInput}
                                onChange={(e) =>
                                    setCustomAnswer(e.target.value)
                                }
                                placeholder={'Write your own...'}
                                ref={inputRef}
                                spellCheck={'false'}
                                value={customAnswer}
                            />
                            <div className={classes.doneButton}>Done</div>
                            <Button
                                className={classes.checkButton}
                                onClick={() => saveCustomInput()}
                            >
                                <i className="fas fa-check" />
                            </Button>
                        </div>
                    )}
                </div>
                {renderLastAnswered()}
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    className={
                        selectedAnswer.length || customAnswer
                            ? classes.saveButton
                            : `${classes.saveButtonDisabled} ${classes.saveButton}`
                    }
                    disabled={!selectedAnswer.length && !customAnswer}
                    onClick={() => saveStatus()}
                >
                    Save Status
                </Button>
                <Button
                    className={classes.cancelButton}
                    onClick={() => setUpdateStatus(false)}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default UpdateStatus;
