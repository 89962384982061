import { useEffect, useState } from 'react';

import { DebitCard, DebitCardOptions, Homeless, Needs } from 'types';

import AssignCardPopUp from '../../AssignCardPopUp';
import Logo from 'assets/logo.png';
import NumPad from '../NumPad';
import PopUp from '../../../PopUp';
import PopUp2 from 'components/_shared/PopUp2';
import { CardClose, CardRules } from 'components/_shared/PopUp2/components';
import { DetailButton, ListButton } from '../Buttons';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    balance: {
        fontSize: '32px',
        fontWeight: '700',
        marginRight: '6px'
    },
    balanceContainer: {},
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'fit-content',
        padding: '40px 0 24px 0',
        gap: '30px'
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    debitCard: {
        width: '420px',
        height: '180px',
        borderRadius: '16px',
        background: '#739BE8'
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '65%',
        padding: '20px',
        color: '#FFFFFF'
    },
    detailsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '70%',
        margin: 'auto',
        padding: '50px 30px 30px 30px'
    },
    logo: {
        width: 'auto',
        height: '32px'
    },
    rulesContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '32px',
        color: '#739BE8',
        fontSize: '16px',
        fontWeight: 700,
        width: '100%'
    }
}));

type DetailsViewProps = {
    assignView: boolean;
    balance: number;
    changeView: boolean;
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    debitType: 'givecard' | 'pex' | null;
    fromCatchUp?: boolean;
    giveAutoTransfer: boolean;
    handleModalClick?: (modal: boolean) => void;
    holder: Homeless;
    needs?: Needs[];
    pexAutoTransfer: boolean;
    photo: string;
    replaceView: boolean;
    replaceCardView?: boolean;
    setAssignDebit?: (assignDebit: boolean) => void;
    setAssignView: (assignView: boolean) => void;
    setChangeView: (changeView: boolean) => void;
    setDebitType?: (debitType: 'givecard' | 'pex' | null) => void;
    setTransferView: (transferView: boolean) => void;
    setPopUp?: (popUp: boolean) => void;
    setReplaceView: (replaceView: boolean) => void;
    transferView: boolean;
    updatePinView?: boolean;
};

const DetailsView = ({
    assignView,
    balance,
    changeView,
    debitCardOptions,
    debitInfo,
    debitType,
    fromCatchUp,
    giveAutoTransfer,
    handleModalClick,
    holder,
    needs,
    pexAutoTransfer,
    photo,
    replaceView,
    replaceCardView,
    setAssignDebit,
    setAssignView,
    setChangeView,
    setDebitType,
    setTransferView,
    setPopUp,
    setReplaceView,
    transferView,
    updatePinView
}: DetailsViewProps) => {
    const classes = useStyles();

    const { account_id, available_balance, card_last4, total_balance } =
        debitInfo || {};
    const { id, name } = holder;

    const [assignPopUp, setAssignPopUp] = useState(false);
    const [listButtonPopUp, setListButtonPopUp] = useState(false);
    const [listButtonContent, setListButtonContent] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);

    const handleListButtonClose = () => {
        setListButtonContent('');
        setListButtonPopUp(false);
    };

    const handleListButtonOpen = (content: string) => {
        setListButtonContent(content);
        setListButtonPopUp(true);
    };

    const renderDebitType = () => {
        if (debitType === 'pex') {
            return 'PEX Card';
        } else if (debitType === 'givecard') {
            return 'GiveCard';
        } else {
            return 'Debit Card';
        }
    };

    useEffect(() => {
        if (
            debitInfo &&
            debitInfo.card_type &&
            debitInfo.card_type !== debitType &&
            !replaceView &&
            (!giveAutoTransfer || !pexAutoTransfer) &&
            setDebitType
        ) {
            setDebitType(debitInfo.card_type);
        }
    }, [
        debitInfo,
        debitType,
        giveAutoTransfer,
        pexAutoTransfer,
        replaceView,
        setDebitType
    ]);

    useEffect(() => {
        if (!account_id && listButtonContent.length > 0 && setPopUp) {
            setListButtonContent('');
            setPopUp(false);
        }
    }, [account_id, listButtonContent, setPopUp]);

    return (
        <>
            {!transferView && !changeView && !replaceView && !assignView ? (
                <>
                    {!listButtonContent.length ? (
                        <div className={classes.detailsWrapper}>
                            <div className={classes.debitCard}>
                                <div className={classes.detailsContainer}>
                                    <div className={classes.cardHeader}>
                                        <img
                                            src={Logo}
                                            alt="Samaritan Logo"
                                            className={classes.logo}
                                        />
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            {renderDebitType()} ending in{' '}
                                            <span
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '800'
                                                }}
                                            >
                                                {card_last4}
                                            </span>
                                        </span>
                                    </div>
                                    <div className={classes.balanceContainer}>
                                        <span className={classes.balance}>
                                            ${debitInfo?.available_balance}
                                        </span>
                                        <span>available</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.buttonWrapper}>
                                {!updatePinView &&
                                    !replaceCardView &&
                                    (!giveAutoTransfer || !pexAutoTransfer) && (
                                        <DetailButton
                                            handleClick={() =>
                                                setTransferView(true)
                                            }
                                            icon={`fas fa-plus`}
                                            label={`Transfer Funds`}
                                        />
                                    )}
                                <DetailButton
                                    handleClick={() => setChangeView(true)}
                                    icon={`far fa-lock-alt`}
                                    label={`Update PIN`}
                                />
                                <DetailButton
                                    handleClick={() => {
                                        if (debitCardOptions?.length > 1) {
                                            setAssignPopUp(true);
                                        } else {
                                            if (
                                                debitCardOptions?.length ===
                                                    1 &&
                                                setDebitType
                                            ) {
                                                setDebitType(
                                                    debitCardOptions[0].type
                                                );
                                            }
                                            setReplaceView(true);
                                        }
                                    }}
                                    icon={`far fa-sync`}
                                    label={`Replace Card`}
                                />
                            </div>
                            <div className={classes.rulesContainer}>
                                <ListButton
                                    icon="book-open-reader"
                                    onClick={() =>
                                        handleListButtonOpen('rules')
                                    }
                                >
                                    Card Rules & Restrictions
                                </ListButton>
                                <ListButton
                                    icon="times-circle"
                                    onClick={() =>
                                        handleListButtonOpen('close')
                                    }
                                >
                                    Close Card
                                </ListButton>
                            </div>
                        </div>
                    ) : (
                        <PopUp2
                            onClose={handleListButtonClose}
                            open={listButtonPopUp}
                            title={listButtonContent}
                            nonAutoTransfer={true}
                        >
                            {listButtonContent === 'rules' ? (
                                <CardRules
                                    debitType={debitType}
                                    onClose={handleListButtonClose}
                                />
                            ) : (
                                <CardClose
                                    holder={holder}
                                    onClose={handleListButtonClose}
                                />
                            )}
                        </PopUp2>
                    )}
                </>
            ) : (
                <NumPad
                    assignView={assignView}
                    balance={balance}
                    changeView={changeView}
                    debitCardOptions={debitCardOptions}
                    debitType={debitType}
                    fromCatchUp={fromCatchUp}
                    handleModalClick={handleModalClick}
                    id={id}
                    name={name}
                    replaceCardView={replaceCardView}
                    replaceView={replaceView}
                    setAssignDebit={setAssignDebit}
                    setAssignView={setAssignView}
                    setChangeView={setChangeView}
                    setDebitType={setDebitType}
                    setReplaceView={setReplaceView}
                    setTransferView={setTransferView}
                    transferView={transferView}
                    updatePinView={updatePinView}
                />
            )}
            {assignPopUp && (
                <AssignCardPopUp
                    debitCardOptions={debitCardOptions}
                    handleModalClick={() => setAssignPopUp(false)}
                    photo={photo}
                    setAssignPopUp={setAssignPopUp}
                    setDebitType={setDebitType!}
                    setReplaceView={setReplaceView}
                />
            )}
            {showPopUp && !assignView && (
                <PopUp
                    content="update need"
                    amount={(available_balance - total_balance!).toString()}
                    handleModalClick={() => setShowPopUp(false)}
                    name={name}
                    homelessNeeds={needs}
                />
            )}
        </>
    );
};

export default DetailsView;
