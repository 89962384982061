import * as CONSTANTS from '../constants/constants';
import randomString from '../../util/randomString';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import { memoNeedStatusUpdated } from './local';

import * as api from '../../api/needs';
import { postHomelessGoal } from '../../api/goals';
import { postingNewGoal, postedNewGoal, failedNewGoal } from './goals';

function fetchHomelessNeeds() {
    return {
        type: CONSTANTS.GET_HOMELESS_NEEDS
    };
}

function setHomelessNeeds(response: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_NEEDS,
        payload: response
    };
}

export function getHomelessNeeds(_token: string, id: number) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessNeeds());
        return api
            .fetchHomelessNeeds(id)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { needs: any[] }) => {
                return dispatch(setHomelessNeeds(responseJson.needs));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

function changeLocalNeedStatus(id: number, status: string) {
    return {
        type: CONSTANTS.CHANGE_LOCAL_NEED_STATUS,
        need_id: id,
        status
    };
}

export function completeNeed(id: number) {
    return (dispatch: Function) => {
        return api
            .completeNeed(id)
            .then((response: any) => {
                if (response && response.ok) {
                    return response.json();
                }
                throw new Error(`${response.status}: ${response.statusText}`);
            })
            .then((responseJson: { need: { id: number } }) => {
                dispatch(memoNeedStatusUpdated(responseJson.need.id));
                return dispatch(
                    changeLocalNeedStatus(responseJson.need.id, 'completed')
                );
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function cancelNeed(id: number) {
    return async (dispatch: Function) => {
        try {
            let res = await api.cancelNeed(id);
            if (res && res.ok) {
                let resJson = await res.json();
                dispatch(changeLocalNeedStatus(resJson.need.id, 'cancelled'));
                return dispatch(memoNeedStatusUpdated(resJson.need.id));
            }
            throw new Error(`${res.status}: ${res.statusText}`);
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
}

// TODO: Fix any types
function postingNewNeed(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.POSTING_NEW_HOMELESS_NEED,
        payload,
        tempRef
    };
}

function postedNewNeed(tempRef: string, newNeed: any) {
    return {
        type: CONSTANTS.POSTED_NEW_HOMELESS_NEED,
        tempRef,
        newNeed
    };
}

function editingNeed(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.EDITING_HOMELESS_NEED,
        payload,
        tempRef
    };
}

function editedNeed(tempRef: string, newNeed: any) {
    return {
        type: CONSTANTS.EDITED_HOMELESS_NEED,
        tempRef,
        newNeed
    };
}

function failedNewNeed(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_NEW_HOMELESS_NEED,
        tempRef,
        error
    };
}

function failedEditNeed(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_EDIT_HOMELESS_NEED,
        tempRef,
        error
    };
}

export function newNeedThunk(
    need: any,
    amount: number,
    date: string,
    needId?: number,
    _qolIds?: number,
    customGoal?: string
) {
    const tempRef = randomString();

    return async (dispatch: Function, getState: any) => {
        const homeless_id = getState().homeless.homelessInfo.id;
        const currentGoals = getState().goals.homelessGoals;
        let matchedGoals = customGoal
            ? currentGoals.filter(
                  (goal: { description: string }) =>
                      goal.description === customGoal
              )[0]
            : null;
        let goal_id = matchedGoals ? matchedGoals.id : null;

        const needPayload = {
            need: {
                amount,
                description: need,
                due_at: date
            } as {
                amount: number;
                description: string;
                due_at: string;
                goal_id: number | null;
                homeless_id: number;
            }
        };

        if (customGoal === null) {
            needPayload.need.goal_id = null;
        }

        if (customGoal && matchedGoals) {
            needPayload.need.goal_id = goal_id;
        }

        if (!needId) {
            needPayload.need.homeless_id = homeless_id;
        }

        if (matchedGoals === undefined) {
            const goalPayload = {
                goal: {
                    homeless_id: homeless_id,
                    description: customGoal
                }
            };

            dispatch(postingNewGoal(goalPayload, tempRef));

            let res = await postHomelessGoal({
                homelessId: homeless_id,
                description: ''
            });

            if (res && !res.ok) {
                const e = await res.json();
                return dispatch(failedNewGoal(tempRef, e.error));
            }

            let resJson = await res.json();
            dispatch(postedNewGoal(tempRef, resJson.goal));

            goal_id = resJson.goal.id;

            needPayload.need.goal_id = goal_id;
        }

        needId
            ? dispatch(editingNeed(needPayload, tempRef))
            : dispatch(postingNewNeed(needPayload, tempRef));

        let res = needId
            ? await api.editNeed(needPayload, needId)
            : await api.postHomelessNeed(needPayload);

        const resJson = await res.json();

        if (res && !res.ok) {
            return needId
                ? dispatch(failedEditNeed(tempRef, resJson.error))
                : dispatch(failedNewNeed(tempRef, resJson.error));
        }

        // transform from v2 schema to v3 schema
        resJson.need.relations = {
            donations: [],
            encouragements: []
        };

        return needId
            ? dispatch(editedNeed(tempRef, resJson.need))
            : dispatch(postedNewNeed(tempRef, resJson.need));
    };
}
